<template>
  <article class="page-container leerling-layout">
    <header>
      <div class="blue-gray-gradient" >
        <slot name="nav-slot">
          <beheerder-menu></beheerder-menu>
        </slot>
      </div>
      <div v-if="hasHeaderSlot" class="header-container">
        <slot name="header-slot"></slot>
      </div>
    </header>

    <div class="slot-container">
        <slot name="body-slot" ></slot>
    </div>

    <footer v-if="hasFooterSlot">
      <slot name="footer-slot" />
      <!--<div class="footer-copyright-wrapper">
         <span class="footer-copyright-text"> ©2020 Focus Onderwijs BV | <a href="http://www.focuspo.nl" target="_blank">Focus PO website</a> | <a href="http://www.getthere.nl" target="_blank">Powered by Get There</a></span>
      </div>-->
    </footer>
  </article>
</template>

<script>
export default {
  async created () {
    if (!this.$gt.stores.account.isAuthenticated) {
      this.$router.push('/')
    }
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header-slot']
    },
    hasFooterSlot () {
      return !!this.$slots['footer-slot']
    }
  }
}
</script>

<style scoped>
  header {
    background: #0D3CA5;
  }
  footer {
    height: 150px;
    background: linear-gradient(90deg, rgba(240,244,255,1) 0%, rgba(240,244,255,1) 50%, rgba(240,244,255,1) 100%);
  }
  h1 {
    color: white;
  }
</style>
