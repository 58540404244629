import httpClient from '../HttpClient';
import { useScholenStore } from '@/stores/scholen';
import { data } from 'autoprefixer';
export default {
    getTemplates: async () =>
        httpClient.getCached(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates`, { cacheKey: [`rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`] }),
    getTemplate: async (templateId) => httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}`),
    verwijderTemplate: async (templateId) =>
        httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}`, {
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),
    wijzigTemplate: async (templateId, data) =>
        httpClient.patch(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}`, {
            data,
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),
    registreerTemplate: async (data) =>
        httpClient.post(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates`, {
            data,
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),
    dupliceerTemplate: async (templateId, data) =>
        httpClient.post(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/kopie`, {
            data,
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),
    uploadTemplateAfbeelding: async (templateId, veld, data, type) =>
        httpClient.put(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/opties/${veld}/afbeelding`, {
            data,
            headers: { 'Content-Type': type },
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),
    uploadTemplateDocument: async (templateId, veld, data, type) =>
        httpClient.put(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/opties/${veld}/document`, {
            data,
            headers: { 'Content-Type': type },
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),
    wijzigTemplateOptie: async (templateId, veld, data) =>
        httpClient.patch(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/opties/${veld}`, {
            data,
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),
    getOntwikkelingsgebieden: async (templateId) => httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden`),

    getOpties: async (templateId) => httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/opties`),
    getOntwikkelingsGebiedOpties: async (templateId, ontwikkelingsgebiedId) =>
        httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden/${ontwikkelingsgebiedId}/opties`),
    saveOntwikkelgebiedOptie: async (templateId, veld, id, ontwikkelgebiedId, data) =>
        id !== undefined && id.length > 1
            ? httpClient.patch(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden/${ontwikkelgebiedId}/opties/afsluiting/${veld}/${id}`, {
                  data,
                  invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
              })
            : httpClient.put(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden/${ontwikkelgebiedId}/opties/afsluiting/${veld}`, {
                  data,
                  invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
              }),
    verwijderOptie: async (templateId, container, id) =>
        httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/opties/${container}/${id}`, {
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),
    verwijderOntwikkelingsGebiedOptie: async (templateId, container, gebiedId, veldid) =>
        httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden/${gebiedId}/opties/${container}/${veldid}`, {
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),

    saveAfsluiting: async (templateId, veld, id, data) =>
        id !== undefined && id.length > 1
            ? httpClient.patch(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/opties/afsluiting/${veld}/${id}`, {
                  data,
                  invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
              })
            : httpClient.put(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/opties/afsluiting/${veld}`, {
                  data,
                  invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
              }),

    updateTemplateFoto: async (templateId, veldId, data, type) =>
        httpClient.put(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/opties/afsluiting/foto/${veldId}/afbeelding`, {
            data,
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
            headers: { 'Content-Type': type },
        }),
    updateTemplateFotoOntwikkelgebied: async (templateId, veldId, ontwikkelgebiedId, data, type) =>
        httpClient.put(
            `/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden/${ontwikkelgebiedId}/opties/afsluiting/foto/${veldId}/afbeelding`,
            { data, invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`, headers: { 'Content-Type': type } }
        ),

    registreerOntwikkelingsgebied: async (templateId, data) =>
        httpClient.post(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden`, {
            data,
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),
    wijzigOntwikkelingsgebied: async (templateId, ontwikkelingsgebiedId, data) =>
        httpClient.patch(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden/${ontwikkelingsgebiedId}`, {
            data,
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),
    verwijderOntwikkelingsgebied: async (templateId, gebiedId) =>
        httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden/${gebiedId}`, {
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),

    getSchalen: async (templateId, gebiedId) => httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden/${gebiedId}/schalen`),
    registreerSchaal: async (templateId, gebiedId, data) =>
        httpClient.post(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden/${gebiedId}/schalen`, {
            data,
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),
    verwijderSchaal: async (templateId, gebiedId, schaalId) =>
        httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden/${gebiedId}/schalen/${schaalId}`, {
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),
    getDomeinen: async (templateId, gebiedId, parameters) =>
        httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden/${gebiedId}/domeinen`, { parameters }),
    wijzigOntwikkelingsgebiedOptie: async (templateId, gebiedId, veld, data) =>
        httpClient.patch(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/templates/${templateId}/ontwikkelingsgebieden/${gebiedId}/opties/${veld}`, {
            data,
            invalidateCacheKey: `rapportageTemplates-${await useScholenStore().getHuidigeSchoolId}`,
        }),
    getRapportenGroep: async (groepId) => httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/groepen/${groepId}/rapporten`),
    getRapportenLeerjaar: async (leerjaar) => httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerjaren/${leerjaar}/rapporten`),
    getRapport: async (leerlingId, rapportId) => httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}`),
    getRapportPdf: async (leerlingId, rapportId) =>
        httpClient.download(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}`, { parameters: { format: 'PDF' } }),
    getRapportenZip: async (rapportIds) => httpClient.download(`/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/zip?rapportIds=${rapportIds}`),
    getRapportVelden: async (leerlingId, rapportId) => httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/velden`),
    maakRapport: async (leerlingId, data) => httpClient.post(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten`, { data }),
    wijzigRapport: async (leerlingId, rapportId, data) => httpClient.patch(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}`, { data }),
    refreshRapport: async (leerlingId, rapportId) => httpClient.options(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/refresh`),
    verwijderRapport: async (leerlingId, rapportId) => httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}`),
    wijzigRapportVeld: async (leerlingId, rapportId, veldId, data) =>
        httpClient.patch(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/velden/${veldId}`, { data }),
    wijzigRapportAfsluitVeld: async (leerlingId, rapportId, veldId, data) =>
        httpClient.patch(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/velden/afsluiting/tekst/${veldId}`, { data }),
    wijzigRapportAfsluitVeldAfbeelding: async (leerlingId, rapportId, veldId, data, type) =>
        httpClient.put(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/velden/afsluiting/foto/${veldId}/afbeelding`, {
            data,
            headers: { 'Content-Type': type },
        }),
    verwijderRapportVeld: async (leerlingId, rapportId, veldId) =>
        httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/velden/${veldId}`),
    verwijderRapportVeldContainer: async (leerlingId, rapportId, veldId, container) =>
        httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/velden/${container}/${veldId}`),
    verwijderRapportFotoVeld: async (leerlingId, rapportId, veldId, container = undefined) =>
        httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/velden/${container ? `${container}/` : ''}${veldId}/afbeelding`),
    verwijderOntwikkelgebiedVeld: async (leerlingId, rapportId, gebiedId, veldId) =>
        httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/ontwikkelingsgebieden/${gebiedId}/velden/${veldId}`),
    wijzigRapportOntwikkelingsgebiedOptie: async (leerlingId, rapportId, gebiedId, veld, data) =>
        httpClient.patch(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/ontwikkelingsgebieden/${gebiedId}/velden/${veld}`, { data }),

    wijzigRapportOntwikkelingsgebiedOptieContainer: async (leerlingId, rapportId, gebiedId, container, veld) =>
        httpClient.patch(
            `/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/ontwikkelingsgebieden/${gebiedId}/velden/${container}/tekst/${veld.id}`,
            { data: veld }
        ),
    verwijderRapportOntwikkelingsgebiedOptieContainer: async (leerlingId, rapportId, gebiedId, container, veldId) =>
        httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/ontwikkelingsgebieden/${gebiedId}/velden/${container}/${veldId}`),
    wijzigRapportOntwikkelingsgebiedOptieContainerFoto: async (leerlingId, rapportId, gebiedId, container, veld, data) =>
        httpClient.put(
            `/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/ontwikkelingsgebieden/${gebiedId}/velden/${container}/foto/${veld.id}/afbeelding`,
            { data: data }
        ),

    wijzigRapportOntwikkelingsgebiedOptieContainerOpties: async (leerlingId, rapportId, gebiedId, container, veld) =>
        httpClient.patchJson(
            `/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/ontwikkelingsgebieden/${gebiedId}/velden/${container}/foto/${veld.id}`,
            { data: veld }
        ),

    wijzigRapportOptieContainerOpties: async (leerlingId, rapportId, gebiedId, container, veld) =>
        httpClient.patchJson(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/velden/${container}/foto/${veld.id}`, { data: veld }),

    wijzigRapportOntwikkelingsgebiedSchaal: async (leerlingId, rapportId, gebiedId, schaalId, data) =>
        httpClient.patch(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/ontwikkelingsgebieden/${gebiedId}/schalen/${schaalId}`, { data }),
    rapportDefinitiefMaken: async (leerlingId, rapportId, data) =>
        httpClient.putJson(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/status`, { data }),
    wijzigFotoVeld: async (leerlingId, rapportId, veld, data, type) =>
        httpClient.put(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/velden/${veld}/afbeelding`, { data, headers: { 'Content-Type': type } }),
    wijzigOntwikkelingsgebiedFotoVeld: async (leerlingId, rapportId, gebiedId, veld, data, type) =>
        httpClient.put(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/rapporten/${rapportId}/ontwikkelingsgebieden/${gebiedId}/velden/${veld}/afbeelding`, {
            data,
            headers: { 'Content-Type': type },
        }),
    getBlob: async (blobId) => httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/uploads/${blobId}`, { toonErrorNotify: false }),
    getFotoUrl: async (url) => httpClient.get(url, { toonErrorNotify: false }),
};
