import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  getMedewerkers: async (search, orderBy, start, limit) =>
    httpClient.call('/medewerkers', { method: 'get', parameters: { search, orderBy, start, limit } }),
  getMedewerker: async medewerkerId =>
    httpClient.call(`/medewerkers/${medewerkerId}`, { method: 'get' }),
  wijzigMedewerker: async (medewerkerId, data) =>
    httpClient.call(`/medewerkers/${medewerkerId}`, { method: 'patch', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} medewerker`, true),
  verwijderMedewerker: async (medewerkerId) =>
    httpClient.call(`/medewerkers/${medewerkerId}`, { method: 'delete' }, true, `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} medewerker`, true),
  registreerMedewerker: async data =>
    httpClient.call('/medewerkers', { method: 'post', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} medewerker`, true)
}
