import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  getHoofdvestigingen: async (search, orderBy, start, limit) =>
    httpClient.call('/hoofdvestigingen', { method: 'get', parameters: { search, orderBy, start, limit } }, true),
  getHoofdvestiging: async id =>
    httpClient.call(`/hoofdvestigingen/${id}`, { method: 'get' }, false),
  wijzigHoofdvestiging: async (id, data) =>
    httpClient.call(`/hoofdvestigingen/${id}`, { method: 'patch', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} hoofdvestiging`),
  wijzigBestuur: async (id, data) => {
    const headers = { 'Content-Type': 'application/json' }
    return httpClient.call(`/hoofdvestigingen/${id}/bestuur`, { method: 'put', data, headers }, true, 'Er is een fout opgetreden bij het wijzigen van het bestuursnummer', true)
  },
  registreerHoofdvestiging: async data =>
    httpClient.call('/hoofdvestigingen', { method: 'post', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} hoofdvestiging`),
  importeerHoofdvestigingen: async data => {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return httpClient.call('/hoofdvestigingen/bestand', { method: 'post', data, headers }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} hoofdvestigingen`)
  }
}
