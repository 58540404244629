<template>
  <div class="sub-menu">
    <router-link :to="{ name:'welkom' }">home</router-link>
    <span> ></span>
    <span>groep 3-8</span>
    <span> ></span>

    <router-link v-if="parent === 'cruciale-leerdoelen'" :to="{ name: 'cruciale-leerdoelen' }">Periodeoverzicht</router-link>
    <span v-if="parent === 'cruciale-leerdoelen'"> ></span>

    <span v-if="activeLink === 'cruciale-leerdoelen'">Periodeoverzicht</span>
    <span v-if="activeLink === 'beheersing-cruciale-leerdoelen'">Leerdoelendossier</span>
    <span v-if="activeLink === 'beheersing-actualiseren'">Beheersing actualiseren</span>
    <span v-if="activeLink === 'maak-periodeplan'">Bewerk periodeplan </span>
    <span v-if="activeLink === 'bevestig-conceptplan'">Bevestig conceptplan</span>
    <span v-if="activeLink === 'bewerk-periodeplan'">Periodeplan</span>
    <span v-if="activeLink === 'bekijk-exporteer-print'">Bekijk, exporteer en print</span>

    <span v-if="activeLink === 'leerroute'">Respons op de leerroute</span>
  </div>
</template>

<script>
export default {
  name: 'groep-submenu',
  props: {
    activeLink: String,
    parent: String
  }
}
</script>
