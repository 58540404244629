import httpClient from '../HttpClient'

export default {
  getLesmethoden: async (schoolId, vak) =>
    httpClient.getCached(`/scholen/${schoolId}/lesmethoden`, { minutesToCache: 60, cacheTags: 'lesmethoden', parameters: { vak }, toonErrorNotify: true }),
  registreerLesmethode: async (schoolId, data) =>
    httpClient.putJson(`/scholen/${schoolId}/lesmethoden`, { data, invalidateCacheTag: 'lesmethoden', toonErrorNotify: true, toonServerResponse: true }),
  verwijderLesmethode: async (schoolId, lesmethodeId) =>
    httpClient.delete(`/scholen/${schoolId}/lesmethoden/${lesmethodeId}`, { invalidateCacheTag: 'lesmethoden', toonErrorNotify: true, toonServerResponse: true })
}
