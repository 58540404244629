import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [
  {
    path: '/aanbod12',
    name: 'aanbod12-index',
    component: () => import('views/Groep12/BeredeneerdAanbod/AanbodIndex.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
  },
  {
    path: '/aanbod12/regulier',
    name: 'aanbod12-regulier',
    component: () => import('views/Groep12/BeredeneerdAanbod/RegulierAanbod.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
  },
  {
    path: '/aanbod12/thematisch',
    name: 'aanbod12-thematisch',
    component: () => import('views/Groep12/BeredeneerdAanbod/ThematischAanbod.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
  },
  {
    path: '/aanbod12/thematisch/bewerken/:themaId',
    name: 'aanbod12-bewerken',
    props: true,
    component: () => import('views/Groep12/BeredeneerdAanbod/BewerkThema.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
  },
  {
    path: '/aanbod12/thematisch/bewerken/:themaId/:subdoelId/:themanaam',
    name: 'aanbod12-bewerk-doelen',
    props: true,
    component: () => import('views/Groep12/BeredeneerdAanbod/BewerkThemaDoelen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
  }
]
