import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  getScholen: async (search, orderBy, start, limit) =>
    httpClient.call('/parnassys/scholen', { method: 'get', parameters: { search, orderBy, start, limit } }, true),
  getSchool: async id =>
    httpClient.call(`/parnassys/scholen/${id}`, { method: 'get' }, true),
  toevoegenParnassysSchool: async data =>
    httpClient.call('/parnassys/scholen', { method: 'post', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} parnassys synchronisatie van de  school`, true),
  verwijderParnassysSchool: async id =>
    httpClient.call(`/parnassys/scholen/${id}`, { method: 'delete' }, true, `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} parnassys synchronisatie van de school`)
}
