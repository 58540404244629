
<template>
  <div class="mega-menu flex">
    <div class="inner-menu flex justify-right v-middle flex-auto margin-auto">
      <section class="page-section submenu flex-auto" >
        <span class="bestuur-icon"> </span>
        <br />
        <h2>Bestuur</h2>

        <section class="nav-section">
          <div class="label-container">
            <router-link :to="{ name: 'meta-scholen-vix' }">Elke school (vix)</router-link>
          </div>
        </section>

        <section class="nav-section">
          <div class="label-container">
            <router-link :to="{ name: 'meta-scholen-referentieniveaus' }">Elke school (referentieniveaus)</router-link>
          </div>
        </section>

        <section class="nav-section">
          <div class="label-container">
            <router-link :to="{ name: 'meta-bestuur-vix' }">Bestuur (vix)</router-link>
          </div>
        </section>
        <hr>
      </section>

      <section class="page-section beheer-color submenu flex-auto">
        <span class="beheer-icon"> </span>
        <br />
        <h2 v-if="heeftFeatureBeheer">Beheer</h2>
        <h2 v-else @click="logout()" class="pointer">Inloggen beheerder</h2>

        <section class="nav-section">
          <div class="label-container">
            <p>
              <router-link :to="{ name: 'meta-mijn-gegevens' }" @click="verbergMenu">Mijn gegevens</router-link>
            </p>
          </div>
        </section>

        <section v-if="false" class="nav-section">
          <div class="label-container">
            <p>
              <router-link :to="{ name: 'meta-registratie-afronden' }" :class="{ actief: actieveLink === 'AfrondenRegistratie' }" @click="verbergMenu">Afronden registratie</router-link>
            </p>
          </div>
        </section>

        <section class="nav-section">
          <div class="label-container">
            <p>
              <router-link :to="{ name: 'meta-toegang' }" @click="verbergMenu">Toegang</router-link>
            </p>
          </div>
        </section>

        <section v-if="true" class="nav-section">
          <div class="label-container">
            <p>
              <router-link :to="{ name: 'meta-databeheer' }" @click="verbergMenu">Databeheer</router-link>
            </p>
          </div>
        </section>

        <section v-if="true" class="nav-section">
          <div class="label-container">
            <p>
              <router-link :to="{ name: 'meta-avg-beheer' }" @click="verbergMenu">AVG-Beheer</router-link>
            </p>
          </div>
        </section>

      </section>
    </div>
  </div>
</template>

<style src="../../assets/css/components/Navigatie/metaMegaMenu.scss" scoped />

<script setup>
import { inject, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const stores = inject('stores')
// const features = inject('features')

// const ingelogdeGebruiker = computed(() => stores.account.ingelogdeGebruiker)
// const registratieAfgerond = computed(() => huidigeSchool.value ? huidigeSchool.value.registratieIsCompleet : true)
// const heeftFeatureBeheer = computed(() => ingelogdeGebruiker.value && ingelogdeGebruiker.value.features.includes(features.Beheer))
// const heeftFeatureBeheerImporteren = computed(() => ingelogdeGebruiker.value && ingelogdeGebruiker.value.features.includes(features.BeheerImporteren))
// const heeftFeatureBeheerMijnGegevens = computed(() => ingelogdeGebruiker.value && ingelogdeGebruiker.value.features.includes(features.BeheerMijnGegevens))
// const heeftFeatureBeheerToegang = computed(() => ingelogdeGebruiker.value && ingelogdeGebruiker.value.features.includes(features.BeheerToegang))

const heeftFeatureBeheer = true

const api = inject('api')
const nieuwstegels = ref(null)
onMounted(() => setTimeout(() => getNieuwstegels(), 1000))
function getNieuwstegels () {
  api.content.getNieuwstegels()
    .then(response => {
      nieuwstegels.value = response.result.nieuwstegels
    })
    .catch(() => {
      nieuwstegels.value = []
    })
}

const router = useRouter()
function logout () {
  stores.account.logout()
    .finally(() => {
      router.push('/')
    })
}
// *zucht* een klik op de link naar de huidige pagina sluit het menu niet. Daarom moeten we een emit doen naar de parent component die het menu kan verbergen.
// Echterrrr wil je dat niet doen als de gebruiker wel op een andere link klikt aangezien hij dan eerst het menu sluit en daarna navigeert, waardoor het scherm 'knippert'
const route = useRoute()
const emit = defineEmits(['verbergMenu'])
function verbergMenu (event) {
  if (event.target.pathname === route.path) {
    emit('verbergMenu')
  }
}

</script>
