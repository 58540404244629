import httpClient from '../HttpClient'

export default {
  getThemaplannenGroep: async (schoolId, groepId) =>
    httpClient.get(`/scholen/${schoolId}/groepen/${groepId}/themaplannen`),
  getThemaplanGroep: async (schoolId, groepId, themaplanId) =>
    httpClient.get(`/scholen/${schoolId}/groepen/${groepId}/themaplannen/${themaplanId}`),
  toevoegenThemaplanGroep: async (schoolId, groepId) =>
    httpClient.post(`/scholen/${schoolId}/groepen/${groepId}/themaplannen`),
  toevoegenThemaplanLeerjaar: async (schoolId, leerjaar) =>
    httpClient.post(`/scholen/${schoolId}/leerjaren/${leerjaar}/themaplannen`),
  selecteerThemaThemaplanGroep: async (schoolId, groepId, data) =>
    httpClient.put(`/scholen/${schoolId}/groepen/${groepId}/themaplannen/thema`, { data }),
  selecteerThemaThemaplanLeerjaar: async (schoolId, leerjaar, themaplanId, data) =>
    httpClient.put(`/scholen/${schoolId}/leerjaren/${leerjaar}/themaplannen/${themaplanId}/thema`, { data }),
  bevestigThemaplanstap: async (schoolId, groepId, themaplanId, data) =>
    httpClient.patchJson(`/scholen/${schoolId}/groepen/${groepId}/themaplannen/${themaplanId}/stappen`, { data }),
  // subdomeinen
  getThemaplanDoelen: async (schoolId, groep, themaplanId, vak) =>
    httpClient.get(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/beheersing/${vak}`),
  toevoegenThemaplanSubdomeinen: async (schoolId, groep, themaplanId, vak, domeinIds) =>
    httpClient.put(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/${vak}/domeinen`, { data: domeinIds }),
  // verwijderenThemaplanSubdomeinen: async (schoolId, groep, themaplanId, domeinIds) =>
  //  httpClient.delete(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/domeinen`, { data: domeinIds }),
  // groepen
  getWerkgroepen: async (schoolId, groep, themaplanId, domein, pakket) =>
    httpClient.get(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/werkgroepen/${domein}/${pakket}`),
  updateWerkgroepen: async (schoolId, groep, themaplanId, domein, data, pakket) =>
    httpClient.put(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/werkgroepen/${domein}/${pakket}`, { data }),
  verwijderUitWerkgroepen: async (schoolId, groep, themaplanId, domein, data, pakket) =>
    httpClient.deleteJson(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/werkgroepen/${domein}/${pakket}`, { data }),
  resetWerkgroepen: async (schoolId, groep, themaplanId, domein, pakket) =>
    httpClient.options(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/werkgroepen/${domein}/${pakket}/reset`),
  // aanvullen
  getThemaActiviteiten: async (schoolId, groep, themaplanId) =>
    httpClient.get(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/activiteiten`),
  updateThemaActiviteit: async (schoolId, groep, themaplanId, activiteitId, data) =>
    httpClient.patch(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/activiteiten/${activiteitId}`, { data }),
  registreerThemaActiviteit: async (schoolId, groep, themaplanId, data) =>
    httpClient.post(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/activiteiten`, { data }),
  getThemavelden: async (schoolId, groep, themaplanId) =>
    httpClient.get(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/velden`),
  getThemaveld: async (schoolId, groep, themaplanId, veldId) =>
    httpClient.get(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/velden/${veldId}`),
  updateThemaveld: async (schoolId, groep, themaplanId, veldId, data) =>
    httpClient.patch(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/velden/${veldId}`, { data }),
  updateThemaStatus: async (schoolId, groep, themaplanId, data) =>
    httpClient.putJson(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/status`, { data }),
  getZonegroepen: async (schoolId, groep, themaplanId, vak) =>
    httpClient.get(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/zonegroepen/${vak}`),
  updateZonegroepen: async (schoolId, groep, themaplanId, doelId, data) =>
    httpClient.patch(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/doelen/${doelId}`, { data }),
  getAnnotaties: async (schoolId, groep, themaplanId) =>
    httpClient.get(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/annotaties`),
  updateAnnotaties: async (schoolId, groep, themaplanId, data) =>
    httpClient.put(`/scholen/${schoolId}/groepen/${groep}/themaplannen/${themaplanId}/annotaties`, { data }),
  // uploads
  getThemaplanBijlagen: async (schoolId, themaplanId) =>
    httpClient.get(`/scholen/${schoolId}/themaplannen/${themaplanId}/bijlagen`),
  exporteerThemaplanBijlage: async (schoolId, themaplanId, uploadId) =>
    httpClient.get(`/scholen/${schoolId}/themaplannen/${themaplanId}/bijlagen/${uploadId}`)
}
