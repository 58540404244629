
import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [{
  path: '/meta/registratie-afronden',
  name: 'meta-registratie-afronden',
  props: true,
  component: () => import('views/Meta/RegistratieAfronden.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
},
{
  path: '/meta/scholen/vix',
  name: 'meta-scholen-vix',
  component: () => import('views/Meta/ScholenVix.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
},
{
  path: '/meta/scholen/referentieniveaus',
  name: 'meta-scholen-referentieniveaus',
  component: () => import('views/Meta/ScholenReferentieniveaus.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
},
{
  path: '/meta/bestuur/vix',
  name: 'meta-bestuur-vix',
  component: () => import('views/Meta/BestuurVix.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
},
{
  path: '/meta/mijn-downloads',
  name: 'meta-mijn-downloads',
  component: () => import('views/Meta/MijnDownloads.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
},
{
  path: '/meta/beheer/mijn-gegevens',
  name: 'meta-mijn-gegevens',
  component: () => import('views/Meta/MijnGegevens/Overzicht.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
},
{
  path: '/meta/beheer/mijn-gegevens/wijzig',
  name: 'meta-wijzig-gegevens',
  component: () => import('views/Meta/MijnGegevens/Wijzigen.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
},
{
  path: '/meta/beheer/toegang',
  name: 'meta-toegang',
  component: () => import('views/Meta/Toegang/Overzicht.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
},
{
  path: '/meta/beheer/toegang/toevoegen',
  name: 'meta-toegang-toevoegen',
  component: () => import('views/Meta/Toegang/Toevoegen.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
},
{
  path: '/meta/beheer/toegang/details/:id',
  name: 'meta-toegang-details',
  props: true,
  component: () => import('views/Meta/Toegang/Details.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
},
{
  path: '/meta/beheer/toegang/wijzigen/:id',
  name: 'meta-toegang-wijzigen',
  props: true,
  component: () => import('views/Meta/Toegang/Wijzigen.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
},
{
  path: '/meta/beheer/databeheer',
  name: 'meta-databeheer',
  component: () => import('views/Meta/Databeheer.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
},
{
  path: '/meta/beheer/avg-beheer',
  name: 'meta-avg-beheer',
  component: () => import('views/Meta/Avg/Overzicht.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
},
{
  path: '/meta/beheer/avg-beheer/details/:id',
  name: 'meta-avg-beheer-details',
  props: true,
  component: () => import('views/Meta/Avg/Details.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Bestuur) }
}]
