import httpClient from '../HttpClient'

export default {
  getJaarplannen: async (schoolId) =>
    httpClient.getCached(`/scholen/${schoolId}/jaarplannen`, { minutesToCache: 5, cacheTags: ['jaarplannen'], cacheKey: 'jaarplannen' }),
  getJaarplanning: async (schoolId, jaarplanId) =>
    httpClient.get(`/scholen/${schoolId}/jaarplannen/${jaarplanId}`),
  getBlokplanning: async (schoolId, jaarplanId) =>
    httpClient.get(`/scholen/${schoolId}/jaarplannen/${jaarplanId}/blokplanning`),
  wijzigBlokplanning: async (schoolId, jaarplanId, data) =>
    httpClient.patchJson(`/scholen/${schoolId}/jaarplannen/${jaarplanId}/blokplanning`, { data, invalidateCacheTag: 'jaarplannen' }),
  getThemaplanning: async (schoolId, jaarplanId) =>
    httpClient.get(`/scholen/${schoolId}/jaarplannen/${jaarplanId}/themaplanning`),
  wijzigThemaplanning: async (schoolId, jaarplanId, data) =>
    httpClient.patchJson(`/scholen/${schoolId}/jaarplannen/${jaarplanId}/themaplanning`, { data, invalidateCacheTag: 'jaarplannen' })
}
