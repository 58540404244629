import httpClient from '../HttpClient'

export default {
  getBasisinstellingen: async (schoolId) =>
    httpClient.get(`/scholen/${schoolId}/rapporten/instellingen`),
  wijzigBasisinstelling: async (schoolId,  data) => {
    const headers = { 'Content-Type': 'multipart/form-data' }
    httpClient.put(`/scholen/${schoolId}/rapporten/instellingen`, { data, headers })
  },
  verwijderBasisinstelling: async (schoolId,  instellingId) =>
    httpClient.delete(`/scholen/${schoolId}/rapporten/instellingen/${instellingId}`)
}
