
import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [
  {
    path: '/alle-gebruikers',
    name: 'admin-gebruikers-overzicht',
    component: () => import('views/Admin/Gebruikers/GebruikersOverzicht.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToegang) }
  }]
