import { defineStore } from 'pinia'
import { sortAsc } from '@/helpers/simpleSort'

// Store voor de selecties, besturen en scholen in Focus PO Meta ('bestuursmodule')
export const useBesturenStore = defineStore('Besturen', {
  state: () => {
    return {
      bestuurnummerStatus: '',
      currentPage: 1,
      currentOrderBy: '',
      currentSearch: '',
      demoBestuurId: null,
      bestuur: null,
      scholenSelectie: null
    }
  },
  getters: {
    getHuidigBestuurId: (state) => state.bestuur?.id ?? state.demoBestuurId
  },
  actions: {
    getBestuur (provider, bestuurId) {
      return new Promise((resolve, reject) => {
        provider.getBestuur(bestuurId)
          .then(response => {
            this.bestuur = response.result
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async setSchoolLijst (provider, bestuurId) {
      await new Promise((resolve, reject) => {
        provider.getScholen(bestuurId)
          .then(response => {
            this.scholenSelectie = response.result.scholen
              ?.sort((a, b) => sortAsc(a.naam, b.naam))
              ?.filter(school => !school.focusSBO)
              ?.map(school => {
                school.oudeImport = school.toetsresultatenZijnVerouderd;
                school.label = school.naam
                school.value = school.id
                school.abonnement = school.abonnementen?.find(f => f.isActief)

                return school
              })
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            resolve()
          })
      })
    },
    setCurrentPage (page) {
      this.currentPage = page
    },
    setCurrentOrderBy (orderBy) {
      this.currentOrderBy = orderBy
    },
    setCurrentSearch (search) {
      this.currentSearch = search
    },
    resetSearch () {
      this.currentPage = 1
      this.currentOrderBy = ''
      this.currentSearch = ''
    },
    setDemoBestuur (bestuurId) {
      this.demoBestuurId = bestuurId
      this.scholenSelectie = null
    },
    reset () {
      this.bestuurnummerStatus = ''
      this.currentPage = 1
      this.currentOrderBy = ''
      this.currentSearch = ''
      this.demoBestuurId = null
      this.bestuur = null
      this.scholenSelectie = null
    }
  },
  persist: true
})

function monthDiff (d1, d2) {
  let months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth() + 1
  months += d2.getMonth()
  return months <= 0 ? 0 : months
}
