import importRoutes from './importRoutes'
import instellingenRoutes from './instellingenRoutes'
import leerlingRoutes from './leerlingRoutes'
import mijnGegevensRoutes from './mijnGegevensRoutes'
import monitorRoutes from './monitorRoutes'
import toegangRoutes from './toegangRoutes'

export default [
  importRoutes,
  instellingenRoutes,
  leerlingRoutes,
  mijnGegevensRoutes,
  monitorRoutes,
  toegangRoutes
].flat()
