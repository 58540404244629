<template>
  <div class="sub-menu">
    <router-link :to="{ name:'welkom' }">home</router-link>
    <span> ></span>
    <span>groep 1-2</span>
    <span> ></span>

    <!--Themaoverzicht-->
    <router-link v-if="parent === 'cruciale-leerdoelen' && activeLink !== 'actualiseren'" :to="{ name: 'cruciale-leerdoelen-12' }">Themaoverzicht</router-link>
    <router-link v-if="parent === 'cruciale-leerdoelen' && activeLink === 'actualiseren'" :to="{ name: 'cruciale-leerdoelen-12' }">Leerdoelendossier</router-link>
    <span v-if="parent === 'cruciale-leerdoelen' && activeLink !== 'actualiseren'"> ></span>

    <span v-if="activeLink === 'cruciale-leerdoelen'">Themaoverzicht</span>
<!--    <span v-if="activeLink === 'actualiseren'">Actualiseren</span>-->
    <span v-if="activeLink === 'subdomeinen'">Specifieke subdomeinen</span>
    <span v-if="activeLink === 'groeperen'">PO-groepen</span>
    <span v-if="activeLink === 'aanvullen'">Themaplan aanvullen</span>
    <span v-if="activeLink === 'bevestigen'">Themaplan bevestigen</span>
    <span v-if="activeLink === 'exporteren'">Bekijk, exporteer en print</span>

    <!--Leerdoelendossier-->
    <span v-if="activeLink === 'leerdoelendossier'">Leerdoelendossier</span>

    <!--Groepsoverzicht-->
    <span v-if="activeLink === 'groepsoverzicht'">Groepsoverzicht</span>

    <!--Beredeneerd aanbod-->
    <router-link v-if="parent === 'beredeneerd-aanbod' || parent === 'thematisch-aanbod' || parent === 'bewerk-thema'" :to="{ name: 'aanbod12-index' }">Beredeneerd aanbod</router-link>
    <span v-if="parent === 'beredeneerd-aanbod' || parent === 'thematisch-aanbod' || parent === 'bewerk-thema'"> ></span>

    <router-link v-if="parent === 'thematisch-aanbod' || parent === 'bewerk-thema'" :to="{ name: 'aanbod12-thematisch' }">Thematisch aanbod</router-link>
    <span v-if="parent === 'thematisch-aanbod' || parent === 'bewerk-thema'"> ></span>

    <router-link v-if="parent === 'bewerk-thema'" :to="{ name: 'aanbod12-bewerken', params: { themaId: themaId  } }">Thema bewerken</router-link>
    <span v-if="parent === 'bewerk-thema' "> ></span>

    <span v-if="activeLink === 'beredeneerd-aanbod'">Beredeneerd aanbod</span>
    <span v-if="activeLink === 'thematisch-aanbod'">Thematisch aanbod</span>
    <span v-if="activeLink === 'regulier-aanbod'">Regulier aanbod</span>
    <span v-if="activeLink === 'bewerk-thema'">Thema bewerken</span>
    <span v-if="activeLink === 'bewerk-thema-doelen'">Doelen en activiteiten beheren</span>

  </div>
</template>

<script>
export default {
  name: 'groep12-submenu',
  props: {
    parent: String,
    activeLink: String,
    themaId: String
  }
}
</script>
