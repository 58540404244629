import httpClient from '../HttpClient'

const headers = { 'Content-Type': 'multipart/form-data' }
export default {
  getSchoolplannen: async (schoolId, parameters = {}) => {
    return httpClient.get(`/scholen/${schoolId}/schoolplannen`, { parameters })
  },
  getSchoolplan: async (schoolId, schoolplanId) => {
    return httpClient.get(`/scholen/${schoolId}/schoolplannen/${schoolplanId}`)
  },
  downloadSchoolplan: async (schoolId, schoolplanId) => {
    return httpClient.download(`/scholen/${schoolId}/schoolplannen/${schoolplanId}/bestand`)
  },
  postSchoolplan: async (schoolId, data) => {
    return httpClient.post(`/scholen/${schoolId}/schoolplannen`, { data, headers, toonErrorNotify: true })
  },
  updateSchoolplan: async (schoolId, schoolplanId, data) => {
    return httpClient.patchJson(`/scholen/${schoolId}/schoolplannen/${schoolplanId}`, { data })
  },
  deleteSchoolplan: async (schoolId, schoolplanId) => {
    return httpClient.delete(`/scholen/${schoolId}/schoolplannen/${schoolplanId}`)
  }
}
