import httpClient from '../HttpClient'

const headers = { 'Content-Type': 'application/json' }
export default {
  getSchoolVakken: async (schoolId, peildatum, meetmoment, leerjaren, vakken) =>
    httpClient.getCached(`/scholen/${schoolId}/opbrengsten`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], headers, parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { leerjaren, vakken } }),
  getDoelgroepVakken: async (schoolId, doelgroepId, peildatum, meetmoment, leerjaren, vakken) =>
    httpClient.getCached(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/opbrengsten`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { leerjaren, vakken } }),
  getGroepVakken: async (schoolId, groepId, peildatum, meetmoment, vakken) =>
    httpClient.getCached(`/scholen/${schoolId}/groepen/${groepId}/opbrengsten`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { vakken } }),
  getLeerjaarVakken: async (schoolId, leerjaar, peildatum, meetmoment, vakken) =>
    httpClient.getCached(`/scholen/${schoolId}/leerjaren/${leerjaar}/opbrengsten`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { vakken } }),
  getLeerjaarDoelgroepVakken: async (schoolId, leerjaar, doelgroepId, peildatum, meetmoment, vakken) =>
    httpClient.getCached(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/leerjaren/${leerjaar}/opbrengsten`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { vakken } }),
  getSchoolVak: async (schoolId, vakId, peildatum, meetmoment, leerjaren) =>
    httpClient.getCached(`/scholen/${schoolId}/opbrengsten/${vakId}`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { leerjaren } }),
  getDoelgroepVak: async (schoolId, doelgroepId, vakId, peildatum, meetmoment, leerjaren) =>
    httpClient.getCached(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/opbrengsten/${vakId}`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { leerjaren } }),
  getGroepVak: async (schoolId, groepId, vakId, peildatum) =>
    httpClient.getCached(`/scholen/${schoolId}/groepen/${groepId}/opbrengsten/${vakId}`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], parameters: { peildatum: peildatum || '' } }),
  getLeerjaarVak: async (schoolId, leerjaar, vakId, peildatum) =>
    httpClient.getCached(`/scholen/${schoolId}/leerjaren/${leerjaar}/opbrengsten/${vakId}`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], parameters: { peildatum: peildatum || '' } }),
  getLeerjaarDoelgroepVak: async (schoolId, leerjaar, doelgroepId, vakId, peildatum) =>
    httpClient.getCached(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/leerjaren/${leerjaar}/opbrengsten/${vakId}`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], parameters: { peildatum: peildatum || '' } }),
  getGroepenVak: async (schoolId, vakId, peildatum, meetmoment, leerjaren) =>
    httpClient.getCached(`/scholen/${schoolId}/groepen/opbrengsten/${vakId}`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { leerjaren } }),
  getGroepenDoelgroepVak: async (schoolId, doelgroepId, vakId, peildatum, meetmoment, leerjaren) =>
    httpClient.getCached(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/groepen/opbrengsten/${vakId}`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { leerjaren } }),
  getLeerjarenVak: async (schoolId, vakId, peildatum, meetmoment, leerjaren) =>
    httpClient.getCached(`/scholen/${schoolId}/leerjaren/opbrengsten/${vakId}`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { leerjaren } }),
  getLeerjarenDoelgroepVak: async (schoolId, doelgroepId, vakId, peildatum, meetmoment, leerjaren) =>
    httpClient.getCached(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/leerjaren/opbrengsten/${vakId}`, { minutesToCache: 15, cacheTags: ['toetsresultaten', 'doelgroepen'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { leerjaren } }),
  getAmbities: async (schoolId) =>
    httpClient.get(`/scholen/${schoolId}/ambities`),
  getAmbitiesDoelgroep: async (schoolId, doelgroepId) =>
    httpClient.get(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/ambities`),
  getAmbitiesVak: async (schoolId, vakId) =>
    httpClient.getCached(`/scholen/${schoolId}/ambities/${vakId}`, { minutesToCache: 30, cacheTags: ['ambities'] }),
  getAmbitiesDoelgroepVak: async (schoolId, doelgroepId, vakId) =>
    httpClient.get(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/ambities/${vakId}`),
  getVixPerLeerling: async (schoolId, groepId, vakId, toetsmoment, peildatum) =>
    httpClient.get(`/scholen/${schoolId}/groepen/${groepId}/vix/${vakId}/${toetsmoment}`, { method: 'get', parameters: { peildatum: peildatum || '' } }),
  getVixPerLeerlingLeerjaar: async (schoolId, leerjaarId, vakId, toetsmoment, peildatum) =>
    httpClient.get(`/scholen/${schoolId}/leerjaren/${leerjaarId}/vix/${vakId}/${toetsmoment}`, { method: 'get', parameters: { peildatum: peildatum || '' } }),
  getVixPerLeerlingDoelgroep: async (schoolId, groepId, vakId, doelgroepId, toetsmoment, peildatum) =>
    httpClient.get(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/groepen/${groepId}/vix/${vakId}/${toetsmoment}`, { parameters: { peildatum: peildatum || '' } }),
  getVixPerLeerlingLeerjaarDoelgroep: async (schoolId, leerjaarId, vakId, doelgroepId, toetsmoment, peildatum) =>
    httpClient.get(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/leerjaren/${leerjaarId}/vix/${vakId}/${toetsmoment}`, { parameters: { peildatum: peildatum || '' } }),
  exporteerVixPerLeerling: async (schoolId, groepId, vak, toetsmoment, peildatum) =>
    httpClient.download(`/scholen/${schoolId}/groepen/${groepId}/vix/${vak}/${toetsmoment}/bestand`, { parameters: { peildatum: peildatum || '' } }),
  exporteerVixPerLeerjaar: async (schoolId, leerjaar, vak, toetsmoment, peildatum) =>
    httpClient.download(`/scholen/${schoolId}/leerjaren/${leerjaar}/vix/${vak}/${toetsmoment}/bestand`, { parameters: { peildatum: peildatum || '' } }),
  getVakken: async (schoolId) =>
    httpClient.get(`/scholen/${schoolId}/opbrengsten/vakken`)
}
