import httpClient from '../HttpClient'

const headers = { 'Content-Type': 'application/json' }
export default {
  getVakken: async (bestuurId, peildatum) =>
    httpClient.get(`/besturen/${bestuurId}/opbrengsten/vakken`, { parameters: { peildatum: peildatum || '' } }),
  getBestuurVak: async (bestuurId, vakId, peildatum, meetmoment, leerjaren) =>
    httpClient.getCached(`/besturen/${bestuurId}/opbrengsten/vakken/${vakId}`, { minutesToCache: 0, cacheTags: ['toetsresultaten'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { leerjaren } }),
  getBestuurVakken: async (bestuurId, peildatum, meetmoment, leerjaren, vakken) =>
    httpClient.getCached(`/besturen/${bestuurId}/opbrengsten/vakken`, { minutesToCache: 15, cacheTags: ['toetsresultaten'], headers, parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { leerjaren, vakken } }),
  getLeerjaarVakken: async (bestuurId, peildatum, meetmoment, leerjaar, vakken) =>
    httpClient.getCached(`/besturen/${bestuurId}/opbrengsten/vakken/leerjaren/${leerjaar}`, { minutesToCache: 15, cacheTags: ['toetsresultaten'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { vakken } }),
  getLeerjaarVak: async (bestuurId, vak, peildatum, meetmoment, leerjaar) =>
    httpClient.getCached(`/besturen/${bestuurId}/opbrengsten/vakken/${vak}/leerjaren/${leerjaar}`, { minutesToCache: 15, cacheTags: ['toetsresultaten'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' } }),
  getLeerjarenVak: async (bestuurId, vak, peildatum, meetmoment, leerjaren) =>
    httpClient.getCached(`/besturen/${bestuurId}/opbrengsten/vakken/${vak}/leerjaren`, { minutesToCache: 15, cacheTags: ['toetsresultaten'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { leerjaren } })
}
