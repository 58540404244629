// Sorry voor de naam, ik kon niks beters verzinnen. Hernoemen mag.
// ToFixed heeft wat issue, bijv het afronden van 2.55 naar 2.5 en 2.4499 naar 2.5
import { clamp } from '../helpers/clamp'

export const betterFixed = (number, decimals, decimalsWhenRound, responseIfNaN = '0') => {
  if (!number || !parseFloat(number)) return responseIfNaN
  decimals = clamp(decimals, 0, 100)
  decimalsWhenRound = clamp(decimalsWhenRound, 0, 100)

  // wederom sorry voor de naam. Dit getal is nodig om goed af te ronden op het aantal gegeven decimalen
  const roundFix = Math.pow(10, decimals)
  const rounded = Math.round(parseFloat(number) * roundFix) / roundFix
  return rounded % 1 ? rounded.toFixed(decimals) : rounded.toFixed(decimalsWhenRound)
}
