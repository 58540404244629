import { createWebHistory, createRouter } from 'vue-router';
import onderhoudsRoutes from './onderhoudsRoutes';
import focusRoutes from './focusRoutes';

const routes = parseInt(import.meta.env.VITE_IN_ONDERHOUD) ? onderhoudsRoutes : focusRoutes;
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        el: to.hash,
                        behavior: 'smooth',
                    });
                }, 200);
            });
        }
    },
});

router.onError((error, to) => {
    if (error.message.includes('dynamically imported module')) {
        window.location.reload();
    }
});
export default router;
