<template>
  <article class="page-container groep-layout">
    <header>
      <div class="blue-gray-gradient">
        <slot name="nav-slot">
          <beheerder-menu></beheerder-menu>
        </slot>
      </div>
      <div v-if="hasHeaderSlot" class="header-container">
        <slot name="header-slot"></slot>
      </div>
    </header>

    <div v-if="hasBodySlot" class="slot-container">
      <slot name="body-slot" ></slot>
    </div>
    <div v-if="hasFullWidthSlot" class="full-width-container">
      <slot name="full-width-slot" ></slot>
    </div>

    <footer v-if="hasFooterSlot">
      <slot name="footer-slot" />
      <!--<div class="footer-copyright-wrapper">
         <span class="footer-copyright-text"> ©2020 Focus Onderwijs BV | <a href="http://www.focuspo.nl" target="_blank">Focus PO website</a> | <a href="http://www.getthere.nl" target="_blank">Powered by Get There</a></span>
      </div>-->
    </footer>
  </article>
</template>

<script>
export default {
  async created () {
    if (!this.$gt.stores.account.isAuthenticated) {
      this.$router.push('/')
    }
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header-slot']
    },
    hasBodySlot () {
      return !!this.$slots['body-slot']
    },
    hasFullWidthSlot () {
      return !!this.$slots['full-width-slot']
    },
    hasFooterSlot () {
      return !!this.$slots['footer-slot']
    }
  }
}
</script>

<style scoped>
  header {
    background: #674D94;
  }
  footer {
    bottom: 0;
    height: 50px;
  }
  .slot-container {
    padding: 20px 0 10px 0 !important;
  }
  h1 {
    color: white;
  }
</style>
