import httpClient from '../HttpClient';
import { errors } from 'constants/apiErrors';

const headers = { 'Content-Type': 'multipart/form-data' };
export default {
  importeerLeerlingenEsis: async (schoolId, data) =>
    httpClient.post(`/scholen/${schoolId}/leerlingen/esis/bestand`, {
      data,
      headers,
      invalidateCacheTag: 'toetsresultaten',
      toonErrorNotify: true,
      errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} import`,
      toonServerResponse: true,
    }),
  importeerLeerlingenParnassys: async (schoolId) =>
    httpClient.call(`/scholen/${schoolId}/leerlingen/parnassys`, { method: 'post' }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} import`, true),
  getLeerlingenInLeerjaar: async (schoolId, leerjaar) =>
    httpClient.get(`/scholen/${schoolId}/leerjaren/${leerjaar}/leerlingen`),
  getLeerlingVix: async (schoolId, leerlingId, trendZone, trendZoneFilter, referentieType) =>
    httpClient.get(`/scholen/${schoolId}/leerlingen/${leerlingId}/vix`, { parameters: { trendzone: trendZone, filter: trendZoneFilter, referentieniveaus: referentieType } }),
};
