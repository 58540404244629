import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  getKoppelingen: async (schoolId) =>
    httpClient.call(`/scholen/${schoolId}/koppelingen`, { method: 'get' }, false, `${errors.FOUT_OPGETREDEN_OPHALEN_DE} koppeling`),
  wijzigKoppelingen: async (schoolId, bron, data) => {
    const headers = { 'Content-Type': 'application/json' }
    return httpClient.call(`/scholen/${schoolId}/koppelingen/${bron}/actief`, { method: 'patch', data, headers }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} koppeling`)
  },
  getSleutel: async (schoolId, bron, soort) =>
    httpClient.call(`/scholen/${schoolId}/koppelingen/${bron}/${soort}/autorisatiesleutel`, { method: 'put' }, false, `${errors.FOUT_OPGETREDEN_OPHALEN_DE} autorisatiesleutel`),
  verwijderSleutel: async (schoolId, bron, soort) =>
    httpClient.call(`/scholen/${schoolId}/koppelingen/${bron}/${soort}/autorisatiesleutel`, { method: 'delete' }, false, `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} autorisatiesleutel`)
}
