<template>
  <div class="sub-menu">
    <router-link :to="{ name:'meta' }">home</router-link>
    <span> ></span>
    <span v-if="activeLink === 'mijn-downloads'">Mijn Downloads</span>
    <span v-else-if="activeLink === 'mijn-gegevens'">Mijn gegevens</span>
    <span v-else-if="activeLink === 'registratie-afronden'">Registratie afronden</span>
    <span v-else-if="activeLink === 'demo'">Demo</span>

    <span v-else-if="activeLink === 'toegang'">Toegang</span>
    <template v-else-if="parent === 'toegang'">
      <router-link :to="{ name:'meta-toegang' }">Toegang</router-link>
      <span>></span>
      <span v-if="activeLink === 'toegang-toevoegen'">Toevoegen</span>
      <span v-else-if="activeLink === 'toegang-details'">Details</span>
      <span v-else-if="activeLink === 'toegang-wijzigen'">Wijzigen</span>
    </template>

    <span v-else-if="activeLink === 'avg-beheer'">AVG-beheer</span>
    <template v-else-if="parent === 'avg-beheer'">
      <router-link :to="{ name:'meta-avg-beheer' }">AVG-beheer</router-link>
      <span>></span>
      <span v-if="activeLink === 'avg-beheer-details'">Details</span>
    </template>

    <span v-else-if="activeLink === 'databeheer'">Databeheer</span>
    <span v-else-if="activeLink === 'bestuur-vix'">Bestuur (vix)</span>
    <template v-else-if="activeLink === 'scholen-referentieniveaus' || activeLink === 'scholen-vix'">
      <span>school</span>
      <span> ></span>
      <span v-if="activeLink === 'scholen-referentieniveaus'">Elke school (referentieniveaus)</span>
      <span v-if="activeLink === 'scholen-vix'">Elke school (vix)</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'school-submenu',
  props: {
    activeLink: String,
    parent: String
  }
}
</script>
