<template>
    <div class="mega-menu flex">
        <div class="inner-menu flex space-between v-middle flex-auto margin-auto">
            <section class="school-color submenu flex-auto">
                <span class="school-icon"> </span>
                <br />
                <h2>school</h2>

                <section class="nav-section" :class="heeftFeatureOpbrengsten ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureOpbrengsten" :to="{ name: 'opbrengsten' }" @click="verbergMenu"> Toetsresultaten (OR1) </router-link>
                        <template v-else> Toetsresultaten (OR1) </template>
                    </div>
                </section>

                <section class="nav-section" :class="heeftFeatureLeerdoelen38 ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureLeerdoelen38" :to="{ name: 'leerdoelbeheersing' }" @click="verbergMenu"> Leerdoelbeheersing </router-link>
                        <template v-else> Leerdoelbeheersing</template>
                    </div>
                </section>

                <section class="nav-section disabled">
                    <div class="label-container">Burgerschap (OR2)</div>
                </section>

                <section class="nav-section" :class="heeftFeatureLeerdoelen ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureLeerdoelen" :to="{ name: 'schoolaanbod' }" @click="verbergMenu"> Schoolaanbod </router-link>
                        <template v-else> Schoolaanbod </template>
                    </div>
                </section>
            </section>

            <section class="kleutergroep-color submenu flex-auto">
                <span class="kleutergroep-icon"> </span>
                <br />
                <h2>groep 1-2</h2>

                <section class="nav-section" :class="heeftFeatureLeerdoelen12 ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureLeerdoelen12" :to="{ name: 'cruciale-leerdoelen-12' }" @click="verbergMenu"> Themaoverzicht </router-link>
                        <template v-else> Themaoverzicht </template>
                    </div>
                </section>

                <section class="nav-section" :class="heeftFeatureLeerdoelen12 ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureLeerdoelen12" :to="{ name: 'cruciale-leerdoelen-12-beheersing', params: { dossierType: 'leerdoelendossier' } }" @click="verbergMenu">
                            Leerdoelendossier
                        </router-link>
                        <template v-else> Leerdoelendossier </template>
                    </div>
                </section>

                <section class="nav-section" :class="heeftFeatureLeerdoelen12 ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureLeerdoelen12" :to="{ name: 'groep12-groepsoverzicht' }" @click="verbergMenu"> Groepsoverzicht </router-link>
                        <template v-else> Groepsoverzicht </template>
                    </div>
                </section>

                <section class="nav-section" :class="heeftFeatureLeerdoelen12 ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureLeerdoelen12" :to="{ name: 'aanbod12-index' }" @click="verbergMenu"> Beredeneerd aanbod </router-link>
                        <template v-else> Beredeneerd aanbod </template>
                    </div>
                </section>
                <br />
            </section>

            <section class="groep-color submenu flex-auto">
                <span class="groep-icon"> </span>
                <br />
                <h2>groep 3-8</h2>

                <section class="nav-section" :class="heeftFeatureLeerdoelen38 ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureLeerdoelen38" :to="{ name: 'cruciale-leerdoelen' }" @click="verbergMenu"> Periodeoverzicht </router-link>
                        <template v-else> Periodeoverzicht </template>
                    </div>
                </section>

                <section class="nav-section" :class="heeftFeatureLeerdoelen38 ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureLeerdoelen38" :to="{ name: 'cruciale-leerdoelen-beheersing' }" @click="verbergMenu"> Leerdoelendossier </router-link>
                        <template v-else> Leerdoelendossier </template>
                    </div>
                </section>

                <section class="nav-section" :class="heeftFeatureLeerdoelen38 ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureLeerdoelen38" :to="{ name: 'sel-burgerschap' }" @click="verbergMenu">SEL en burgerschap</router-link>
                        <span v-else> SEL en burgerschap </span>
                    </div>
                </section>
            </section>

            <section class="leerling-color submenu flex-auto">
                <span class="leerling-icon"> </span>
                <br />
                <h2>leerling</h2>

                <section class="nav-section" :class="heeftFeatureLeerdoelen12 || heeftFeatureOpbrengsten ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureLeerdoelen12 || heeftFeatureOpbrengsten" :to="{ name: 'normen-overzicht' }" @click="verbergMenu"> Groeioverzicht 1-8</router-link>
                        <template v-else> Groeioverzicht 1-8</template>
                    </div>
                </section>

                <section class="nav-section" :class="heeftFeatureRapporten ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureRapporten" :to="{ name: 'leerling-rapport' }" @click="verbergMenu"> Rapport 1-8</router-link>
                        <template v-else> Rapport 1-8</template>
                    </div>
                </section>

                <section class="nav-section" :class="heeftFeatureLeerdoelen12 ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureLeerdoelen12" :to="{ name: 'overdracht-beheersing' }" @click="verbergMenu"> Overdracht 2 > 3 </router-link>
                        <template v-else> Overdracht 2 > 3 </template>
                    </div>
                </section>

                <section class="nav-section" :class="heeftFeatureLeerdoelen12 ? '' : 'disabled'">
                    <div class="label-container">
                        <router-link v-if="heeftFeatureLeerdoelen12" :to="{ name: 'leerling12-overzicht' }" @click="verbergMenu"> Leerlingoverzicht 1-2 </router-link>
                        <template v-else> Leerlingoverzicht 1-2 </template>
                    </div>
                </section>

                <section class="nav-section disabled">
                    <div class="label-container">Interventies</div>
                </section>
            </section>

            <section class="info-color page-section submenu flex-auto">
                <span class="info-icon"> </span><br />
                <h2>Info</h2>

                <section class="nav-section" v-for="nieuwstegel in nieuwstegels" :key="nieuwstegel.id">
                    <div class="label-container">
                        <a :href="nieuwstegel.url" class="nieuwstegel-onderwerp" target="_blank" rel="noopener">{{ nieuwstegel.onderwerp }}</a>
                    </div>
                </section>
            </section>

            <section class="page-section beheer-color submenu flex-auto">
                <span class="beheer-icon"> </span>
                <br />
                <h2 v-if="heeftFeatureBeheer">Beheer</h2>
                <h2 v-else @click="logout()" class="pointer">Inloggen beheerder</h2>

                <section v-if="heeftFeatureBeheerMijnGegevens && registratieAfgerond" class="nav-section">
                    <div class="label-container">
                        <router-link :to="{ name: 'mijn-gegevens' }" @click="verbergMenu">Mijn gegevens</router-link>
                    </div>
                </section>

                <section v-else-if="heeftFeatureBeheer && !registratieAfgerond" class="nav-section">
                    <div class="label-container">
                        <router-link :to="{ name: 'registratie-afronden' }" :class="{ actief: actieveLink === 'AfrondenRegistratie' }" @click="verbergMenu">Afronden registratie</router-link>
                    </div>
                </section>

                <section v-if="heeftFeatureBeheerImporteren" class="nav-section">
                    <div class="label-container">
                        <router-link :to="{ name: 'leerlinggegevens-importeren' }" @click="verbergMenu">Import</router-link>
                    </div>
                </section>

                <section v-if="heeftFeatureBeheerInstellingen" class="nav-section">
                    <div class="label-container">
                        <router-link :to="{ name: 'start1234' }" @click="verbergMenu">Instellingen</router-link>
                    </div>
                </section>

                <section v-if="heeftFeatureBeheerToegang" class="nav-section">
                    <div class="label-container">
                        <router-link :to="{ name: 'leerkrachten-overzicht' }" @click="verbergMenu">Toegang</router-link>
                    </div>
                </section>

                <section v-if="heeftFeatureBeheerLeerling" class="nav-section">
                    <div class="label-container">
                        <router-link :to="{ name: 'leerling-overzicht' }" @click="verbergMenu">Leerling</router-link>
                    </div>
                </section>

                <section v-if="heeftFeatureBeheerLeerling && (heeftFeatureLeerdoelen38 || heeftFeatureLeerdoelen12)" class="nav-section">
                    <div class="label-container">
                        <router-link :to="{ name: 'monitor-index' }" @click="verbergMenu">Monitor</router-link>
                    </div>
                </section>
            </section>
        </div>
    </div>
</template>

<script setup>
import { computed, inject, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const stores = inject('stores');
const features = inject('features');

const ingelogdeGebruiker = computed(() => stores.account.ingelogdeGebruiker);
const huidigeSchool = computed(() => stores.scholen.getHuidigeSchool);
const heeftFeatureOpbrengsten = computed(() => ingelogdeGebruiker.value?.features?.includes(features.Opbrengsten));
const heeftFeatureLeerdoelen = computed(() => ingelogdeGebruiker.value?.features?.includes(features.Leerdoelen));
const heeftFeatureLeerdoelen12 = computed(() => ingelogdeGebruiker.value?.features?.includes(features.Leerdoelen12));
const heeftFeatureLeerdoelen38 = computed(() => ingelogdeGebruiker.value?.features?.includes(features.Leerdoelen38));
const heeftFeatureRapporten = computed(() => ingelogdeGebruiker.value?.features?.includes(features.Rapporten));

const registratieAfgerond = computed(() => (huidigeSchool.value ? huidigeSchool.value.registratieIsCompleet : true));
const heeftFeatureBeheer = computed(() => ingelogdeGebruiker.value?.features?.includes(features.Beheer));
const heeftFeatureBeheerImporteren = computed(() => ingelogdeGebruiker.value?.features?.includes(features.BeheerImporteren));
const heeftFeatureBeheerMijnGegevens = computed(() => ingelogdeGebruiker.value?.features?.includes(features.BeheerMijnGegevens));
const heeftFeatureBeheerInstellingen = computed(
    () => ingelogdeGebruiker.value?.features?.includes(features.BeheerInstellingen) || ingelogdeGebruiker.value?.features?.includes(features.Systeembeheer)
);
const heeftFeatureBeheerToegang = computed(() => ingelogdeGebruiker.value?.features?.includes(features.BeheerToegang));
const heeftFeatureBeheerLeerling = computed(() => ingelogdeGebruiker.value?.features?.includes(features.BeheerLeerling));

const api = inject('api');
const nieuwstegels = ref(null);
onMounted(() => setTimeout(() => getNieuwstegels(), 1000));
function getNieuwstegels() {
    api.content
        .getNieuwstegels()
        .then((response) => {
            nieuwstegels.value = response.result.nieuwstegels;
        })
        .catch(() => {
            nieuwstegels.value = [];
        });
}

const router = useRouter();
function logout() {
    stores.account.logout().finally(() => {
        router.push('/');
    });
}
// *zucht* een klik op de link naar de huidige pagina sluit het menu niet. Daarom moeten we een emit doen naar de parent component die het menu kan verbergen.
// Echterrrr wil je dat niet doen als de gebruiker wel op een andere link klikt aangezien hij dan eerst het menu sluit en daarna navigeert, waardoor het scherm 'knippert'
const route = useRoute();
const emit = defineEmits(['verbergMenu']);
function verbergMenu(event) {
    if (event.target.pathname === route.path) {
        emit('verbergMenu');
    }
}
</script>
