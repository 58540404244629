
import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [
  {
    path: '/medewerkers',
    name: 'medewerkers-overzicht',
    component: () => import('views/Admin/Medewerkers/MedewerkersOverzicht.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToegang) }
  },
  {
    path: '/medewerkers/toevoegen',
    name: 'medewerker-toevoegen',
    component: () => import('views/Admin/Medewerkers/MedewerkerToevoegen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToegang) }
  },
  {
    path: '/medewerkers/details/:medewerkerId',
    name: 'medewerker-details',
    props: true,
    component: () => import('views/Admin/Medewerkers/MedewerkerDetails.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToegang) }
  },
  {
    path: '/medewerkers/wijzigen/:medewerkerId',
    name: 'medewerker-wijzigen',
    props: true,
    component: () => import('views/Admin/Medewerkers/MedewerkerWijzigen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToegang) }
  }]
