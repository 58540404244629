import { defineStore } from 'pinia'
import cloneDeep from 'clone-deep'

/*
Dit is een simpele (!) cache om de (succesvolle) responses van de API die niet vaak wijzigen te behouden. Denk aan de lijst met groepen of leerlingen.
Voor voorbeelden hoe dit gebruikt kan worden: zoek in dit project op "httpClient.getCached" en "InvalidateCache".

Er kan gecached worden op key of tag. Een key is uniek, bijvoorbeeld een ID. Een tag kan bij meerdere calls worden gebruikt.
Als de gebruiker bijvoorbeeld de doelgroepen reset wil je meerdere 'calls' invalidaten

Cache responses niet te lang. Als een andere gebruiker van dezelfde school iets wijzigt blijft de cache voor deze gebruiker in stand.
Het is vooral handig voor pagina's waar gebruikers veel heen-en-weer navigeren, zoals rapportages en periodeplannen.
*/

export const useCacheStore = defineStore('Cache', {
  state: () => {
    return {
      cacheStorage: {}
    }
  },
  getters: {
    getAll: state => state.cacheStorage,
    tryGetCache: state => (key) => {
      if (key && state.cacheStorage[key] && state.cacheStorage[key].expiryDate > new Date().getTime()) {
        // Ook hier een deep clone zodat de cache niet wijzigt
        const clonedCache = cloneDeep(state.cacheStorage[key])
        return clonedCache.data
      } else {
        return null
      }
    }
  },
  actions: {
    addToCache (data, key, lifetimeInMinutes, tags = []) {
      const expiryDate = new Date().getTime() + (lifetimeInMinutes * 60000)
      this.cacheStorage[key] = { data, expiryDate, tags }
    },
    // InvalidateCacheKey
    removeByKey (key) {
      delete this.cacheStorage[key]
    },
    // InvalidateCacheTag
    removeByTag (tag) {
      for (const key in this.cacheStorage) {
        if (this.cacheStorage[key]?.tags?.includes(tag)) {
          delete this.cacheStorage[key]
        }
      }
    },
    removeExpiredItems () {
      const currentTime = new Date().getTime()
      for (const key in this.cacheStorage) {
        if (this.cacheStorage[key].expiryDate < currentTime) {
          delete this.cacheStorage[key]
        }
      }
    },
    reset () {
      this.cacheStorage = {}
    }
  },
  persist: false
})
