import { defineStore } from 'pinia'

// Store voor het opslaan van selecties binnen het groep 1-2 "domein". Omdat het meegeven van parameters en props niet altijd handig is, staan deze in de store.
export const useParamsGroep12Store = defineStore('ParamsGroep12', {
  state: () => {
    return {
      groepId: null,
      groepsNaam: null,
      vak: null,
      niveau: null
    }
  },
  actions: {
    reset () {
      this.groepId = null
      this.groepsNaam = null
      this.vak = null
      this.niveau = null
    }
  },
  persist: true
})
