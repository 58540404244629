<template>
  <nav-header :actieveLink="props.actieveLink" v-click-away="onClickAway">
    <template v-if="toonSchoolnaam" v-slot:naam-slot>
      <div class="middle flex bold fs-18 ml-40" style="color: #06254A;">{{ stores.scholen.demoSchool?.naam }}</div>
    </template>
    <template v-slot:menu-slot>
      <span class="menu-link">{{ ingelogdeGebruiker.naam }}</span >
      <router-link :to="{ name: 'mijn-downloads' }" class="menu-link" :class="{ actief: props.actieveLink === 'downloads', 'nieuwe-downloads': heeftNieuweDownloads }">Mijn downloads</router-link>

      <router-link :to="{ name:'welkom' }" class="menu-link home-icon pointer" :class="{ actief: props.actieveLink === 'Home'}"></router-link>
      <a href="#" class="menu-link logout-icon pointer" id="logout-button" @click="logout()"><q-tooltip :delay="500">Uitloggen</q-tooltip></a>

      <div class="mega-menu-toggle flex" @click="toggleMegaMenu">
        <div class="menu-icon"></div>
        <div>Menu</div>
      </div>

      <div v-if="inDemoModus && heeftFeatureSysteembeheer" class="mega-menu-toggle flex flex-center ml-10" style="background-color: #FF3333" @click="stopDemo(knopLink)">
        <span>Stop demo</span>
      </div>
    </template>

    <template v-slot:mega-menu-slot >
      <mega-menu :style="{ visibility: megaMenuIsVisible ? 'visible' : 'hidden'}" v-on:verberg-menu="onClickAway"></mega-menu>
    </template>
  </nav-header>
</template>

<script setup>
import megaMenu from '@/components/Navigatie/LeerkrachtMegaMenu.vue'
import { ref, computed, inject } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  actieveLink: String
})

const stores = inject('stores')
const ifeatures = inject('features')

// Er zijn 2 demo's: die van focus systeembeheerders (school -> demo) en van meta gebruikers die bij een school in het bestuur kunnen kijken. Bij systeembeheerders de schoolnaam *niet* in de header getoont worden, ook niet als die via de demo van een bestuur een school demo starten (ja ja dat kan ook)
const toonSchoolnaam = computed(() => !stores.besturen.demoBestuurId && inDemoModus)
const ingelogdeGebruiker = computed(() => stores.account.ingelogdeGebruiker)
const heeftFeatureSysteembeheer = computed(() => ingelogdeGebruiker.value?.features?.includes(ifeatures.Systeembeheer))
const heeftNieuweDownloads = computed(() => stores.downloads.nieuweDownloads)
const inDemoModus = computed(() => stores.scholen.demoSchool !== null)

const megaMenuIsVisible = ref(false)
function toggleMegaMenu () {
  megaMenuIsVisible.value = !megaMenuIsVisible.value
}

const router = useRouter()
function stopDemo () {
  stores.scholen.resetDemoSchool()
  router.push({ name: 'scholen' })
}
function logout () {
  stores.account.logout()
    .finally(() => {
      window.location.reload()
    })
}
const onClickAway = (event) => {
  if (megaMenuIsVisible.value) {
    megaMenuIsVisible.value = false
  }
}
</script>
