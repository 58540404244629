
import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [{
  path: '/groep12/cruciale-leerdoelen/overzicht',
  name: 'cruciale-leerdoelen-12',
  component: () => import('views/Groep12/StatusoverzichtCrucialeLeerdoelen.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
},
{
  path: '/groep12/cruciale-leerdoelen/beheersing/:dossierType/:modusOverride?',
  name: 'cruciale-leerdoelen-12-beheersing',
  props: true,
  component: () => import('views/Groep12/BeheersingWijzigen.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
},
{
  path: '/groep12/cruciale-leerdoelen/subdomeinen/:themaId',
  name: 'cruciale-leerdoelen-12-subdomeinen',
  props: true,
  component: () => import('views/Groep12/SpecifiekeSubdomeinen.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
},
{
  path: '/groep12/cruciale-leerdoelen/groepjes/:themaId',
  name: 'cruciale-leerdoelen-12-groepjes',
  props: true,
  component: () => import('views/Groep12/GroepeerLeerlingen.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
},
{
  path: '/groep12/cruciale-leerdoelen/aanvullen/:themaId',
  name: 'cruciale-leerdoelen-12-aanvullen',
  props: true,
  component: () => import('views/Groep12/ThemaplanAanvullen.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
},
{
  path: '/groep12/cruciale-leerdoelen/bevestigen/:themaId',
  name: 'cruciale-leerdoelen-12-bevestigen',
  props: true,
  component: () => import('views/Groep12/ThemaplanBevestigen.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
},
{
  path: '/groep12/cruciale-leerdoelen/po-groepen/:themaId/:groepId',
  name: 'cruciale-leerdoelen-12-po-groepen',
  props: true,
  component: () => import('views/Groep12/POGroepen.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
},
{
  path: '/groep12/cruciale-leerdoelen/exporteren/:themaId',
  name: 'cruciale-leerdoelen-12-exporteren',
  props: true,
  component: () => import('views/Groep12/ThemaplanExports.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
},
{
  path: '/groep12/groepsoverzicht',
  name: 'groep12-groepsoverzicht',
  props: true,
  component: () => import('views/Groep12/GroepsoverzichtView.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen12) }
}]
