<template>
  <nav-header :actieveLink="props.actieveLink" v-click-away="onClickAway">
    <template v-slot:menu-slot>
      <span class="menu-link">{{ ingelogdeGebruiker.naam }}</span >
      <router-link :to="{ name: 'meta-mijn-downloads' }" class="menu-link" :class="{ actief: props.actieveLink === 'downloads', 'nieuwe-downloads': heeftNieuweDownloads }">Mijn downloads</router-link>
      <router-link :to="{ name:'meta' }" class="menu-link home-icon pointer" :class="{ actief: props.actieveLink === 'Home'}"></router-link>
      <a href="#" class="menu-link logout-icon pointer" id="logout-button" @click="logout()"><q-tooltip :delay="500">Uitloggen</q-tooltip></a>

      <div v-if="inDemoModus" class="mega-menu-toggle flex flex-center" style="background-color: #FF3333" @click="Terug()">
        <span>Stop demo</span>
      </div>
      <div v-else class="mega-menu-toggle flex" @click="toggleMegaMenu">
        <div class="menu-icon"></div>
        <div>Menu</div>
      </div>
    </template>
    <template v-slot:mega-menu-slot >
      <meta-mega-menu :style="{ visibility: megaMenuIsVisible ? 'visible' : 'hidden'}" v-on:verberg-menu="onClickAway"></meta-mega-menu>
    </template>
  </nav-header>
</template>

<script setup>
import MetaMegaMenu from '@/components/Navigatie/MetaMegaMenu.vue'
import { ref, computed, inject } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  actieveLink: String
})

const stores = inject('stores')
const ingelogdeGebruiker = computed(() => stores.account.ingelogdeGebruiker)
const heeftNieuweDownloads = computed(() => stores.downloads.nieuweDownloads)
const inDemoModus = computed(() => stores.besturen.demoBestuurId)

const megaMenuIsVisible = ref(false)
function toggleMegaMenu () {
  megaMenuIsVisible.value = !megaMenuIsVisible.value
}

function logout () {
  stores.account.logout()
    .finally(() => {
      window.location.reload()
    })
}
const onClickAway = (event) => {
  if (megaMenuIsVisible.value) {
    megaMenuIsVisible.value = false
  }
}

const router = useRouter()
async function Terug () {
  stores.besturen.setDemoBestuur(null)
  await router.push({ name: 'gebruikers-besturen' })
}
</script>
