// Chrome doet vervelend met sort als A & B dezelfde waarde hebben.
// SonarCloud doet vervelend als je ternary gaat nesten. Daarom maar een aparte global functie om het compact én iedereen tevreden te houden
export const sortAsc = function (a, b) {
  return sort(a, b)
}

export const sortBothAsc = function (a, b, a2, b2) {
  return sortBoth(a, b, a2, b2)
}

export const sortDesc = function (a, b) {
  return sort(a, b) * -1
}

export const sortBothDesc = function (a, b, a2, b2) {
  return sortBoth(a, b, a2, b2) * -1
}

function sort (a, b) {
  if (a > b) {
    return 1
  } else if (a < b) {
    return -1
  } else {
    return 0
  }
}

function sortBoth (a, b, a2, b2) {
  if (a > b) {
    return 1
  } else if (a < b) {
    return -1
  } else if (a2 > b2) {
    return 1
  } else {
    return -1
  }
}
