export const getNiveauClass = function (niveau) {
  return 'N' + (niveau && niveau !== undefined ? niveau.replace('<', 'L').replace('<', 'L').replace('>', 'H') : '')
}
export const getNiveauLabel = function (niveau) {
  switch (niveau) {
    case '3FM7':
      return { niveau: '3F', periode: '(M7)', volledig: '3F(M7)' }
    case '3FB8':
      return { niveau: '3F', periode: '(B8)', volledig: '3F(B8)' }
    default:
      return { niveau, periode: '', volledig: niveau }
  }
}
