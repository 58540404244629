
import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [{
  path: '/leerkrachten',
  name: 'leerkrachten-overzicht',
  component: () => import('views/Schoolbeheer/Toegang/LeerkrachtenOverzicht.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.BeheerToegang) }
},
{
  path: '/leerkrachten/details/:id',
  name: 'leerkrachten-details',
  props: true,
  component: () => import('views/Schoolbeheer/Toegang/LeerkrachtenDetails.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.BeheerToegang) }
},
{
  path: '/leerkrachten/toevoegen',
  name: 'leerkrachten-toevoegen',
  component: () => import('views/Schoolbeheer/Toegang/LeerkrachtenToevoegen.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.BeheerToegang) }
},
{
  path: '/leerkrachten/wijzigen/:id',
  name: 'leerkrachten-wijzigen',
  props: true,
  component: () => import('views/Schoolbeheer/Toegang/LeerkrachtenWijzigen.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.BeheerToegang) }
}]
