import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  registreerSchool: async data =>
    httpClient.call('/scholen', { method: 'post', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} school`, true),
  getScholen: async (search, orderBy, start, limit) =>
    httpClient.call('/scholen', { method: 'get', parameters: { search, orderBy, start, limit } }, true),
  getSchool: async id =>
    httpClient.call(`/scholen/${id}`, { method: 'get' }, true),
  verwijderSchool: async id =>
    httpClient.call(`/scholen/${id}`, { method: 'delete' }, true, `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} school`),
  wijzigBestuur: async (schoolId, data) => {
    const headers = { 'Content-Type': 'application/json' }
    return httpClient.call(`/scholen/${schoolId}/bestuur`, { method: 'put', data, headers }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_HET} bestuur`, true)
  },
  verwijderBestuur: async (schoolId) =>
    httpClient.call(`/scholen/${schoolId}/bestuur`, { method: 'delete' }, true, `${errors.FOUT_OPGETREDEN_VERWIJDEREN_HET} bestuur`),
  wijzigContactpersoon: async (schoolId, data) =>
    httpClient.call(`/scholen/${schoolId}/contactpersoon`, { method: 'put', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} contactpersoon`),
  registreerContactpersoon: async (schoolId, data) =>
    httpClient.call(`/scholen/${schoolId}/contactpersoon`, { method: 'post', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} contactpersoon`),
  wijzigSchool: async (schoolId, data) =>
    httpClient.call(`/scholen/${schoolId}`, { method: 'patch', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} school`),
  wijzigAbonnement: async (schoolId, data) =>
    httpClient.call(`/scholen/${schoolId}/abonnement`, { method: 'post', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_HET} abonnement`, true),
  overstappenAbonnement: async (schoolId, data) => {
    return httpClient.putJson(`/scholen/${schoolId}/abonnement/type`, { data, toonErrorNotify: true, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_HET} abonnement`, toonServerResponse: true })
  },
  beindigAbonnement: async (schoolId) =>
    httpClient.call(`/scholen/${schoolId}/abonnement`, { method: 'delete' }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_HET} abonnment`, true),
  stopAbonnement: async (schoolId, data) =>
    httpClient.putJson(`/scholen/${schoolId}/abonnement/status`, { data, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_HET} abonnment`, toonServerResponse: true }),
  exporteerScholen: async () =>
    httpClient.download('/scholen/bestand'),
  getCookieSettings: async (schoolId) =>
    httpClient.get(`scholen/${schoolId}/2fa/cookie`),
  wijzig2faCookieLifetime: async (schoolId, dagen) =>
    httpClient.patch(`/scholen/${schoolId}/2fa/cookie/${dagen}`)
}
