
import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [{
  path: '/leerling',
  name: 'leerling-overzicht',
  component: () => import('views/Schoolbeheer/Leerling/LeerlingOverzicht.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.BeheerLeerling) }
}]
