export const features = {
  BeheerImporteren: 'BEHEER.IMPORTEREN',
  BeheerMijnGegevens: 'BEHEER.MIJN_GEGEVENS',
  BeheerInstellingen: 'BEHEER.INSTELLINGEN',
  BeheerToegang: 'BEHEER.TOEGANG',
  BeheerLeerling: 'BEHEER.LEERLING',
  BeheerRapporten: 'BEHEER.RAPPORTEN',
  Beheer: 'BEHEER',
  BeheerBestuur: 'BEHEER.BESTUUR',
  BeheerImport: 'BEHEER.IMPORTEREN',
  Opbrengsten: 'OPBRENGSTEN',
  Systeembeheer: 'SYSTEEMBEHEER',
  Leerdoelen: 'LEERDOELEN',
  Leerdoelen12: 'LEERDOELEN.GROEPEN12',
  Leerdoelen38: 'LEERDOELEN.GROEPEN38',
  SysteembeheerBesturen: 'SYSTEEMBEHEER.BESTUREN',
  SysteembeheerToegang: 'SYSTEEMBEHEER.TOEGANG',
  SysteembeheerHoofdvestigingen: 'SYSTEEMBEHEER.HOOFDVESTIGINGEN',
  SysteembeheerScholen: 'SYSTEEMBEHEER.SCHOLEN',
  SysteembeheerSchoolwegingen: 'SYSTEEMBEHEER.SCHOOLWEGINGEN',
  SysteembeheerInstellingen: 'SYSTEEMBEHEER.INSTELLINGEN',
  SysteembeheerToetsdata: 'SYSTEEMBEHEER.TOETSDATA',
  SysteembeheerLeerdoelen: 'SYSTEEMBEHEER.LEERDOELEN',
  SysteembeheerContent: 'SYSTEEMBEHEER.CONTENT',
  Rapporten: 'RAPPORTEN'
}
