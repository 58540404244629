import App from './App.vue'
import { createApp } from 'vue'
import { Quasar } from 'quasar'
import { createPinia } from 'pinia'
import axios from 'axios'
import SelectionArea from '@viselect/vanilla'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueClickAway from 'vue3-click-away'
import moment from 'moment'
import 'moment/min/locales'

import 'quasar/dist/quasar.sass'

import defaultLayout from './layouts/DefaultLayout'
import schoolbeheerLayout from './layouts/SchoolbeheerLayout'
import systeembeheerLayout from './layouts/SysteembeheerLayout'
import accountLayout from './layouts/AccountLayout'
import groep38Layout from './layouts/Groep38Layout'
import groep12Layout from './layouts/Groep12Layout'
import schoolLayout from './layouts/SchoolLayout'
import metaLayout from './layouts/MetaLayout'
import leerlingLayout from './layouts/LeerlingLayout'
import instellingenLayout from './layouts/InstellingenLayout'

import spinnerCircle from '@/components/SpinnerCircle'
import navHeader from '@/components/Navigatie/NavHeader'
import beheerderMenu from '@/components/Navigatie/BeheerderMenu'
import leerkrachtMenu from '@/components/Navigatie/LeerkrachtMenu'
import metaMenu from '@/components/Navigatie/MetaMenu'
import demoMenu from '@/components/Navigatie/DemoMenu'
import schoolbeheerSubmenu from '@/components/Navigatie/SchoolbeheerSubmenu'
import groepSubmenu from '@/components/Navigatie/GroepSubmenu'
import groep12Submenu from '@/components/Navigatie/Groep12Submenu'
import schoolSubmenu from '@/components/Navigatie/SchoolSubmenu'
import leerlingSubmenu from '@/components/Navigatie/LeerlingSubmenu'
import metaSubmenu from '@/components/Navigatie/MetaSubmenu'
import demoSubmenu from '@/components/Navigatie/DemoSubmenu'
import videoComponent from '@/components/Content/VideoComponent'
import downloadLink from '@/components/Content/DownloadLink'
import niveauLabel from '@/components/Common/NiveauLabel'

import { useCacheStore } from '@/stores/cache'
import { useAccountStore } from '@/stores/account'

import interceptorsSetup from './helpers/axiosInterceptors'
import { sortAsc, sortDesc, sortBothAsc, sortBothDesc } from './helpers/simpleSort'
import { clamp } from './helpers/clamp'
import { betterFixed } from './helpers/betterFixed'

import './tailwind.css'
import './assets/css/style.scss'


import Focus from './api/focus'
import { features } from './constants/features'
import router from './router'
import quasarUserOptions from './quasar-user-options'


import { FotoService } from 'services/fotoservice';
import { TemplateService } from 'services/templateservice';
import { RapportService } from 'services/rapportservice';
import { BestuurService } from './services/bestuurservice'
import { LeerlijnService } from 'services/leerlijnservice';

moment.locale('nl')

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)
  .use(pinia)
  .use(VueClickAway)
  .use(Quasar, quasarUserOptions)
  .use(router)
  .use(moment)

app.component(Quasar, quasarUserOptions)
app.component('default-layout', defaultLayout)
app.component('schoolbeheer-layout', schoolbeheerLayout)
app.component('systeembeheer-layout', systeembeheerLayout)
app.component('account-layout', accountLayout)
app.component('groep38-layout', groep38Layout)
app.component('groep12-layout', groep12Layout)
app.component('school-layout', schoolLayout)
app.component('meta-layout', metaLayout)
app.component('leerling-layout', leerlingLayout)
app.component('instellingen-layout', instellingenLayout)
app.component('nav-header', navHeader)
app.component('leerkracht-menu', leerkrachtMenu)
app.component('meta-menu', metaMenu)
app.component('demo-menu', demoMenu)
app.component('beheerder-menu', beheerderMenu)
app.component('spinner-circle', spinnerCircle)
app.component('schoolbeheer-submenu', schoolbeheerSubmenu)
app.component('groep-submenu', groepSubmenu)
app.component('groep12-submenu', groep12Submenu)
app.component('school-submenu', schoolSubmenu)
app.component('leerling-submenu', leerlingSubmenu)
app.component('meta-submenu', metaSubmenu)
app.component('demo-submenu', demoSubmenu)
app.component('video-component', videoComponent)
app.component('download-link', downloadLink)
app.component('niveau-label', niveauLabel)

// voor oude options API components en views
app.config.globalProperties.$gt = {}
app.config.globalProperties.$gt.http = axios
app.config.globalProperties.$gt.stores = {}
app.config.globalProperties.$gt.api = new Focus({ config: { basePath: import.meta.env.VITE_API_ENDPOINT } })
app.config.globalProperties.$gt.features = features
app.config.globalProperties.$gt.SelectionArea = SelectionArea
app.config.globalProperties.$gt.TfaEnabled = parseInt(import.meta.env.VITE_2FA_ENABLED)
app.config.globalProperties.$gt.TfaVerplicht = parseInt(import.meta.env.VITE_2FA_VERPLICHT)

app.config.globalProperties.moment = moment
app.config.globalProperties.sortAsc = sortAsc
app.config.globalProperties.sortBothAsc = sortBothAsc
app.config.globalProperties.sortDesc = sortDesc
app.config.globalProperties.sortBothDesc = sortBothDesc
app.config.globalProperties.clamp = clamp
app.config.globalProperties.betterFixed = betterFixed

interceptorsSetup(app.config.globalProperties.$gt.api)
// voor de composition API components en views
app.provide('http', axios)
app.provide('stores', {})
app.provide('api', new Focus({ config: { basePath: import.meta.env.VITE_API_ENDPOINT } }))
app.provide('features', features)
app.provide('SelectionArea', SelectionArea)
app.provide('TfaEnabled', parseInt(import.meta.env.VITE_2FA_ENABLED))
app.provide('TfaVerplicht', parseInt(import.meta.env.VITE_2FA_VERPLICHT))
app.provide('moment', moment)
app.provide('sortAsc', sortAsc)
app.provide('sortDesc', sortDesc)
app.provide('sortBothAsc', sortBothAsc)
app.provide('sortBothDesc', sortBothDesc)
app.provide('clamp', clamp)

// Services
app.provide('fotoService', new FotoService())
app.provide('templateService', new TemplateService())
app.provide('rapportService', new RapportService())
app.provide('leerlijnService', new LeerlijnService())
app.provide('bestuurService', new BestuurService())

axios.defaults.baseURL = import.meta.env.VITE_API_ENDPOINT

app.config.productionTip = false
// Als de gebruiker nog is ingelogd de token toevoegen aan de header
if (useAccountStore().isAuthenticated) {
  const token = useAccountStore.token
  axios.defaults.headers.common.authorization = `Bearer ${token}`
}

useCacheStore().removeExpiredItems()
app.mount('#app')
