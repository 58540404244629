import { defineStore } from 'pinia'

// Store voor het opslaan van selecties binnen het groep 3-8 "domein". Omdat het meegeven van parameters en props niet altijd handig is, staan deze in de store.
export const useParamsGroep38Store = defineStore('ParamsGroep38', {
  state: () => {
    return {
      groepId: null,
      groepsNaam: null,
      leerjaar: null,
      vak: null,
      niveau: null,
      vindplaatsen: { R: null, T: null, L: null, TL: null }
    }
  },
  actions: {
    reset () {
      this.groepId = null
      this.groepsNaam = null
      this.leerjaar = null
      this.vak = null
      this.niveau = null
      this.vindplaatsen = { R: null, T: null, L: null, TL: null }
    }
  },
  persist: true
})
