<template>
  <article class="page-container meta-layout">
    <header>
      <div class="blue-gray-gradient">
        <slot name="nav-slot">
          <beheerder-menu></beheerder-menu>
        </slot>
      </div>
      <div v-if="hasHeaderSlot" class="header-container">
        <slot name="header-slot"></slot>
      </div>
    </header>

    <transition name="fade" mode="out-in">
      <div class="slot-container">
          <slot name="body-slot" ></slot>
      </div>
    </transition>

    <footer v-if="hasFooterSlot">
        <slot name="footer-slot" />
        <!--<div class="footer-copyright-wrapper">
          <span class="footer-copyright-text"> ©2020 Focus Onderwijs BV | <a href="http://www.focuspo.nl" target="_blank">Focus PO website</a> | <a href="http://www.getthere.nl" target="_blank">Powered by Get There</a></span>
        </div>-->
    </footer>
  </article>
</template>

<script>
export default {
  props: {
    footerClass: String
  },
  async created () {
    if (!this.$gt.stores.account.isAuthenticated) {
      this.$router.push('/')
    }
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header-slot']
    },
    hasFooterSlot () {
      return !!this.$slots['footer-slot']
    }
  }
}
</script>

<style scoped>
  header {
    background: #8292A4;
  }
  footer {
    height: unset;
  }
  h1 {
    color: white;
  }
</style>
