import { defineStore } from 'pinia'

export const useDownloadsStore = defineStore('Downloads', {
  state: () => {
    return {
      nieuweDownloads: false
    }
  },
  actions: {
    // dit wordt gebruikt om aan te geven dat er nieuwe downloads zijn, aangegeven door een rood bolletje bij "mijn downloads" in de header
    setNieuweDownloads (heeftNieuweDownloads) {
      this.nieuweDownloads = heeftNieuweDownloads
    },
    reset () {
      this.nieuweDownloads = false
    }
  },
  persist: true
})
