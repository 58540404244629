import accountRoutes from './Account/index.js'
import adminRoutes from './Admin/index.js'
import groep12Routes from './Groep12/index.js'
import groep38Routes from './Groep38/index.js'
import homeRoutes from './Home/index.js'
import leerlingRoutes from './Leerling/index.js'
import schoolRoutes from './School/index.js'
import schoolbeheerRoutes from './Schoolbeheer/index.js'
import metaRoutes from './Meta/index.js'
import ssoRoutes from './Sso/index.js'

const routes = [
  accountRoutes,
  adminRoutes,
  groep12Routes,
  groep38Routes,
  homeRoutes,
  leerlingRoutes,
  schoolRoutes,
  schoolbeheerRoutes,
  metaRoutes,
  ssoRoutes,
  [{
    path: '/:pathMatch(.*)*',
    component: () => import('../views/Error/404Pagina.vue')
  }]
].flat()

export default routes
