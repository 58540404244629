import HttpClient from '@/api/HttpClient';
import Image from 'image-js';
import { Buffer } from 'buffer';
import * as _ from 'lodash';

export class FotoService {
    async getData(url: string): Promise<any> {
        return new Promise((resolve) => {
            return HttpClient.get(url).then((data) => {
                resolve(data.result);
            });
        });
    }

    async getBlobFromInput(input: File) {
        if (_.isEmpty(input)) return new Promise(() => {});

        let resizedImg = await this.resizeImage(input);
        const resizedBlob = this.dataURIToBlob(resizedImg);

        return resizedBlob;
    }

    getImageMimeType(imageB64: any) {
        const signatures = new Map();
        let foundType = 'image/svg+xml';

        signatures.set('JVBERi0', 'application/pdf');
        signatures.set('R0lGODdh', 'image/gif');
        signatures.set('R0lGODlh', 'image/gif');
        signatures.set('R0lGODlh', 'image/gif');
        signatures.set('iVBORw0KGgo', 'image/png');
        signatures.set('/9j/', '');

        signatures.forEach((mimeType, signature) => {
            if (imageB64.indexOf(signature) !== -1) {
                foundType = mimeType;
            }
        });

        return foundType;
    }

    async resizeImage(img: File, width = 640): Promise<string> {
        const fileReader = new FileReader();

        return new Promise<string>((resolve, reject) => {
            fileReader.readAsDataURL(img);
            fileReader.onload = async () => {
                try {
                    if (fileReader.result) {
                        const base64String = (fileReader.result as string).split(',')[1];
                        const image = await Image.load(Buffer.from(base64String, 'base64'));

                        let dataURL = image.toDataURL();
                        if (width > 0) {
                            const resized = image.resize({ width: width });
                            dataURL = resized.toDataURL();
                        }

                        resolve(dataURL);
                    } else {
                        reject(new Error('File reading failed'));
                    }
                } catch (error) {
                    reject(error);
                }
            };

            fileReader.onerror = () => {
                reject(new Error('File reading error'));
            };
        });
    }

    dataURIToBlob(dataURI: string): Blob {
        const splitDataURI = dataURI.split(',');
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }
}
