<template>
  <article class="page-container light-layout">
    <header>
      <div class="blue-gray-gradient">
        <slot name="nav-slot">
          <beheerder-menu :actieveLink="actieveLink"></beheerder-menu>
        </slot>
      </div>
      <div v-if="hasHeaderSlot" class="header-container">
        <slot name="header-slot"></slot>
      </div>
    </header>

    <transition name="fade" mode="out-in">
      <div v-if="hasBodySlot" class="slot-container">
          <slot name="body-slot" ></slot>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div v-if="hasFullWidthSlot" class="full-width-container">
          <slot name="full-width-slot" ></slot>
      </div>
    </transition>

    <footer v-if="hasFooterSlot">
      <slot name="footer-slot" />
      <!--<div class="footer-copyright-wrapper">
         <span class="footer-copyright-text"> ©2020 Focus Onderwijs BV | <a href="http://www.focuspo.nl" target="_blank">Focus PO website</a> | <a href="http://www.getthere.nl" target="_blank">Powered by Get There</a></span>
      </div>-->
    </footer>
  </article>
</template>

<script>

export default {
  props: {
    actieveLink: String
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header-slot']
    },
    hasBodySlot () {
      return !!this.$slots['body-slot']
    },
    hasFullWidthSlot () {
      return !!this.$slots['full-width-slot']
    },
    hasFooterSlot () {
      return !!this.$slots['footer-slot']
    }
  }
}
</script>

<style scoped>
  header {
    background: rgb(240,244,255);
    background: linear-gradient(90deg, rgba(240,244,255,1) 0%, rgba(240,244,255,1) 50%, rgba(240,244,255,1) 100%);
  }
  footer {
    height: 150px;
    background: linear-gradient(90deg, rgba(240,244,255,1) 0%, rgba(240,244,255,1) 50%, rgba(240,244,255,1) 100%);
  }
  h2 {
    color: black;
  }
  @media screen and (max-width: 1280px) {
      .full-width-container {
        justify-content: unset;
      }
  }

</style>
