<template>
  <div class="sub-menu">
    <router-link :to="{ name:'home' }">home</router-link>
    <span> ></span>
    <span>Demo</span>
  </div>
</template>

<script>
export default {
  name: 'demo-submenu',
  props: {
    activeLink: String,
    parent: String
  }
}
</script>
