<template>
  <router-view />
  <a v-if="isIngelogd" class="support-btn" :href="ZendeskUrl" target="_blank" rel="noopener"></a>
</template>

<script>
import { useAccountStore } from '@/stores/account'
import { useScholenStore } from '@/stores/scholen'
import { useDownloadsStore } from '@/stores/downloads'
import { useCacheStore } from '@/stores/cache'
import { useBesturenStore } from '@/stores/besturen'
import { inject } from 'vue'

export default {
  computed: {
    isIngelogd () {
      return useAccountStore()?.isAuthenticated
    },
    ZendeskUrl () {
      return import.meta.env.VITE_ZENDESK_HC_URL
    }
  },
  async created () {
    const html = document.documentElement
    html.setAttribute('lang', 'nl')

    const stores = inject('stores')
    // voor oude options API components en views
    this.$gt.stores.account = useAccountStore()
    this.$gt.stores.scholen = useScholenStore()
    this.$gt.stores.besturen = useBesturenStore()
    this.$gt.stores.downloads = useDownloadsStore()

    // voor de composition API components en views
    stores.account = useAccountStore()
    stores.scholen = useScholenStore()
    stores.besturen = useBesturenStore()
    stores.downloads = useDownloadsStore()

    const accountStore = useAccountStore()
    // Cache legen bij een refresh
    useCacheStore().reset()
    useScholenStore().resetSearch()
    useBesturenStore().resetSearch()
    // Als de gebruiker nog is ingelogd voor de zekerheid token refreshen
    if (accountStore.isAuthenticated) {
      await accountStore.refreshToken(true)
      // ingelogde gebruiker info ophalen
      if (!accountStore.ingelogdeGebruiker) {
        await accountStore.getGebruiker(this.$gt.api.account)
      }

      // Als er geen schoolgegevens zijn geladen maar de gebruiker wel gekoppeld is aan een school, de info daarvan ophalen
      const ingelogdeGebruiker = accountStore.ingelogdeGebruiker
      if ((!this.$gt.stores.besturen?.scholenSelectie?.length) && ingelogdeGebruiker.rollen.includes('BESTUURDER') && ingelogdeGebruiker.scholen) {
        await this.$gt.stores.besturen.getBestuur(this.$gt.api.besturen, ingelogdeGebruiker.bestuurId)
        await this.$gt.stores.besturen.setSchoolLijst(this.$gt.api.besturen, ingelogdeGebruiker.bestuurId)
      } else if (!this.$gt.stores.scholen.getHuidigeSchool && (ingelogdeGebruiker.rollen.includes('LEERKRACHT') || ingelogdeGebruiker.rollen.includes('BEHEERDER')) && ingelogdeGebruiker.scholen?.[0]) {
        await this.$gt.stores.scholen.setSchool(this.$gt.api.scholen, ingelogdeGebruiker.scholen[0])
      } else {
        // Geen actie nodig als school(lijst) al gevuld is of gebruiker andere rol heeft
      }
    }
  },
  async mounted () {
  }
}
</script>
