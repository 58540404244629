import httpClient from '../HttpClient'

export default {
  login: async data =>
    httpClient.post('/account/token', { data, toonErrorNotify: false, errorText: 'Ongeldige gebruikersnaam of wachtwoord', withCredentials: true }),
  loginVerificationCode: async data =>
    httpClient.call('/account/token/verificationcode', { method: 'post', data }),
  loginRecoveryCode: async data =>
    httpClient.call('/account/token/recoverycode', { method: 'post', data }),
  refreshToken: async () =>
    httpClient.call('/account/refreshtoken', { method: 'post' }),
  resetPassword: async data =>
    httpClient.call('/account/password', { method: 'post', data }, true, 'De activatiecode is ongeldig.', true),
  getGebruiker: async () =>
    httpClient.call('/account', { method: 'get' }),
  forgotPassword: async data => {
    const headers = { 'Content-Type': 'application/json' }
    return httpClient.call('/account/password/forgotten', { method: 'post', data, headers }, true, 'Er is een fout opgetreden. Controleer of het e-mailadres juist is.')
  },
  getAuthenticatorKey: async () =>
    httpClient.call('/account/2fa/authenticatorkey', { method: 'get' }),
  enableTwoFactorAuthentication: async data =>
    httpClient.post('/account/2fa/enable', { data }),
  disableTwoFactorAuthentication: async data =>
    httpClient.post('/account/2fa/disable', { data }),
  verwijderTwoFactorAuthenticationBestuur: async (bestuurId, gebruikerId) =>
    httpClient.delete(`/account/2fa/bestuur/${bestuurId}/gebruiker/${gebruikerId}/disable`),
  verwijderTwoFactorAuthenticationSchoolbeheer: async (schoolId, gebruikerId) =>
    httpClient.delete(`/account/2fa/school/${schoolId}/gebruiker/${gebruikerId}/disable`),
  verwijderTwoFactorAuthenticationSysteembeheer: async (gebruikerId) =>
    httpClient.delete(`/account/2fa/gebruiker/${gebruikerId}/disable`),
  getTwoFactorAuthenticationState: async () =>
    httpClient.call('/account/2fa/state', { method: 'get' })
}
