import axios from 'axios'
import router from '../router'
import { useAccountStore } from '@/stores/account'

export default function setup (api) {
  axios.interceptors.response.use((response) => {
    useAccountStore().refreshToken()
    return response
  }, (error) => {
    if (error?.response?.status === 401 && !TokenIsValid()) {
      useAccountStore().logout()
      router.push('/login')
    }
    return Promise.reject(error)
  })
}
// met de getter 'isAuthenticated' in /stores/account krijg ik hier steeds een cached (?) antwoord die dus mogelijk niet klopt.
// Daarom de hele check hier nogmaals via de states in de account store omdat die wel werken
function TokenIsValid () {
  const accStore = useAccountStore()
  const epochNow = new Date().getTime()
  return !!accStore.token && accStore.tokenExpiration > epochNow && import.meta.env.VITE_AUTH_VERSION === accStore.authVersion
}
