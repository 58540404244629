<template>
  <div class="q-py-md">
    <h2 v-if="titel">{{titel}}</h2>
    <q-video
      :ratio="16/9"
      :src="url"
    />
  </div>
</template>

<script>
export default {
  name: 'focus-video',
  props: {
    url: [Object, String],
    titel: String
  }
}
</script>
