import httpClient from '../HttpClient'

export default {
  getReferentieniveaus: async () =>
    httpClient.getCached('/referentieniveaus', { minutesToCache: 60 }),
  getReferentieniveausVak: async (vak) =>
    httpClient.getCached(`/referentieniveaus/${vak}`, { minutesToCache: 60 }),
  getReferentieniveausVakNiveau: async (vak, niveau) =>
    httpClient.getCached(`/referentieniveaus/${vak}/${niveau}`, { minutesToCache: 60 }),
  getIjkpunten: async (schoolweging) =>
    httpClient.getCached('/referentieniveaus/ijkpunten', { minutesToCache: 60, parameters: { schoolweging } }),
  getIjkpuntenVak: async (vak, schoolweging) =>
    httpClient.getCached(`/referentieniveaus/ijkpunten/${vak}`, { minutesToCache: 60, parameters: { schoolweging } })
}
