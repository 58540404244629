import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [
  {
    path: '/monitor',
    name: 'monitor-index',
    component: () => import('views/Schoolbeheer/Monitor/MonitorIndex.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen) }
  }
]
