<template>
  <div class="sub-menu">
    <router-link :to="{ name:'welkom' }">home</router-link>
    <span> ></span>
    <span>school</span>
    <span> ></span>
    <span v-if="activeLink === 'opbrengsten'">Toetsresultaten (VIX)</span>
    <span v-if="activeLink === 'leerdoelbeheersing'">Leerdoelbeheersing</span>
    <span v-if="activeLink === 'schoolaanbod'">Schoolaanbod</span>
    <span v-if="activeLink === 'referentieniveaus'">beheersing referentieniveaus</span>
  </div>
</template>

<script>
export default {
  name: 'school-submenu',
  props: {
    activeLink: String
  }
}
</script>
