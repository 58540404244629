// Alles naar OnderhoudsView verwijzen
const routes = [{
  path: '/',
  name: 'onderhoud',
  component: () => import('views/Account/OnderhoudsView.vue')
},
{
  path: '/:pathMatch(.*)*',
  redirect: { name: 'onderhoud' }
}]

export default routes
