import httpClient from '../HttpClient'
import { useScholenStore } from '@/stores/scholen'

export default {
  getWerkplanExport: async (schoolId, groepId, blokplanId, data) => {
    return httpClient.post(`/export/werkplan/groepen/${schoolId}/${groepId}/${blokplanId}`, { data })
  },
  getWerkplanExportLeerjaar: async (schoolId, leerjaar, blokplanId, data) => {
    return httpClient.post(`/export/werkplan/leerjaren/${schoolId}/${leerjaar}/${blokplanId}`, { data })
  },
  getBolletjesExport: async (schoolId, groepId, blokplanId, data) => {
    return httpClient.post(`/export/bolletjes/groepen/${schoolId}/${groepId}/${blokplanId}`, { data })
  },
  getBolletjesExportLeerjaar: async (schoolId, leerjaar, blokplanId, data) => {
    return httpClient.post(`/export/bolletjes/leerjaren/${schoolId}/${leerjaar}/${blokplanId}`, { data })
  },
  getIkDoelenExport: async (schoolId, blokplanId, data) => {
    return httpClient.post(`/export/ikdoelen/${schoolId}/${blokplanId}`, { data })
  },
  getVindplaatsenExport: async (schoolId, groepId, blokplanId, data) => {
    return httpClient.post(`/export/vindplaats/groepen/${schoolId}/${groepId}/${blokplanId}`, { data })
  },
  getVindplaatsenExportLeerjaar: async (schoolId, leerjaar, blokplanId, data) => {
    return httpClient.post(`/export/vindplaats/leerjaren/${schoolId}/${leerjaar}/${blokplanId}`, { data })
  },
  getJaarplanExport: async (schoolId, groepId, blokplanId, data) => {
    return httpClient.post(`/export/groepen/${schoolId}/${groepId}/${blokplanId}`, { data })
  },
  getJaarplanExportLeerjaar: async (schoolId, leerjaar, vak, data) => {
    return httpClient.post(`/export/leerjaren/${schoolId}/${leerjaar}/${vak}`, { data })
  },
  getThemaplanAlgemeenExport: async (themaplanId) => {
    return httpClient.download(`/export/themaplan/algemeen/${await useScholenStore().getHuidigeSchoolId}/${themaplanId}`)
  },
  getThemaplanSpeelwerkomgevingExport: async (themaplanId) => {
    return httpClient.download(`/export/themaplan/speelwerkomgeving/${await useScholenStore().getHuidigeSchoolId}/${themaplanId}`)
  },
  getThemaplanInstructieenbegeleidingExport: async (themaplanId) => {
    return httpClient.download(`/export/themaplan/instructieenbegeleiding/${await useScholenStore().getHuidigeSchoolId}/${themaplanId}`)
  },
  getThemaplanZonegroepenExport: async (themaplanId, parameters) => {
    return httpClient.download(`/export/themaplan/zonegroepen/${await useScholenStore().getHuidigeSchoolId}/${themaplanId}`, { parameters })
  },
  getLeerlingenDoelenExport: async (themaplanId) => {
    return httpClient.download(`/export/themaplan/leerlingendoelen/${await useScholenStore().getHuidigeSchoolId}/${themaplanId}`)
  },
  getDoelenLeerlingenExport: async (themaplanId) => {
    return httpClient.download(`/export/themaplan/doelenleerlingen/${await useScholenStore().getHuidigeSchoolId}/${themaplanId}`)
  },
  getThemaplanWoordenlijstExport: async (themaplanId) => {
    return httpClient.download(`/export/themaplan/woordenlijst/${await useScholenStore().getHuidigeSchoolId}/${themaplanId}`)
  },
  getThemaplanEvaluatieExport: async (themaplanId) => {
    return httpClient.download(`/export/themaplan/evaluatie/${await useScholenStore().getHuidigeSchoolId}/${themaplanId}`)
  },
  getKleuterRapport: async (leerlingId, rapportId) => {
    return httpClient.download(`/export/scholen/${await useScholenStore().getHuidigeSchoolId}/groepen12/leerling/${leerlingId}/rapport/${rapportId}`)
  },
  getLeerlingRapport: async (leerlingId, rapportId) => {
    return httpClient.download(`/export/scholen/${await useScholenStore().getHuidigeSchoolId}/groepen/leerling/${leerlingId}/rapport/${rapportId}`)
  },
  getDoelenInOntwikkelingLeerlingExport: async (leerlingId, vak) => {
    return httpClient.download(`/export/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/doeleninontwikkeling/${vak}/export`)
  },
  getDoelenInOntwikkelingSubdomeinExport: async (domeinId, groep) => {
    return httpClient.download(`/export/scholen/${await useScholenStore().getHuidigeSchoolId}/groepen/${groep}/doeleninontwikkeling/${domeinId}/export`)
  },
  getGroepsoverzichtExport: async (vak, data) => {
    return httpClient.post(`/export/scholen/${await useScholenStore().getHuidigeSchoolId}/groepsoverzicht/${vak}/export`, { data })
  },
  getThemaExport: async (themaId, parameters) => {
    return httpClient.download(`/export/thema/${await useScholenStore().getHuidigeSchoolId}/${themaId}`, { parameters })
  },
  getRegulierAanbodExport: async (data) => {
    return httpClient.post(`/export/regulieraanbod/${await useScholenStore().getHuidigeSchoolId}`, { data })
  },
  getRapportExport: async (leerlingId, rapportId) => {
    return httpClient.download(`/export/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/${rapportId}/leerlingen/${leerlingId}`)
  },
  getRapportenExport: async (groep, rapportIds) => {
    return httpClient.download(`/export/scholen/${await useScholenStore().getHuidigeSchoolId}/rapporten/${groep}/zip?rapportIds=${rapportIds}`)
  },
  getLeerlingoverzichtExport: async (data) => {
    return httpClient.post(`/export/scholen/${await useScholenStore().getHuidigeSchoolId}/overzicht12`, { data })
  },
  getReferentieniveauExport: async (bestuurId, data) => {
    return httpClient.post(`/export/bestuur/${bestuurId}/referentieniveaus`, { data })
  },
  getSchoolplanExport: async (schoolId, schoolplanId) => {
    return httpClient.post(`/export/scholen/${schoolId}/schoolplannen/${schoolplanId}`)
  }
}
