import { defineStore } from 'pinia'

// 'oude' store die nog steeds wordt gebruikt voor het opslaan van selecties bij Focus systeembeheerders op de scholen pagina
export const useScholenStore = defineStore('Scholen', {
  state: () => {
    return {
      bestuurnummerStatus: '',
      currentPage: 1,
      currentOrderBy: '',
      currentSearch: '',
      huidigeSchool: null,
      afsluitVelden: [],
      demoSchool: null,
      huidigeSchoolId: null
    }
  },
  getters: {
    getHuidigeSchool: (state) => state.demoSchool ?? state.huidigeSchool,
    getHuidigeSchoolId: (state) => (state.demoSchool ?? state.huidigeSchool)?.id
  },
  actions: {
    setCurrentPage (page) {
      this.currentPage = page
    },
    setCurrentOrderBy (orderBy) {
      this.currentOrderBy = orderBy
    },
    setCurrentSearch (search) {
      this.currentSearch = search
    },
    setAfsluitVelden(velden) {
      this.afsluitVelden = velden;
    },
    setSchool (provider, schoolId) {
      return new Promise((resolve, reject) => {
        provider.getSchool(schoolId)
          .then(response => {
            this.huidigeSchool = response.result
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getDemoSchool (provider, schoolId) {
      return new Promise((resolve, reject) => {
        provider.getSchool(schoolId)
          .then(response => {
            this.demoSchool = response.result
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    setDemoSchool (demoSchool) {
      return new Promise((resolve, reject) => {
        this.demoSchool = demoSchool
        resolve()
      })
    },
    reset () {
      this.bestuurnummerStatus = ''
      this.currentPage = 1
      this.currentOrderBy = ''
      this.currentSearch = ''
      this.afsluitVelden = []
      this.huidigeSchool = null
      this.demoSchool = null
      this.huidigeSchoolId = null
    },
    resetSearch () {
      this.currentPage = 1
      this.currentOrderBy = ''
      this.currentSearch = ''
    },
    resetDemoSchool () {
      this.demoSchool = null
    },
    resetBestuurnummerStatus () {
      this.bestuurnummerStatus = ''
    },
    setSchoolId (schoolId) {
      this.huidigeSchoolId = schoolId
    }
  },
  persist: true
})
