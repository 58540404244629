import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [
  {
    path: '/parnassys',
    name: 'parnassys',
    component: () => import('views/Admin/Scholen/ScholenParnassysOverzicht.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/parnassys/verwijderen/:id',
    name: 'parnassys-verwijderen',
    props: true,
    component: () => import('views/Admin/Scholen/SchoolParnassysVerwijderen.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/parnassys/toevoegen',
    name: 'parnassys-toevoegen',
    component: () => import('views/Admin/Scholen/SchoolParnassysToevoegen.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/scholen',
    name: 'scholen',
    component: () => import('views/Admin/Scholen/ScholenOverzicht.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/scholen/details/:id',
    name: 'scholen-details',
    props: true,
    component: () => import('views/Admin/Scholen/SchoolDetails.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerScholen) }
  },
  {
    path: '/scholen/verwijderen/:id',
    name: 'scholen-verwijderen',
    props: true,
    component: () => import('views/Admin/Scholen/SchoolVerwijderen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerScholen) }
  },
  {
    path: '/school/wijzigen/:id',
    name: 'scholen-wijzigen',
    props: true,
    component: () => import('views/Admin/Scholen/SchoolWijzigen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerScholen) }
  },
  {
    path: '/school/toevoegen',
    name: 'school-toevoegen',
    component: () => import('views/Admin/Scholen/SchoolToevoegen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerScholen) }
  },
  {
    path: '/besturen',
    name: 'besturen',
    component: () => import('views/Admin/Besturen/BesturenOverzicht.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerBesturen) }
  },
  {
    path: '/gebruikers/besturen',
    name: 'gebruikers-besturen',
    component: () => import('views/Admin/Besturen/GebruikersOverzicht.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerBesturen) }
  },
  {
    path: '/besturen/importeren',
    name: 'besturen-importeren',
    component: () => import('views/Admin/Besturen/BesturenImporteren.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerBesturen) }
  },
  {
    path: '/besturen/details/:id/:vorigePagina?',
    name: 'besturen-details',
    props: true,
    component: () => import('views/Admin/Besturen/BestuurDetails.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerBesturen) }
  },
  {
    path: '/besturen/wijzigen/:id/:vorigePagina?',
    name: 'besturen-wijzigen',
    props: true,
    component: () => import('views/Admin/Besturen/BestuurWijzigen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerBesturen) }
  },
  {
    path: '/besturen/toevoegen',
    name: 'besturen-toevoegen',
    component: () => import('views/Admin/Besturen/BestuurToevoegen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerBesturen) }
  },
  {
    path: '/besturen/verwijderen/:id/:vorigePagina?',
    name: 'besturen-verwijderen',
    props: true,
    component: () => import('views/Admin/Besturen/BestuurVerwijderen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerBesturen) }
  },
  {
    path: '/hoofdvestigingen',
    name: 'hoofdvestigingen',
    component: () => import('views/Admin/Hoofdvestigingen/HoofdvestigingenOverzicht.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerHoofdvestigingen) }
  },
  {
    path: '/hoofdvestigingen/importeren',
    name: 'hoofdvestigingen-importeren',
    component: () => import('views/Admin/Hoofdvestigingen/HoofdvestigingenImporteren.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerHoofdvestigingen) }
  },
  {
    path: '/hoofdvestigingen/details/:id',
    name: 'hoofdvestigingen-details',
    props: true,
    component: () => import('views/Admin/Hoofdvestigingen/HoofdvestigingDetails.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerHoofdvestigingen) }
  },
  {
    path: '/hoofdvestigingen/wijzigen/:id',
    name: 'hoofdvestigingen-wijzigen',
    props: true,
    component: () => import('views/Admin/Hoofdvestigingen/HoofdvestigingWijzigen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerHoofdvestigingen) }
  },
  {
    path: '/hoofdvestigingen/toevoegen',
    name: 'hoofdvestigingen-toevoegen',
    component: () => import('views/Admin/Hoofdvestigingen/HoofdvestigingToevoegen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerHoofdvestigingen) }
  },
  {
    path: '/toetsseries',
    name: 'toetsseries',
    component: () => import('views/Admin/Toetsseries/ToetsseriesOverzicht.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToetsdata) }
  },
  {
    path: '/toetsseries/details/:id',
    name: 'toetsserie-details',
    props: true,
    component: () => import('views/Admin/Toetsseries/ToetsserieDetails.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToetsdata) }
  },
  {
    path: '/toetsseries/wijzigen/:id',
    name: 'toetsserie-wijzigen',
    props: true,
    component: () => import('views/Admin/Toetsseries/ToetsserieWijzigen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToetsdata) }
  },
  {
    path: '/toetsseries/toevoegen',
    name: 'toetsserie-toevoegen',
    component: () => import('views/Admin/Toetsseries/ToetsserieToevoegen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToetsdata) }
  },
  {
    path: '/toetsseries/verwijderen/:id',
    name: 'toetsserie-verwijderen',
    props: true,
    component: () => import('views/Admin/Toetsseries/ToetsserieVerwijderen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToetsdata) }
  },
  {
    path: '/toetsseries/importeren',
    name: 'toetsseries-importeren',
    component: () => import('views/Admin/Toetsseries/ToetsseriesImporteren.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToetsdata) }
  },
  {
    path: '/normen/wijzigen/:id/:toetsmoment?',
    name: 'normen-wijzigen',
    props: true,
    component: () => import('views/Admin/Normen/NormenWijzigen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToetsdata) }
  },
  {
    path: '/percentielen/wijzigen/:id',
    name: 'percentielen-wijzigen',
    props: true,
    component: () => import('views/Admin/Normen/PercentielenWijzigen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToetsdata) }
  },
  {
    path: '/schoolwegingen',
    name: 'schoolwegingen',
    component: () => import('views/Admin/Schoolwegingen/SchoolwegingenOverzicht.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerSchoolwegingen) }
  },
  {
    path: '/schoolwegingen/details/:jaar/:brin',
    name: 'schoolweging-details',
    props: true,
    component: () => import('views/Admin/Schoolwegingen/SchoolwegingDetails.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerSchoolwegingen) }
  },
  {
    path: '/schoolwegingen/wijzigen/:jaar/:brin',
    name: 'schoolweging-wijzigen',
    props: true,
    component: () => import('views/Admin/Schoolwegingen/SchoolwegingWijzigen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerSchoolwegingen) }
  },
  {
    path: '/schoolwegingen/toevoegen',
    name: 'schoolweging-toevoegen',
    props: true,
    component: () => import('views/Admin/Schoolwegingen/SchoolwegingToevoegen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerSchoolwegingen) }
  },
  {
    path: '/schoolwegingen/verwijderen/:jaar/:brin',
    name: 'schoolweging-verwijderen',
    props: true,
    component: () => import('views/Admin/Schoolwegingen/SchoolwegingVerwijderen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerSchoolwegingen) }
  },
  {
    path: '/schoolwegingen/importeren',
    name: 'schoolwegingen-importeren',
    component: () => import('views/Admin/Schoolwegingen/SchoolwegingenImporteren.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerSchoolwegingen) }
  },
  {
    path: '/instellingen',
    name: 'instellingen-overzicht',
    component: () => import('views/Admin/Instellingen/InstellingenOverzicht.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerInstellingen) }
  },
  {
    path: '/instellingen/wijzigen',
    name: 'instellingen-wijzigen',
    component: () => import('views/Admin/Instellingen/InstellingenWijzigen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerInstellingen) }
  },
  {
    path: '/referentieniveau',
    name: 'referentieniveau-overzicht',
    component: () => import('views/Admin/Referentieniveau/ReferentieniveauOverzicht.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToetsdata) }
  },
  {
    path: '/referentieniveau/wijzigen/:vak/:niveau',
    name: 'referentieniveau-wijzigen',
    props: true,
    component: () => import('views/Admin/Referentieniveau/ReferentieniveauWijzigen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerToetsdata) }
  },
  {
    path: '/leerdoelen',
    name: 'leerdoelen',
    component: () => import('views/Admin/Leerdoelen/LeerdoelenOverzicht.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerLeerdoelen) }
  },
  {
    path: '/leerdoelen/details/:id',
    name: 'leerdoelen-details',
    props: true,
    component: () => import('views/Admin/Leerdoelen/LeerdoelDetails.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerLeerdoelen) }
  },
  {
    path: '/leerdoelen/wijzigen/:id',
    name: 'leerdoelen-wijzigen',
    props: true,
    component: () => import('views/Admin/Leerdoelen/LeerdoelWijzigen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerLeerdoelen) }
  },
  {
    path: '/leerdoelen/toevoegen',
    name: 'leerdoelen-toevoegen',
    component: () => import('views/Admin/Leerdoelen/LeerdoelToevoegen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerLeerdoelen) }
  },
  {
    path: '/leerdoelen/importeren',
    name: 'leerdoelen-importeren',
    component: () => import('views/Admin/Leerdoelen/LeerdoelenImporteren.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerLeerdoelen) }
  },
  {
    path: '/content/nieuwstegels',
    name: 'nieuwstegels-overzicht',
    component: () => import('views/Admin/Content/NieuwstegelsOverzicht.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerContent) }
  },
  {
    path: '/content/nieuwstegels/toevoegen',
    name: 'nieuwstegel-toevoegen',
    component: () => import('views/Admin/Content/NieuwstegelToevoegen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerContent) }
  },
  {
    path: '/content/nieuwstegels/wijzigen/:nieuwstegelId',
    name: 'nieuwstegel-wijzigen',
    props: true,
    component: () => import('views/Admin/Content/NieuwstegelWijzigen.vue'),
    beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.SysteembeheerContent) }
  }]
