<template>
    <a :class="classList" class="text-primary" :href="`/bestanden/${bestandsnaam}`" target="_blank" rel="noopener" @click="getDocument">{{ titel }} <slot></slot></a>
</template>

<script>
export default {
    name: 'DownloadLink',
    props: {
        titel: String,
        bestandsnaam: String,
        classList: String,
    },
    methods: {
        async getDocument(e) {
            e.preventDefault();
            return new Promise((resolve) => {
                this.$gt.api.content
                    .getSasUri(this.bestandsnaam)
                    .then((response) => {
                        const link = document.createElement('a');
                        link.href = response.result.sasUri;
                        link.download = response.result.naam;
                        link.target = '_blank';
                        document.body.appendChild(link);
                        link.click();
                        link.remove();

                        // link markeren als visisted
                        const currentUrl = window.location.href;
                        history.replaceState({}, '', e.target.href);
                        history.replaceState({}, '', currentUrl);
                    })
                    .finally(() => {
                        resolve();
                    });
            });
        },
    },
};
</script>
