import leerkrachtenApi from './Scholen/leerkrachtenApi'
import jaarplannenApi from './Scholen/jaarplannenApi'
import opbrengstenApi from './Scholen/opbrengstenApi'
import themaplannenApi from './Scholen/themaplannenApi'
import ambitiesApi from './Scholen/ambitiesApi'
import activiteitenApi from './Scholen/activiteitenApi'
import themasApi from './Scholen/themasApi'
import groepen12Api from './Scholen/groepen12Api'
import scholenApi from './Scholen/scholenApi'
import schoolLeerlijnenApi from './Scholen/leerlijnenApi'
import leerroutesApi from './Scholen/leerroutes'
import lesmethodenApi from './Scholen/lesmethodenApi'
import blokplannenApi from './Scholen/blokplannenApi'
import toetsresultatenApi from './Scholen/toetsresultatenApi'
import leerlingenApi from './Scholen/leerlingenApi'
import doelgroepenApi from './Scholen/doelgroepenApi'
import responseApi from './Scholen/responseApi'
import groepenApi from './Scholen/groepenApi'
import koppelingenApi from './Scholen/koppelingenApi'
import niveausApi from './Scholen/niveausApi'
import facturatieApi from './Scholen/facturatieApi'
import rapportenApi from './Scholen/rapportenApi'
import logboekApi from './Scholen/logboekApi'
import downloadsApi from './Scholen/downloadsApi'
import schoolaanbodApi from './Scholen/schoolaanbodApi'
import schoolplannenApi from './Scholen/schoolplannenApi'
import leerdoelbeheersingApi from './Scholen/leerdoelbeheersingApi'
import rapportInstellingenApi from './Scholen/instellingenApi'

import abonnementenApi from './Abonnementen'
import accountApi from './Account'
import besturenApi from './Besturen'
import metaOpbrengsten from './Meta/opbrengstenApi'
import bestuurdersApi from './Bestuurders'
import contentApi from './Content'
import domeinenApi from './Domeinen'
import exportApi from './Export'
import gebruikersApi from './Gebruikers'
import hoofdvestigingenApi from './Hoofdvestigingen'
import instellingenApi from './Instellingen'
import leerdoelenApi from './Leerdoelen'
import leerlijnenApi from './Leerlijnen'
import medewerkersApi from './Medewerkers'
import parnassysApi from './Parnassys'
import referentieniveausApi from './Referentieniveaus'
import schoolwegingenApi from './Schoolwegingen'
import toetsseriesApi from './Toetsseries'
import ssoApi from './Sso'

class Focus {
  config

  constructor () {
    this.abonnementen = abonnementenApi
    this.account = accountApi
    this.besturen = besturenApi
    this.bestuurders = bestuurdersApi
    this.content = contentApi
    this.domeinen = domeinenApi
    this.export = exportApi
    this.gebruikers = gebruikersApi
    this.hoofdvestigingen = hoofdvestigingenApi
    this.instellingen = instellingenApi
    this.templates = leerlijnenApi
    this.leerdoelen = leerdoelenApi
    this.medewerkers = medewerkersApi
    this.parnassys = parnassysApi
    this.facturatie = facturatieApi
    this.metaOpbrengsten = metaOpbrengsten
    this.sso = ssoApi

    this.leerkrachten = leerkrachtenApi
    this.opbrengsten = opbrengstenApi
    this.scholen = scholenApi
    this.jaarplannen = jaarplannenApi
    this.groepen12 = groepen12Api
    this.referentieniveaus = referentieniveausApi
    this.themaplan = themaplannenApi
    this.thema = themasApi
    this.ambities = ambitiesApi
    this.toetsseries = toetsseriesApi
    this.blokplanning = blokplannenApi
    this.activiteiten = activiteitenApi
    this.schoolwegingen = schoolwegingenApi
    this.leerlingen = leerlingenApi
    this.toetsresultaten = toetsresultatenApi
    this.doelgroepen = doelgroepenApi
    this.niveaus = niveausApi
    this.koppelingen = koppelingenApi
    this.response = responseApi
    this.leerlijnen = schoolLeerlijnenApi
    this.leerroutes = leerroutesApi
    this.lesmethoden = lesmethodenApi
    this.groepen = groepenApi
    this.rapporten = rapportenApi
    this.logboek = logboekApi
    this.downloads = downloadsApi
    this.schoolaanbod = schoolaanbodApi
    this.schoolplannen = schoolplannenApi
    this.leerdoelbeheersing = leerdoelbeheersingApi
    this.rapportInstellingen = rapportInstellingenApi
  }
}

export default Focus
