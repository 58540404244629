import httpClient from '../HttpClient'

const headers = { 'Content-Type': 'application/json' }
export default {
  getOverzicht: async (schoolId, vak, parameters) => {
    return httpClient.call(`/scholen/${schoolId}/blokplannen/${vak}`, { method: 'get', parameters }, true)
  },
  verwijderBlokplan: async (schoolId, blokplanId) => {
    return httpClient.deleteJson(`/scholen/${schoolId}/blokplannen/${blokplanId}`, { invalidateCacheTag: 'jaarplannen', toonErrorNotify: true })
  },
  verwijderBlokplannen: async (schoolId, vak, blok) => {
    return httpClient.deleteJson(`/scholen/${schoolId}/blokplannen/${vak}/${blok}`, { invalidateCacheTag: 'jaarplannen', toonErrorNotify: true })
  },
  resetBlokplan: async (schoolId, blokplanId) => {
    return httpClient.call(`/scholen/${schoolId}/blokplannen/${blokplanId}/initialiseer`, { method: 'options', headers }, true)
  },
  resetBlokplannen: async (schoolId, vak, blok) => {
    return httpClient.call(`/scholen/${schoolId}/blokplannen/${vak}/${blok}/initialiseer`, { method: 'options', headers }, true)
  },
  wijzigBlokplanStatus: async (schoolId, blokplanId, data) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/a3dc2666-dde7-485c-98dd-3914fba8597e/blokplannen/${blokplanId}/status`, { method: 'put', data, headers }, true)
  },
  verwijderBlokplannenVoorVak: async (schoolId, vak) => {
    return httpClient.deleteJson(`/scholen/${schoolId}/blokplannen?vak=${vak}`, { invalidateCacheTag: 'jaarplannen', toonErrorNotify: true })
  },
  toevoegenBlokplan: async (schoolId, groepId, data) => {
    return httpClient.post(`/scholen/${schoolId}/groepen/${groepId}/blokplannen`, { data, invalidateCacheTag: 'jaarplannen', toonErrorNotify: true })
  },
  toevoegenBlokplanLeerjaar: async (schoolId, leerjaar, data) => {
    return httpClient.post(`/scholen/${schoolId}/leerjaren/${leerjaar}/blokplannen`, { data, invalidateCacheTag: 'jaarplannen', toonErrorNotify: true })
  },
  toevoegenBlokdoel: async (schoolId, groepId, blokplanId, niveau, data) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId}/blokplannen/${blokplanId}/blokdoelen/${niveau}`, { method: 'post', data }, true)
  },
  verwijderBlokdoel: async (schoolId, groepId, blokplanId, niveau, data) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId}/blokplannen/${blokplanId}/blokdoelen/${niveau}`, { method: 'delete', data }, true)
  },
  toevoegenBorgingsdoel: async (schoolId, groepId, blokplanId, niveau, data) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId}/blokplannen/${blokplanId}/borgingsdoelen/${niveau}`, { method: 'post', data }, true)
  },
  verwijderBorgingsdoel: async (schoolId, groepId, blokplanId, niveau, data) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId}/blokplannen/${blokplanId}/borgingsdoelen/${niveau}`, { method: 'delete', data }, true)
  },
  toevoegenBezemdoel: async (schoolId, groepId, blokplanId, niveau, data) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId}/blokplannen/${blokplanId}/bezemdoelen`, { method: 'post', data }, true)
  },
  verwijderBezemdoel: async (schoolId, groepId, blokplanId, niveau, data) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId}/blokplannen/${blokplanId}/bezemdoelen`, { method: 'delete', data }, true)
  },
  toevoegenBlokdoelLeerjaar: async (schoolId, leerjaar, blokplanId, niveau, data) => {
    return httpClient.call(`/scholen/${schoolId}/leerjaren/${leerjaar}/blokplannen/${blokplanId}/blokdoelen/${niveau}`, { method: 'post', data }, true)
  },
  verwijderBlokdoelLeerjaar: async (schoolId, leerjaar, blokplanId, niveau, data) => {
    return httpClient.call(`/scholen/${schoolId}/leerjaren/${leerjaar}/blokplannen/${blokplanId}/blokdoelen/${niveau}`, { method: 'delete', data }, true)
  },
  toevoegenBorgingsdoelLeerjaar: async (schoolId, leerjaar, blokplanId, niveau, data) => {
    return httpClient.call(`/scholen/${schoolId}/leerjaren/${leerjaar}/blokplannen/${blokplanId}/borgingsdoelen/${niveau}`, { method: 'post', data }, true)
  },
  verwijderBorgingsdoelLeerjaar: async (schoolId, leerjaar, blokplanId, niveau, data) => {
    return httpClient.call(`/scholen/${schoolId}/leerjaren/${leerjaar}/blokplannen/${blokplanId}/borgingsdoelen/${niveau}`, { method: 'delete', data }, true)
  },
  toevoegenBezemdoelLeerjaar: async (schoolId, leerjaar, blokplanId, niveau, data) => {
    return httpClient.call(`/scholen/${schoolId}/leerjaren/${leerjaar}/blokplannen/${blokplanId}/bezemdoelen`, { method: 'post', data }, true)
  },
  verwijderBezemdoelLeerjaar: async (schoolId, leerjaar, blokplanId, niveau, data) => {
    return httpClient.call(`/scholen/${schoolId}/leerjaren/${leerjaar}/blokplannen/${blokplanId}/bezemdoelen`, { method: 'delete', data }, true)
  },
  markeerBlokdoelGezien: async (schoolId, groepId, blokdplanId, niveau) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId}/blokplannen/${blokdplanId}/blokdoelen/${niveau}/check`, { method: 'put' }, true)
  },
  markeerBezemoelGezien: async (schoolId, groepId, blokdplanId, niveau) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId}/blokplannen/${blokdplanId}/bezemdoelen/${niveau}/check`, { method: 'put' }, true)
  },
  markeerBorgingsdoelGezien: async (schoolId, groepId, blokdplanId, niveau) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId}/blokplannen/${blokdplanId}/borgingsdoelen/${niveau}/check`, { method: 'put' }, true)
  },
  markeerBlokdoelGezienLeerjaar: async (schoolId, leerjaar, blokdplanId, niveau) => {
    return httpClient.call(`/scholen/${schoolId}/leerjaren/${leerjaar}/blokplannen/${blokdplanId}/blokdoelen/${niveau}/check`, { method: 'put' }, true)
  },
  markeerBezemoelGezienLeerjaar: async (schoolId, leerjaar, blokdplanId, niveau) => {
    return httpClient.call(`/scholen/${schoolId}/leerjaren/${leerjaar}/blokplannen/${blokdplanId}/bezemdoelen/${niveau}/check`, { method: 'put' }, true)
  },
  markeerBorgingsdoelGezienLeerjaar: async (schoolId, leerjaar, blokdplanId, niveau) => {
    return httpClient.call(`/scholen/${schoolId}/leerjaren/${leerjaar}/blokplannen/${blokdplanId}/borgingsdoelen/${niveau}/check`, { method: 'put' }, true)
  },
  getBlokplan: async (schoolId, groepId, blokplanId) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId}/blokplannen/${blokplanId}`, { method: 'get' }, true)
  },
  getBlokplanLeerjaar: async (schoolId, leerjaar, blokplanId) => {
    return httpClient.call(`/scholen/${schoolId}/leerjaren/${leerjaar}/blokplannen/${blokplanId}`, { method: 'get' }, true)
  },
  getBlokplanConcept: async (schoolId, blokplanId, hoofddoel) =>
    httpClient.get(`/scholen/${schoolId}/blokplannen/${blokplanId}/doelen`, { parameters: { hoofddoel } }),
  wijzigBlokplanConcept: async (schoolId, blokplanId, data) =>
    httpClient.patchJson(`/scholen/${schoolId}/blokplannen/${blokplanId}/doelen`, { data }),
  getBlokplannen: async (schoolId, groepId, parameters) => {
    return httpClient.get(`/scholen/${schoolId}/groepen/${groepId}/blokplannen`, { parameters })
  },
  getBlokplannenLeerjaar: async (schoolId, leerjaar, parameters) => {
    return httpClient.get(`/scholen/${schoolId}/leerjaren/${leerjaar}/blokplannen`, { parameters })
  },
  getBlokplanDoelen: async (schoolId, groepId, blokplanId) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId}/blokplannen/${blokplanId}/doelen`, { method: 'get' }, true)
  },
  getBlokplanDoelenLeerjaar: async (schoolId, leerjaar, blokplanId) => {
    return httpClient.call(`/scholen/${schoolId}/leerjaren/${leerjaar}/blokplannen/${blokplanId}/doelen`, { method: 'get' }, true)
  },
  getBlokplanBlokdoelen: async (schoolId, groepId, blokplanId, niveau) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId || blokplanId}/blokplannen/${blokplanId}/blokdoelen/${niveau}`, { method: 'get' }, true)
  },
  getBlokplanBorgingsdoelen: async (schoolId, groepId, blokplanId, niveau) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId || blokplanId}/blokplannen/${blokplanId}/borgingsdoelen/${niveau}`, { method: 'get' }, true)
  },
  getBlokplanBezemdoelen: async (schoolId, groepId, blokplanId, niveau) => {
    return httpClient.call(`/scholen/${schoolId}/groepen/${groepId || blokplanId}/blokplannen/${blokplanId}/bezemdoelen/${niveau}`, { method: 'get' }, true)
  },
  wijzigBeheersingLeerjaar: async (schoolId, leerjaar, vak, data, niveau, reset) => {
    return httpClient.patchJson(`/scholen/${schoolId}/leerjaren/${leerjaar}/doelen/${vak}/${niveau}`, { data, parameters: { reset }, toonErrorNotify: true, toonServerResponse: true })
  },
  wijzigBeheersing: async (schoolId, groepId, vak, data, niveau, reset) => {
    return httpClient.patchJson(`/scholen/${schoolId}/groepen/${groepId}/doelen/${vak}/${niveau}`, { data, parameters: { reset }, toonErrorNotify: true, toonServerResponse: true })
  },
  getWeekplanning: async (schoolId, blokplanId) =>
    httpClient.get(`/scholen/${schoolId}/blokplannen/${blokplanId}/weekplanning`),
  wijzigWeekplanning: async (schoolId, blokplanId, data) =>
    httpClient.put(`/scholen/${schoolId}/blokplannen/${blokplanId}/weekplanning`, { data })
}
