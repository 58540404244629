import { ifAuthenticated } from 'helpers/routeHelpers'

export default [
  {
    path: '/start1234',
    name: 'start1234',
    component: () => import('views/Schoolbeheer/Instellingen/InstellingenStart.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/start1234/doelgroepen',
    name: 'start1234-doelgroepen',
    component: () => import('views/Schoolbeheer/Instellingen/DoelgroepenOverzicht.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/start1234/doelgroepen/wijzigen',
    name: 'start1234-doelgroepen-wijzigen',
    component: () => import('views/Schoolbeheer/Instellingen/DoelgroepWijzigen.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/start1234/doelgroepen/wijzigen/naam',
    name: 'start1234-doelgroepen-naam-wijzigen',
    component: () => import('views/Schoolbeheer/Instellingen/DoelgroepNaamWijzigen.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/start1234/ambities',
    name: 'start1234-ambities',
    component: () => import('views/Schoolbeheer/Instellingen/AmbitiesOverzicht.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/start1234/schoolaanbod',
    name: 'start1234-schoolaanbod',
    component: () => import('views/Schoolbeheer/Instellingen/SchoolaanbodOverzicht.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/start1234/jaarplanning',
    name: 'start1234-jaarplanning',
    component: () => import('views/Schoolbeheer/Instellingen/JaarplanningOverzicht.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/start1234/schoolleerlijnen',
    name: 'start1234-schoolleerlijnen',
    component: () => import('views/Schoolbeheer/Instellingen/SchoolleerlijnenOverzicht.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/start1234/onderwijsplannen',
    name: 'start1234-onderwijsplannen',
    component: () => import('views/Schoolbeheer/Instellingen/OnderwijsplannenOverzicht.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/start1234/lesmethodes',
    name: 'start1234-lesmethodes',
    component: () => import('views/Schoolbeheer/Instellingen/LesmethodesOverzicht.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/start1234/rapportages',
    name: 'start1234-rapportages',
    component: () => import('views/Schoolbeheer/Instellingen/RapportagesOverzicht.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/start1234/rapportages/wijzigen/:id?',
    name: 'start1234-rapportages-wijzigen',
    props: true,
    component: () => import('views/Schoolbeheer/Instellingen/RapportagesWijzigen.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/start1234/rapportages/wijzigen/ontwikkelingsgebieden/:templateId/:id?',
    name: 'start1234-rapportages-ontwikkelingsgebieden',
    props: true,
    component: () => import('views/Schoolbeheer/Instellingen/RapportagesOntwikkelingsgebieden.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/start1234/rapportages/wijzigen/tekst-foto-vakken/:templateId/:id?/:ontwikkelgebiedId?',
    name: 'start1234-rapportages-tekst-foto-vakken',
    props: true,
    component: () => import('views/Schoolbeheer/Instellingen/RapportagesTekstFotoVelden.vue'),
    beforeEnter: ifAuthenticated
  }
]
