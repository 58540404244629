import httpClient from '../HttpClient'
import { useScholenStore } from '@/stores/scholen'

export default {
  getGroep: async (schoolId, groepId) =>
    httpClient.getCached(`/scholen/${schoolId}/combinatiegroepen/${groepId}`, { cacheTags: ['groepen12', 'doelgroepen', 'toetsresultaten'] }),
  getGroepen: async (schoolId) =>
    httpClient.getCached(`/scholen/${schoolId}/combinatiegroepen`, { cacheTags: ['groepen12', 'doelgroepen', 'toetsresultaten'] }),
  getLeerlingenInGroep: async (schoolId, groepId, forceRefresh = false) =>
    httpClient.getCached(`/scholen/${schoolId}/groepen12/${groepId}/leerlingen`, { cacheTags: ['groepen12', 'doelgroepen', 'leerlingen12', 'toetsresultaten'], forceRefresh }),
  getGroepdoelen: async (schoolId, groepId, vak, parameters) =>
    httpClient.get(`/scholen/${schoolId}/groepen12/${groepId}/doelen/${vak}`, { parameters }),
  registreerInPlanningscyclus: async (schoolId, groepId, leerlingId) =>
    httpClient.put(`/scholen/${schoolId}/groepen12/${groepId}/leerlingen/${leerlingId}/planningscyclus`, { invalidateCacheTag: 'leerlingen12' }),
  verwijderUitPlanningscyclus: async (schoolId, groepId, leerlingId) =>
    httpClient.delete(`/scholen/${schoolId}/groepen12/${groepId}/leerlingen/${leerlingId}/planningscyclus`, { invalidateCacheTag: 'leerlingen12' }),
  wijzigBeheersing: async (schoolId, groepId, data, parameters) =>
    httpClient.patchJson(`/scholen/${schoolId}/groepen12/${groepId}/doelen`, { data, parameters }),
  getNorm: async (schoolId, groepId, leerlingId, parameters) =>
    httpClient.call(`/scholen/${schoolId}/groepen12/${groepId}/leerlingen/${leerlingId}/norm`, { method: 'get', parameters }, true),
  getDomeinscores: async (schoolId, groepId, leerlingId, vak) =>
    httpClient.get(`/scholen/${schoolId}/groepen12/${groepId}/leerlingen/${leerlingId}/domeinscores/${vak}`),
  getDoelenInOntwikkelingLeerling: async (schoolId, leerlingId, vak) =>
    httpClient.get(`/scholen/${schoolId}/groepen12/groepen/leerlingen/${leerlingId}/doeleninontwikkeling/${vak}`),
  getDoelenInOntwikkelingSubdomein: async (schoolId, groepId, domeinId) =>
    httpClient.get(`/scholen/${schoolId}/groepen12/${groepId}/doeleninontwikkeling/${domeinId}`),
  getDomeinscores12: async (groepId, parameters) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/groepen12/${groepId}/domeinscores`, { parameters }),
  getDomeinscoresLeerjaar12: async (leerjaar, parameters) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerjaren/${leerjaar}/domeinscores`, { parameters }),
  getDomeinscoresLeerjaren12: async (vak) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerjaren/domeinscores?leerjaren=1&leerjaren=2&vak=${vak}`),
  getGroeioverzicht: async (groepId, leerlingId, vak, parameters) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/groepen12/${groepId}/leerlingen/${leerlingId}/groeioverzicht/${vak}`, { parameters }),
  getDoelenoverzicht: async (groepId, leerlingId, vak, parameters) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/groepen12/${groepId}/leerlingen/${leerlingId}/doelen/${vak}`, { parameters })
}
