
import { ifAuthenticated } from 'helpers/routeHelpers'

export default [{
  path: '/sso/zendesk',
  name: 'sso-zendesk',
  props: true,
  component: () => import('views/Sso/Zendesk.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, null, true) }
}]
