
import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [{
  path: '/schoolbeheer/importeren',
  name: 'leerlinggegevens-importeren',
  component: () => import('views/Schoolbeheer/Import/LeerlinggegevensImporteren.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, [features.BeheerImporteren, features.BeheerImport]) }
},
{
  path: '/schoolbeheer/importeren/succes',
  name: 'importeren-succes',
  component: () => import('views/Schoolbeheer/Import/ImporterenGelukt.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.BeheerImporteren) }
},
{
  path: '/schoolbeheer/importeren/diataal/succes',
  name: 'importeren-diataal-succes',
  component: () => import('views/Schoolbeheer/Import/ImporterenDiataalGelukt.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.BeheerImporteren) }
}]
