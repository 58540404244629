import httpClient from '../HttpClient'
import { useScholenStore } from '@/stores/scholen'

export default {
  getLeerlijnenSchool: async id =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerroutes`),
  getLeerlijnenDoelgroep: async (doelgroepId) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/doelgroepen/${doelgroepId}/leerroutes`),
  getLeerlijnenSchoolVak: async (vak) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerroutes/${vak}`),
  wijzigLeerlijnenSchoolVakProfiel: async (vak, profiel, data) =>
    httpClient.putJson(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerroutes/${vak}/${profiel}`, { data }),
  wijzigLeerlijnenDoelgroepVakProfiel: async (doelgroepId, vak, profiel, data) =>
    httpClient.putJson(`/scholen/${await useScholenStore().getHuidigeSchoolId}/doelgroepen/${doelgroepId}/leerroutes/${vak}/${profiel}`, { data }),
  getGroepLeerroutes: async (groepId) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/groepen/${groepId}/leerroutes`)
}
