import './styles/quasar.scss';
// import 'quasar/dist/quasar.ie.polyfills'
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/material-icons-round/material-icons-round.css';
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import { Notify } from 'quasar';
import langNl from 'quasar/lang/nl';

import * as all from 'quasar';

// To be used on app.use(Quasar, { ... })
export default {
    config: {
        brand: {
            primary: '#0B57FF',
        },
    },
    components: all,
    directives: all,
    plugins: {
        Notify,
    },
    lang: langNl,
};
