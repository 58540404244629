<template>
  <nav-header :actieveLink="props.actieveLink" v-click-away="onClickAway">
    <template v-slot:naam-slot>
      <div v-if="toonSchoolnaam" class="middle flex bold fs-18 ml-40" style="color: #06254A;">{{ stores.scholen.demoSchool?.naam }}</div>
    </template>
    <template v-slot:menu-slot>
      <span class="menu-link">{{ ingelogdeGebruiker.naam }}</span >
      <router-link v-if="!isSysteembeheerder || inBestuurDemo" :to="{ name:'meta' }" class="menu-link home-icon pointer" :class="{ actief: props.actieveLink === 'meta'}"></router-link>
      <router-link v-else :to="{ name:'welkom' }" class="menu-link home-icon pointer" :class="{ actief: props.actieveLink === 'Home'}"></router-link>
      <a href="#" class="menu-link logout-icon pointer" id="logout-button" @click="logout()"><q-tooltip :delay="500">Uitloggen</q-tooltip></a>

      <div v-if="isSysteembeheerder && !inBestuurDemo" class="mega-menu-toggle flex" @click="toggleMegaMenu">
        <div class="menu-icon"></div>
        <div>Menu</div>
      </div>
      <div class="mega-menu-toggle flex flex-center ml-10" style="background-color: #FF3333" @click="Terug(knopLink)">
        <span>{{knopTekst}}</span>
      </div>

    </template>
    <template v-slot:mega-menu-slot >
      <mega-menu v-if="isSysteembeheerder && !inBestuurDemo" :style="{ visibility: megaMenuIsVisible ? 'visible' : 'hidden'}" v-on:verberg-menu="onClickAway"></mega-menu>
    </template>
  </nav-header>
</template>

<script setup>
import megaMenu from '@/components/Navigatie/LeerkrachtMegaMenu.vue'
import { ref, computed, inject } from 'vue'
import { useRouter } from 'vue-router'

// Er zijn 2 demo's: die van focus systeembeheerders (school -> demo) en van meta gebruikers die bij een school in het bestuur kunnen kijken. Bij systeembeheerders de schoolnaam *niet* in de header getoont worden, ook niet als die via de demo van een bestuur een school demo starten (ja ja dat kan ook)
const toonSchoolnaam = computed(() => stores.besturen.demoBestuurId || !stores.account.ingelogdeGebruiker?.rollen?.includes('SYSTEEMBEHEERDER'))

const props = defineProps({
  actieveLink: String,
  knopTekst: String,
  knopLink: String
})

const stores = inject('stores')
const ingelogdeGebruiker = computed(() => stores.account.ingelogdeGebruiker)
const isSysteembeheerder = computed(() => stores.account.ingelogdeGebruiker?.rollen?.includes('SYSTEEMBEHEERDER'))

// Er zijn 2 demo's: die van een school en van een bestuur. Systeembeheerders kunnen een demo van een bestuur starten en daarna een demo van een school binnen dat bestuur starten (demoception). 
const inBestuurDemo = computed(() => stores.besturen.demoBestuurId)

const megaMenuIsVisible = ref(false)
function toggleMegaMenu () {
  megaMenuIsVisible.value = !megaMenuIsVisible.value
}

function logout () {
  stores.account.logout()
    .finally(() => {
      window.location.reload()
    })
}

const router = useRouter()
async function Terug (routeName) {
  stores.scholen.resetDemoSchool()
  await router.push({ name: routeName })
}
const onClickAway = (event) => {
  if (megaMenuIsVisible.value) {
    megaMenuIsVisible.value = false
  }
}

</script>
