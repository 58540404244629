<template>
  <nav-header :actieveLink="actieveLink" :toonHomeLink="true">
    <template v-slot:menu-slot>
      <div class="dynamische-links">
        <router-link v-if="heeftFeatureSysteembeheerInstellingen  && heeftTfaIngeschakeld" :to="{ name: 'instellingen-overzicht' }">Instellingen</router-link>

        <a v-if="heeftFeatureSysteembeheerContent && heeftTfaIngeschakeld">Content  <q-icon name="expand_more" class="dropdown-menu"/>
          <q-menu :offset="[0, 10]">
            <q-list style="min-width: 100px">
              <q-item clickable v-close-popup :to="{ name: 'nieuwstegels-overzicht' }">
                <q-item-section>Nieuwstegels</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </a >

        <a v-if="heeftFeatureSysteembeheerScholen && heeftTfaIngeschakeld">Gebruikers  <q-icon name="expand_more"   class="dropdown-menu"/>
          <q-menu :offset="[0, 10]">
            <q-list style="min-width: 100px">
              <q-item clickable v-close-popup :to="{ name: 'scholen' }">
                <q-item-section>Scholen</q-item-section>
              </q-item>
              <q-item clickable v-close-popup :to="{ name: 'gebruikers-besturen' }">
                <q-item-section>Besturen</q-item-section>
              </q-item>
              <q-item clickable v-close-popup :to="{ name: 'parnassys' }">
                <q-item-section>ParnasSys synchronisatie</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </a >

        <a v-if="heeftFeatureSysteembeheerToegang && heeftTfaIngeschakeld">Toegang  <q-icon name="expand_more" class="dropdown-menu"/>
          <q-menu :offset="[0, 10]">
            <q-list style="min-width: 100px">
              <q-item clickable v-close-popup :to="{ name: 'medewerkers-overzicht' }">
                <q-item-section>Medewerkers</q-item-section>
              </q-item>
              <q-item clickable v-close-popup :to="{ name: 'admin-gebruikers-overzicht' }">
                <q-item-section>Alle gebruikers</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </a >

        <a v-if="heeftFeatureSysteembeheerToetsdata && heeftTfaIngeschakeld">Toetsdata  <q-icon name="expand_more" class="dropdown-menu" />
          <q-menu :offset="[0, 10]">
            <q-list style="min-width: 100px">
              <q-item clickable v-close-popup :to="{ name: 'toetsseries' }">
                <q-item-section>Vaktabellen</q-item-section>
              </q-item>
              <q-item clickable v-close-popup :to="{ name: 'referentieniveau-overzicht' }">
                <q-item-section>Referentieniveaus</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </a >

        <a v-if="(heeftFeatureSysteembeheerBesturen || heeftFeatureSysteembeheerSchoolwegingen || heeftFeatureSysteembeheerHoofdvestigingen) && heeftTfaIngeschakeld">Import  <q-icon name="expand_more"  class="dropdown-menu" />
          <q-menu :offset="[0, 10]">
            <q-list style="min-width: 100px">
              <q-item clickable v-close-popup :to="{ name: 'besturen' }" v-if="heeftFeatureSysteembeheerBesturen">
                <q-item-section>Besturen</q-item-section>
              </q-item>
              <q-item clickable v-close-popup :to="{ name: 'hoofdvestigingen' }" v-if="heeftFeatureSysteembeheerHoofdvestigingen">
                <q-item-section>Hoofdvestigingen</q-item-section>
              </q-item>
              <q-item clickable v-close-popup :to="{ name: 'schoolwegingen' }" v-if="heeftFeatureSysteembeheerSchoolwegingen">
                <q-item-section>Schoolwegingen</q-item-section>
              </q-item>
              <q-item clickable v-close-popup :to="{ name: 'leerdoelen' }" v-if="heeftFeatureSysteembeheerLeerdoelen">
                <q-item-section>Leerdoelen</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </a >

        <template v-if="!heeftFeatureSysteembeheer || inDemoModus">
          <router-link v-if="heeftFeatureBeheerMijnGegevens && registratieAfgerond && heeftTfaIngeschakeld" :to="{ name: 'mijn-gegevens' }" :class="{ actief: actieveLink === 'MijnGegevens' }">Mijn gegevens</router-link>
          <router-link v-else-if="heeftFeatureBeheer && !registratieAfgerond" :to="{ name: 'registratie-afronden' }" :class="{ actief: actieveLink === 'AfrondenRegistratie' }">Afronden registratie</router-link>
          <a v-if="heeftFeatureBeheer && (!registratieAfgerond || !actiefAbonnement || !heeftTfaIngeschakeld)" @click="logout()">Uitloggen</a >
          <router-link v-if="heeftFeatureBeheerImporteren && heeftTfaIngeschakeld" :to="{ name: 'leerlinggegevens-importeren' }" :class="{ actief: actieveLink === 'Importeren' }">Import</router-link>

          <router-link v-if="heeftFeatureBeheerInstellingen && heeftTfaIngeschakeld" :to="{ name: 'start1234' }" :class="{ actief: actieveLink === 'Instellingen' }">Instellingen</router-link>
          <router-link v-if="heeftFeatureBeheerToegang && heeftTfaIngeschakeld" :to="{ name: 'leerkrachten-overzicht' }" :class="{ actief: actieveLink === 'Toegang' }">Toegang</router-link>
          <router-link v-if="heeftFeatureBeheerLeerling && heeftTfaIngeschakeld" :to="{ name: 'leerling-overzicht' }" :class="{ actief: actieveLink === 'Leerling' }">Leerling</router-link>
          <router-link v-if="heeftFeatureBeheerLeerling && heeftFeatureLeerdoelen38 && heeftTfaIngeschakeld" :to="{ name: 'monitor-index' }" :class="{ actief: actieveLink === 'Monitor' }">Monitor</router-link>
        </template>
        <a v-if="heeftFeatureSysteembeheer" @click="logout()">Uitloggen</a >
      </div>
    </template>
  </nav-header>
</template>

<script>
export default {
  name: 'beheerder-menu',
  props: {
    actieveLink: String
  },
  data () {
    return {
      title: this.$gt.features.Beheer,
      actieveSchool: this.$gt.stores.scholen.getHuidigeSchool ? this.$gt.stores.scholen.getHuidigeSchool.id : null
    }
  },
  methods: {
    logout () {
      this.$gt.stores.account.logout()
        .finally(result => {
          window.location.reload()
        })
    },
    heeftFeature (feature) {
      return this.ingelogdeGebruiker?.features.includes(feature)
    }
  },
  computed: {
    ingelogdeGebruiker () {
      return this.$gt.stores.account.ingelogdeGebruiker
    },
    huidigeSchool () {
      return this.$gt.stores.scholen.getHuidigeSchool
    },
    naam () {
      return this.ingelogdeGebruiker ? this.ingelogdeGebruiker.naam : ''
    },
    registratieAfgerond () {
      return this.huidigeSchool ? this.huidigeSchool.registratieIsCompleet : true
    },
    actiefAbonnement () {
      return this.huidigeSchool?.abonnement?.isActief
    },
    heeftFeatureOpbrengsten () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.Opbrengsten)
    },
    heeftFeatureBeheer () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.Beheer)
    },
    heeftFeatureLeerdoelen38 () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.Leerdoelen38)
    },
    heeftFeatureBeheerImporteren () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.BeheerImporteren)
    },
    heeftFeatureBeheerMijnGegevens () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.BeheerMijnGegevens)
    },
    heeftFeatureBeheerInstellingen () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.BeheerInstellingen)
    },
    heeftFeatureBeheerToegang () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.BeheerToegang)
    },
    heeftFeatureBeheerLeerling () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.BeheerLeerling)
    },
    heeftFeatureSysteembeheer () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.Systeembeheer)
    },
    heeftFeatureSysteembeheerSchoolwegingen () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.SysteembeheerSchoolwegingen)
    },
    heeftFeatureSysteembeheerBesturen () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.SysteembeheerBesturen)
    },
    heeftFeatureSysteembeheerToegang () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.SysteembeheerToegang)
    },
    heeftFeatureSysteembeheerHoofdvestigingen () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.SysteembeheerHoofdvestigingen)
    },
    heeftFeatureSysteembeheerScholen () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.SysteembeheerScholen)
    },
    heeftFeatureSysteembeheerInstellingen () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.SysteembeheerInstellingen)
    },
    heeftFeatureSysteembeheerToetsdata () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.SysteembeheerToetsdata)
    },
    heeftFeatureSysteembeheerLeerdoelen () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.SysteembeheerLeerdoelen)
    },
    heeftFeatureSysteembeheerContent () {
      return this.ingelogdeGebruiker?.features.includes(this.$gt.features.SysteembeheerContent)
    },
    heeftTfaIngeschakeld () {
      return !this.$gt.TfaVerplicht || this.$gt.stores.account.tfaEnabled
    },
    homeLink () {
      if (this.ingelogdeGebruiker?.rollen.includes('SYSTEEMBEHEERDER')) {
        return 'scholen'
      } else {
        return 'home'
      }
    },
    inDemoModus () {
      return this.$gt.stores.scholen?.demoSchool?.id
    }
  },
  watch: {
    actieveSchool: async function (val) {
      this.$gt.stores.scholen.getDemoSchool(this.$gt.api.scholen, val)
        .then(response => this.$router.go())
    }
  }
}
</script>
