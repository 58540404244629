
import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [{
  path: '/opbrengsten/:vorigePagina?',
  name: 'opbrengsten',
  query: { doelgroepId: '', samenvoegingsniveau: '' },
  props: true,
  component: () => import('views/School/ToetsresultatenView.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Opbrengsten) }
},
{
  path: '/schoolaanbod',
  name: 'schoolaanbod',
  props: true,
  component: () => import('views/School/SchoolaanbodView.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen) }
},
{
  path: '/leerdoelbeheersing',
  name: 'leerdoelbeheersing',
  props: true,
  component: () => import('views/School/LeerdoelbeheersingView.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen38) }
}]
