
import { ifNotAuthenticated, ifAuthenticated, ifAuthenticatedRole } from 'helpers/routeHelpers'
import { rollen } from 'constants/rollen'

export default [{
  path: '/',
  name: 'home',
  component: () => import('views/Account/HomeView.vue'),
  beforeEnter: ifNotAuthenticated
},
{
  path: '/welkom',
  name: 'welkom',
  component: () => import('views/Home/SchoolStartscherm.vue'),
  beforeEnter (to, from, next) { ifAuthenticatedRole(to, from, next, [rollen.LEERKRACHT, rollen.SYSTEEMBEHEERDER, rollen.SUPPORTMEDEWERKER, rollen.ADVISEUR, rollen.BEHEERDER]) }
},
{
  path: '/meta',
  name: 'meta',
  component: () => import('views/Home/MetaStartscherm.vue'),
  beforeEnter (to, from, next) { ifAuthenticatedRole(to, from, next, [rollen.BESTUURDER, rollen.SYSTEEMBEHEERDER]) }
},
{
  path: '/media',
  alias: '/#/media',
  name: 'media-overzicht',
  component: () => import('views/Home/MediaOverzicht.vue'),
  beforeEnter: ifAuthenticated
},
{
  path: '/downloads',
  name: 'mijn-downloads',
  component: () => import('views/Home/MijnDownloads.vue'),
  beforeEnter: ifAuthenticated
},
{
  path: '/404',
  name: '404',
  component: () => import('views/Error/404Pagina.vue')
},
{
  path: '/500',
  name: '500',
  component: () => import('views/Error/500Pagina.vue')
}]
