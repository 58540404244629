<template>
  <div class="sub-menu">
    <router-link :to="{ name:'welkom' }">home</router-link>
    <span> ></span>
    <span>leerling</span>
    <span> ></span>

    <span v-if="activeLink === 'groeioverzicht'">Groeioverzicht</span>

    <router-link v-if="parent === 'rapport'" :to="{ name: 'leerling-rapport' }">Rapport</router-link>
    <span v-if="parent === 'rapport'"> ></span>

    <span v-if="activeLink === 'rapport'">Rapport</span>
    <span v-if="activeLink === 'overdracht'">Overdracht 2 > 3</span>
    <span v-if="activeLink === 'leerlingoverzicht'">Leerlingoverzicht 1-2</span>
    <span v-if="activeLink === 'rapport-bewerken'">Rapport bewerken</span>
    <span v-if="activeLink === 'rapport-downloaden'">Rapport downloaden</span>
  </div>
</template>

<script>
export default {
  name: 'leerling-submenu',
  props: {
    activeLink: String,
    parent: String
  }
}
</script>
