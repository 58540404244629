
import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [{
  path: '/leerling/rapport/:groepId?',
  name: 'leerling-rapport',
  props: true,
  component: () => import('views/Leerling/LeerlingRapport.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Rapporten) }
},
{
  path: '/leerling/rapport/V1/bewerken/:rapportId/:leerlingId/:leerjaar/:groepId',
  name: 'leerling-rapport-bewerken-v1',
  props: true,
  component: () => import('views/Leerling/RapportBewerkenV1.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Rapporten) }
},
{
  path: '/leerling/rapport/V2/bewerken/:rapportId/:leerlingId/:leerjaar/:groepId',
  name: 'leerling-rapport-bewerken-v2',
  props: true,
  component: () => import('views/Leerling/RapportBewerkenV2.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Rapporten) }
},
{
  path: '/leerling/normen',
  name: 'normen-overzicht',
  query: { leerlingId: null, groepId: null},
  component: () => import('views/Leerling/GroeiOverzicht.vue'),
  beforeEnter: ifAuthenticated
},
{
  path: '/leerling/overdracht/:groepId?',
  name: 'overdracht-beheersing',
  props: true,
  component: () => import('views/Leerling/OverdrachtBeheersing.vue'),
  beforeEnter: ifAuthenticated
},
{
  path: '/leerling/overzicht12',
  name: 'leerling12-overzicht',
  props: true,
  component: () => import('views/Leerling/LeerlingOverzicht.vue'),
  beforeEnter: ifAuthenticated
}]
