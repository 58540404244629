import httpClient from '../HttpClient'

export default {
  getOverzichtGroep: async (schoolId, groepId, vak) => {
    return httpClient.get(`/scholen/${schoolId}/groepen/${groepId}/response/${vak}`)
  },
  getOverzichtLeerjaar: async (schoolId, leerjaar, vak) => {
    return httpClient.get(`/scholen/${schoolId}/leerjaren/${leerjaar}/response/${vak}`)
  }
}
