<template>
  <div class="nav-header">
    <div class="user-menu">
      <div class="flex middle center middle">
        <router-link :to="{ name: homeLink }" class="flex middle"> <img src="../../assets/img/logo.png" height="30" width="125" alt=""/></router-link>
        <slot name="naam-slot"></slot>
      </div>
      <div class="menu-links" v-if="ingelogdeGebruiker">
        <slot name="menu-slot"></slot>
      </div>
      <div class="menu-links" v-if="ingelogdeGebruiker && toonHomeLink">
        <router-link :to="{ name:'home' }" class="home-link" :class="{ actief: actieveLink === 'Home'}"><i class="home-icon"></i></router-link>
      </div>
    </div>
    <slot name="mega-menu-slot"></slot>
  </div>
</template>

<script>
export default {
  name: 'navBar',
  props: {
    actieveLink: String,
    toonHomeLink: Boolean
  },
  async created () {
    if (!this.$gt.stores.account.ingelogdeGebruiker && this.$gt.stores.account.isAuthenticated) {
      try {
        await this.$gt.stores.account.getGebruiker(this.$gt.api.account)

        if (this.$gt.stores.account.ingelogdeGebruiker.bestuurId) {
          await this.$gt.stores.besturen.getBestuur(this.$gt.api.besturen, this.$gt.stores.account.ingelogdeGebruiker.bestuurId)
        }
      } catch {
        this.$gt.stores.account.logout()
          .finally(_ => {
            this.$router.push('/')
          })
      }
    }
  },
  data () {
    return {
      actieveSchool: this.$gt.stores.scholen.getHuidigeSchool ? this.$gt.stores.scholen.getHuidigeSchool.id : null
    }
  },
  computed: {
    ingelogdeGebruiker () {
      return this.$gt.stores.account.ingelogdeGebruiker
    },
    huidigeSchool () {
      return this.$gt.stores.scholen.getHuidigeSchool
    },
    naam () {
      return this.ingelogdeGebruiker ? this.ingelogdeGebruiker.naam : ''
    },
    heeftFeatureSysteembeheer () {
      return this.ingelogdeGebruiker?.features?.includes(this.$gt.features.Systeembeheer)
    },
    heeftTfaIngeschakeld () {
      return !this.$gt.TfaVerplicht || this.$gt.stores.account.tfaEnabled
    },
    //uitzoeken naar welke pagina de gebruiker wordt gestuurd. Als de gebruiker in een demo zit kan dit ook van invloed zijn op de startpagina
    homeLink () {
      if ((this.ingelogdeGebruiker?.rollen.includes('BESTUURDER') || this.$gt.stores.besturen.demoBestuurId) && this.heeftTfaIngeschakeld) {
        return 'meta'
      } else if ((this.ingelogdeGebruiker?.rollen.includes('BESTUURDER') || this.$gt.stores.scholen?.demoSchool?.id) && this.heeftTfaIngeschakeld) {
        return 'welkom'
      } else if (this.heeftTfaIngeschakeld) {
        return 'home'
      } else {
        return 'account-2fa'
      }
    }
  }
}
</script>
