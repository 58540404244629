<template>
  <article class="page-container school-layout">
    <header :class="{ 'demo': inDemoModus }">
      <div class="blue-gray-gradient">
        <slot name="nav-slot">
          <beheerder-menu></beheerder-menu>
        </slot>
      </div>
      <div v-if="hasHeaderSlot" class="header-container">
        <slot name="header-slot"></slot>
      </div>
    </header>

    <div class="slot-container">
        <slot name="body-slot" ></slot>
    </div>

    <footer v-if="hasFooterSlot">
        <slot name="footer-slot" ></slot>
    </footer>

  </article>
  <footer v-if="hasStickyFooterSlot" class="sticky-footer">
      <slot name="sticky-footer-slot" ></slot>
  </footer>
</template>

<script>
export default {
  props: {
    footerClass: String
  },
  async created () {
    if (!this.$gt.stores.account.isAuthenticated) {
      this.$router.push('/')
    }
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header-slot']
    },
    hasFooterSlot () {
      return !!this.$slots['footer-slot']
    },
    hasStickyFooterSlot () {
      return !!this.$slots['sticky-footer-slot']
    },
    inDemoModus () {
      return this.$gt.stores.scholen?.demoSchool?.id
    }
  }
}
</script>

<style scoped>
  header {
    background: #D693C5;
  }
  header.demo {
    background: #8292A4;
  }
  footer {
    height: unset;
  }
  footer.sticky-footer {
    position: sticky;
    z-index: 200;
  }
  h1 {
    color: white;
  }
</style>
