import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  getLeerdoelen: async (search, orderBy, start, limit) =>
    httpClient.call('/leerdoelen', { method: 'get', parameters: { search, orderBy, start, limit } }, true),
  getLeerdoel: async id =>
    httpClient.call(`/leerdoelen/${id}`, { method: 'get' }, true),
  wijzigLeerdoel: async (id, data) =>
    httpClient.call(`/leerdoelen/${id}`, { method: 'patch', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_HET} leerdoel`),
  registreerLeerdoel: async data =>
    httpClient.call('/leerdoelen', { method: 'post', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_HET} leerdoel`),
  importeerLeerdoelen: async data => {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return httpClient.call('/leerdoelen/bestand', { method: 'post', data, headers }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} leerdoelen`)
  }
}
