import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  getAmbitieprofielen: async () =>
    httpClient.getCached('/ambitieprofielen', { minutesToCache: 30 }),
  getSchoolambities: async (schoolId) =>
    httpClient.getCached(`/scholen/${schoolId}/ambities`, { minutesToCache: 30, cacheTags: ['ambities'], toonErrorNotify: false }),
  getDoelgroepambities: async (schoolId, doelgroepId) =>
    httpClient.getCached(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/ambities`, { minutesToCache: 30, cacheTags: ['ambities'], errorText: `${errors.FOUT_OPGETREDEN_OPHALEN_DE} ambities` }),
  getSchoolambitiesVak: async (schoolId, vak) =>
    httpClient.getCached(`/scholen/${schoolId}/ambities/${vak}`, { minutesToCache: 30, cacheTags: ['ambities'], toonErrorNotify: false }),
  getDoelgroepambitiesVak: async (schoolId, doelgroepId, vak) =>
    httpClient.getCached(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/ambities/${vak}`, { minutesToCache: 30, cacheTags: ['ambities'], toonErrorNotify: false }),
  wijzigSchoolambities: async (schoolId, vak, data) =>
    httpClient.put(`/scholen/${schoolId}/ambities/${vak}`, { invalidateCacheTag: 'ambities', data, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} ambities` }),
  wijzigDoelgroepambities: async (schoolId, doelgroepId, vak, data) =>
    httpClient.put(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/ambities/${vak}`, { invalidateCacheTag: 'ambities', data, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} ambities` })
}
