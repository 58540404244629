import httpClient from '../HttpClient'
import { useScholenStore } from '@/stores/scholen'

export default {
  getDownloads: async id =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/downloads`),
  downloadBestand: async (downloadPath) =>
    httpClient.download(`/scholen/${await useScholenStore().getHuidigeSchoolId}/downloads/${downloadPath}`),
  downloadBestanden: async (bestanden) =>
    httpClient.download(`/scholen/${await useScholenStore().getHuidigeSchoolId}/downloads/zip?bestanden=${bestanden}`),
  wijzigDownload: async (downloadPath, data) =>
    httpClient.patchJson(`/scholen/${await useScholenStore().getHuidigeSchoolId}/downloads/${downloadPath}`, { data }),
  verwijderDownload: async (downloadPath) =>
    httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/downloads/${downloadPath}`),

  getDownloadsBesturen: async bestuurId =>
    httpClient.get(`/besturen/${bestuurId}/downloads`),
  downloadBestandBesturen: async (bestuurId, downloadPath) =>
    httpClient.download(`/besturen/${bestuurId}/downloads/${downloadPath}`),
  downloadBestandenBesturen: async (bestuurId, bestanden) =>
    httpClient.download(`/besturen/${bestuurId}/downloads/zip?bestanden=${bestanden}`),
  wijzigDownloadBesturen: async (bestuurId, downloadPath, data) =>
    httpClient.patchJson(`/besturen/${bestuurId}/downloads/${downloadPath}`, { data }),
  verwijderDownloadBesturen: async (bestuurId, downloadPath) =>
    httpClient.delete(`/besturen/${bestuurId}/downloads/${downloadPath}`)
}
