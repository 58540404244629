import httpClient from '../HttpClient'
import { useScholenStore } from '@/stores/scholen'

export default {
  getSchoolLeerlijnen: async () =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlijnen`),
  getSchoolLeerlijn: async (leerlijnId) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlijnen/${leerlijnId}`),
  getSchoolLeerlijnConcept: async (leerlijnId) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlijnen/${leerlijnId}/concept`),
  wijzigLeerlijnStatus: async (leerlijnId, data) =>
    httpClient.putJson(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlijnen/${leerlijnId}/status`, { data }),
  wijzigConceptLeerlijn: async (leerlijnId, data) =>
    httpClient.patch(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlijnen/${leerlijnId}/concept`, { data }),
  wijzigConceptStatus: async (leerlijnId, data) =>
    httpClient.putJson(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlijnen/${leerlijnId}/concept/status`, { data }),
  resetSchoolLeerlijn: async (leerlijnId) =>
    httpClient.options(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlijnen/${leerlijnId}/reset`),
  verwijderConceptLeerlijn: async (leerlijnId) =>
    httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlijnen/${leerlijnId}/concept`)
}
