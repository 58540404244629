import httpClient from '../HttpClient'
import { useScholenStore } from '@/stores/scholen'

export default {
  getActiviteiten: async (themaId) => {
    return httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/themas/${themaId}/Activiteiten`)
  },
  registreerActiviteit: async (themaId, data) => {
    return httpClient.postJson(`/scholen/${await useScholenStore().getHuidigeSchoolId}/themas/${themaId}/Activiteiten`, { data })
  },
  updateActiviteit: async (themaId, activiteitId, data) => {
    return httpClient.patchJson(`/scholen/${await useScholenStore().getHuidigeSchoolId}/themas/${themaId}/Activiteiten/${activiteitId}`, { data })
  },
  verwijderActiviteit: async (themaId, activiteitId) => {
    return httpClient.deleteJson(`/scholen/${await useScholenStore().getHuidigeSchoolId}/themas/${themaId}/Activiteiten/${activiteitId}`)
  },
  getOntwikkelgebieden: async () => {
    return httpClient.get('/thema/ontwikkelingsgebieden')
  }
}
