import { defineStore } from 'pinia'

// Store voor het opslaan van selecties binnen het leerling "domein". Omdat het meegeven van parameters en props niet altijd handig is, staan deze in de store.
export const useParamsLeerlingStore = defineStore('ParamsLeerling', {
  state: () => {
    return {
      groepId: null,
      groepsNaam: null,
      leerjaar: null,
      ontwikkelgebied: null,
      leerlingId: null
    }
  },
  actions: {
    reset () {
      this.groepId = null
      this.groepsNaam = null
      this.leerjaar = null
      this.ontwikkelgebied = null
      this.leerlingId = null
    }
  },
  persist: true
})
