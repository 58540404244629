import httpClient from '../HttpClient'
import { useScholenStore } from '@/stores/scholen'

export default {
  getLeerdoelbeheersingVak: async (vak, parameters) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerdoelbeheersing/${vak}`, { parameters }),
  getLeerdoelbeheersingGroep: async (vak, groep, parameters) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/groepen/${groep}/leerdoelbeheersing/${vak}`, { parameters }),
  getLeerdoelbeheersingLeerjaar: async (vak, leerjaar, parameters) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerjaren/${leerjaar}/leerdoelbeheersing/${vak}`, { parameters })
}
