import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  getSchoolwegingen: async (search, orderBy, start, limit) =>
    httpClient.get('/schoolwegingen', { parameters: { search, orderBy, start, limit } }),
  getSchoolwegingenInJaar: async (search, orderBy, start, limit, jaar) =>
    httpClient.get(`/schoolwegingen/${jaar}`, { parameters: { search, orderBy, start, limit } }),
  getSchoolweging: async (brin) =>
    httpClient.getCached(`/schoolwegingen/scholen/${brin}`, { minutesToCache: 30, cacheTags: ['schoolweging'], toonErrorNotify: false }),
  getSchoolwegingJaar: async (jaar, brin) =>
    httpClient.get(`/schoolwegingen/${jaar}/${brin}`, { toonErrorNotify: false }),
  wijzigSchoolweging: async (jaar, brin, data) =>
    httpClient.put(`/schoolwegingen/${jaar}/${brin}`, { data, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} schoolweging`, invalidateCacheTag: 'schoolweging' }),
  verwijderSchoolweging: async (jaar, brin) =>
    httpClient.delete(`/schoolwegingen/${jaar}/${brin}`, { errorText: `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} schoolweging`, invalidateCacheTag: 'schoolweging' }),
  registreerSchoolweging: async (jaar, brin, data) =>
    httpClient.put(`/schoolwegingen/${jaar}/${brin}`, { data, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} schoolweging`, invalidateCacheTag: 'schoolweging' }),
  importeerSchoolwegingen: async data =>
    httpClient.postJson('/schoolwegingen/bestand', { data, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} schoolwegingen`, invalidateCacheTag: 'schoolweging' })
}
