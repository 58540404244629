import HttpClient from '@/api/HttpClient';
import { useScholenStore } from '@/stores/scholen';
import _ from 'lodash';
import { SubDomein } from '@/views/Schoolbeheer/Instellingen/Models/SubDomein';

export class LeerlijnService {
  public async getLeerlijnDoelen12(vak: String): Promise<IDomein[]> {
    let domeinen: IDomein[] = [];

    await HttpClient.get(`/scholen/${await this.getSchoolId()}/groepen12/leerlijnen/${vak}`).then(response => {
      response?.result?.domeinen.forEach((domeinItem: any) => {
        let domein: IDomein = {
          naam: domeinItem.naam,
          rangorde: domeinItem.rangorde,
          isOpen: false,
          subdomeinen: []
        }

        domeinItem.subdomeinen.forEach((subdomeinItem: any) => {
          let subdomein: ISubDomein = {
            id: subdomeinItem.id,
            naam: subdomeinItem.naam,
            rangorde: subdomeinItem.rangorde,
            isOpen: false,
            leerdoelen: [],
            isUitgesloten: subdomeinItem.isUitgesloten
          };

          subdomeinItem.doelen.forEach((doelItem: any) => {
            subdomein.leerdoelen.push({
              id: doelItem.id,
              subDomein: subdomein,
              isUitgesloten: doelItem.isUitgesloten,
              omschrijving: doelItem.omschrijving,
              nummer: doelItem.nummer,
              rangorde: doelItem.rangorde,
              pakket: doelItem.pakket
            });
          });

          domein.subdomeinen.push(subdomein);
        });

        domeinen.push(domein);
      });
    });

    return domeinen;
  }

  public async getDomeinen(vak: string) {
    return  await HttpClient.get(`/scholen/${await this.getSchoolId()}/groepen12/leerlijnen/${vak}/domeinen`);
  }

  public async toggleDoel(enable: boolean, vak: string, leerdoel: ILeerdoel) {
    if (enable) {
      await HttpClient.put(`/scholen/${await this.getSchoolId()}/groepen12/leerlijnen/${vak}/doelen/${leerdoel.id}`);
    } else {
      await HttpClient.delete(`/scholen/${await this.getSchoolId()}/groepen12/leerlijnen/${vak}/doelen/${leerdoel.id}`);
    }
  }

  public async toggleSubdomein(enable: boolean, vak: string, subdomein: ISubDomein) {
    if (enable) {
      await HttpClient.put(`/scholen/${await this.getSchoolId()}/groepen12/leerlijnen/${vak}/domeinen/${subdomein.id}`);
    } else {
      await HttpClient.delete(`/scholen/${await this.getSchoolId()}/groepen12/leerlijnen/${vak}/domeinen/${subdomein.id}`);
    }
  }

  private async getSchoolId(): Promise<string> {
    return await useScholenStore().getHuidigeSchoolId;
  }
}

export interface IDomein {
  subdomeinen: ISubDomein[];
  isOpen: boolean;
  naam: String;
  rangorde?: Number;
}

export interface ISubDomein {
  id?: string;
  vak?: string;
  naam?: string;
  rangorde?: Number;
  leerdoelen: ILeerdoel[];
  isOpen: boolean;
  isUitgesloten: boolean;
}

export interface ILeerdoel {
  id?: string;
  subDomein?: ISubDomein;
  isUitgesloten: Boolean;
  omschrijving?: string;
  nummer?: string;
  rangorde?: Number;
  pakket?: string;
}
