<template>
<article class="page-container account-layout">
  <header>
    <div class="dark-blue-gradient" />
    <div class="logo">
      <router-link to="/"> <img src="../assets/img/logo-wit2x.png" alt="" /></router-link>
    </div>
  </header>

  <div class="slot-container ">
      <slot />
  </div>

  <footer class="dark-blue-gradient">
    <!--<div class="footer-copyright-wrapper">
      <span class="footer-copyright-text-grijs"> ©2020 Focus Onderwijs BV | <a href="http://www.focuspo.nl" target="_blank">Focus PO website</a> | <a href="http://www.getthere.nl" target="_blank">Powered by Get There</a></span>
    </div>-->
  </footer>
</article>
</template>

<script>
export default {
}
</script>

<style scoped>
  .page-container {
    background: rgb(12,88,255);
    background: linear-gradient(90deg, rgba(12,88,255,1) 0%, rgba(165,190,243,1) 100%);
  }
  header {
    height: 250px;
    background: rgb(9,74,255);
    background: linear-gradient(90deg, rgba(9,74,255,1) 0%, rgba(101,143,255,1) 100%);
  }
</style>
