<template>
  <article class="page-container light-layout">
    <header :class="{ 'demo': inDemoModus }">
      <div class="blue-gray-gradient">
        <leerkracht-menu :actieveLink="actieveLink"></leerkracht-menu>
        <slot name="nav-slot"></slot>
      </div>
      <div v-if="hasHeaderSlot" class="header-container">
        <slot name="header-slot"></slot>
      </div>
    </header>
    <div class="sub-header-container">
      <div class="sub-header-body full-height flex stap-header o-visible ">
        <slot name="sub-header-slot"></slot>
      </div>
    </div>

    <div class="slot-container">
        <slot name="body-slot" ></slot>
    </div>

    <footer v-if="hasFooterSlot">
      <slot name="footer-slot"></slot>
    </footer>
  </article>
</template>

<script>

export default {
  props: {
    actieveLink: String
  },
  async created () {
    if (!this.$gt.stores.account.isAuthenticated) {
      this.$router.push('/')
    }
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header-slot']
    },
    hasFooterSlot () {
      return !!this.$slots['footer-slot']
    },
    inDemoModus () {
      return this.$gt.stores.scholen?.demoSchool?.id
    }
  }
}
</script>

<style scoped>
   header {
    background: transparent linear-gradient(90deg, #85ABFF 0%, #EDF0F2 100%) 0% 0% no-repeat padding-box;
  }
  header.demo {
    background: #8292A4;
  }
  footer {
    height: 150px;
    background: linear-gradient(90deg, rgba(240,244,255,1) 0%, rgba(240,244,255,1) 50%, rgba(240,244,255,1) 100%);
  }
  h2 {
    color: black;
  }
  .sub-header-container {
    width: 100%;
    height: 90px;
    background-color: #FCFAE5;
  }
  .sub-header-body {
    max-width: 1280px;
    margin: auto;
    align-items: center;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.1s;
    transition-property: opacity;
    transition-timing-function: linear;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
</style>
