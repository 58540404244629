import axios from 'axios'
import { defineStore } from 'pinia'
import { useParamsGroep12Store } from '@/stores/paramsGroep12'
import { useParamsGroep38Store } from '@/stores/paramsGroep38'
import { useParamsLeerlingStore } from '@/stores/paramsLeerling'
import { useScholenStore } from '@/stores/scholen'
import { useBesturenStore } from '@/stores/besturen'
import { useCacheStore } from '@/stores/cache'
import { useDownloadsStore } from '@/stores/downloads'
import accountApi from '@/api/Account'
import router from '../router'

// tijd tussen token refreshes. Hoeft niet te vaak, moet wel vaak genoeg. 
const TIME_BETWEEN_REFRESHES_MS = 45000
let logoutTimeout = null
let refreshTokenTimout = null

export const useAccountStore = defineStore('Account', {
  state: () => {
    return {
      token: null,
      authVersion: 0,
      tokenExpiration: 0,
      status: '',
      tfaEnabled: false,
      ingelogdeGebruiker: null,
      refreshTokenNow: false
    }
  },
  getters: {
    isAuthenticated: (state) => {
      const epochNow = new Date().getTime()
      // controleren of token bestaat en de expiration in de toekomst ligt. Ook nog een check of de auth versie ten tijde van inloggen (die dus hier in de store staat) overeenkomt met de huidige van de applicatie
      return !!state.token && state.tokenExpiration > epochNow && import.meta.env.VITE_AUTH_VERSION === state.authVersion
    }
  },
  actions: {
    login (user, provider) {
      return new Promise((resolve, reject) => {
        this.auth_request()
        provider.login(user)
          .then(response => {
            if (response.result.token) {
              // gooi alle stores (nogmaals) leeg, zodat er geen data of cache van andere gebruikers overblijft
              this.resetStores()
              const userToken = response.result.token
              // token in de authorization header zetten voor elke call
              axios.defaults.headers.common.authorization = `Bearer ${userToken}`
              this.SetTfaEnabled(response.result.tfaEnabled)
              this.auth_success(userToken)
            }
            resolve(response)
          })
          .catch(error => {
            this.auth_error(error)
            delete axios.defaults.headers.common.authorization
            reject(error)
          })
      })
    },
    logout () {
      return new Promise((resolve, reject) => {
        this.auth_logout()
        // autorisatie header leeggooien zodat er geen spoor van een andere gebruiker meer is
        delete axios.defaults.headers.common.authorization
        this.resetStores()
        clearTimeout(logoutTimeout)
        router.push({ name: 'login' })
        resolve()
      })
    },
    getGebruiker (provider) {
      return new Promise((resolve, reject) => {
        provider.getGebruiker()
          .then(response => {
            this.ingelogde_gebruiker(response.result)
            useScholenStore().setSchoolId(response.result.scholen[0])
            resolve(response)
          })
          .catch(error => {
            this.auth_error(error)
            this.logout()
            reject(error)
          })
      })
    },
    async refreshToken (forceRefresh = false, provider = null) {
      if (this.refreshTokenNow || forceRefresh) {
        this.refreshTokenNow = false

        if (this.tokenExpiration < new Date().getTime() || !this.token) {
          this.logout()
          router.push('/')
        }
        axios.defaults.headers.common.authorization = `Bearer ${this.token}`
        return new Promise((resolve, reject) => {
          (provider ?? accountApi).refreshToken()
            .then(response => {
              const userToken = response.result.token
              // autorisatie header leeggooien zodat er geen spoor van een andere gebruiker meer is
              delete axios.defaults.headers.common.authorization
              // token in de authorization header zetten voor elke call
              axios.defaults.headers.common.authorization = `Bearer ${userToken}`
              this.auth_success(userToken)
              resolve()
            })
            .catch(error => {
              reject(error)
            })
        })
      }
    },
    setAkkoordZendesk (akkoord) {
      this.ingelogdeGebruiker.akkoordZendesk = akkoord
    },
    resetStores () {
      useParamsGroep12Store().reset()
      useParamsGroep38Store().reset()
      useParamsLeerlingStore().reset()
      useScholenStore().reset()
      useBesturenStore().reset()
      useDownloadsStore().reset()
      useCacheStore().reset()
    },
    SetTfaEnabled (enabled) {
      this.tfaEnabled = enabled
    },
    auth_request () {
      this.status = 'loading'
    },
    auth_success (userToken) {
      this.status = 'success'
      this.token = userToken
      // token expiration uit de token halen. Dit hebben we nodig om de gebruiker uit te loggen zodra deze is verlopen
      this.tokenExpiration = getTokenExpirationMS(userToken)
      // AuthVersion kan gebruikt worden om na een release alle gebruikers uit te loggen, bijvoorbeeld als er iets in de authenticatie flow is gewijzigd
      this.authVersion = import.meta.env.VITE_AUTH_VERSION

      this.resetLogoutTimer()
      this.resetRefreshTimer()
    },
    auth_error (error) {
      this.status = 'error: ' + error
    },
    // alle properties weer terug naar de default waarden 
    auth_logout () {
      this.token = null
      this.tokenExpiration = 0
      this.status = ''
      this.ingelogdeGebruiker = null
      this.tfaEnabled = false
      this.authVersion = 0
      this.refreshTokenNow = false
      this.refreshTokenTimout = null
      this.logoutTimeout = null
    },
    ingelogde_gebruiker (gebruiker) {
      this.ingelogdeGebruiker = gebruiker
    },
    // deze timer logt de gebruiker uit zodra de token expiration is verstreken. Moet opnieuw worden ingesteld na het refreshen van de token
    resetLogoutTimer () {
      clearTimeout(logoutTimeout)
      const timeout = this.tokenExpiration - new Date().getTime()
      logoutTimeout = setTimeout(() => {
        this.auth_logout()
        router.push('/')
      }, timeout)
    },
    // deze timer zet een boolean die ervoor zorgt dat bij de volgende call de/het/een token wordt refreshed. 
    resetRefreshTimer () {
      clearTimeout(refreshTokenTimout)
      refreshTokenTimout = setTimeout(() => {
        this.refreshTokenNow = true
      }, TIME_BETWEEN_REFRESHES_MS)
    }
  },
  persist: true
})

function getTokenExpirationMS (token) {
  if (!token) {
    return 0
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return (JSON.parse(jsonPayload).exp || 0) * 1000
}
