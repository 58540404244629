import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  getToetsseries: async (search, orderBy, start, limit) =>
    httpClient.call('/toetsseries', { method: 'get', parameters: { search, orderBy, start, limit } }, true),
  getCitoToetsserie: async id =>
    httpClient.call(`/toetsseries/cito/${id}`, { method: 'get' }, true, `${errors.FOUT_OPGETREDEN_OPHALEN_DE} toetsserie`),
  getCitoToets: async (id, toetsmoment) =>
    httpClient.call(`/toetsseries/cito/${id}/${toetsmoment}`, { method: 'get' }, true, `${errors.FOUT_OPGETREDEN_OPHALEN_DE} toets`),
  wijzigCitoToetsserie: async (id, data) =>
    httpClient.call(`/toetsseries/cito/${id}`, { method: 'patch', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} toetsserie`),
  registreerCitoToetsserie: async data =>
    httpClient.call('/toetsseries/cito', { method: 'post', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} toetsserie`),
  wijzigCitoToets: async (id, toetsmoment, data) =>
    httpClient.call(`/toetsseries/cito/${id}/${toetsmoment}`, { method: 'put', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} toets`),
  verwijderCitoToets: async (id, toetsmoment) =>
    httpClient.call(`/toetsseries/cito/${id}/${toetsmoment}`, { method: 'delete' }, true, `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} toets`),
  verwijderCitoToetsserie: async (id) =>
    httpClient.call(`/toetsseries/cito/${id}`, { method: 'delete' }, true, `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} toets`, true),
  importeerCitoToetsseries: async data => {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return httpClient.call('/toetsseries/cito/bestand', { method: 'post', data, headers }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} toetsseries`)
  },
  getReferentieniveaus: async id =>
    httpClient.call(`/toetsseries/cito/${id}/referentieniveaus`, { method: 'get' }, true),
  wijzigReferentieniveau: async (id, niveau, data) =>
    httpClient.call(`/toetsseries/cito/${id}/referentieniveaus/${niveau}`, { method: 'put', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} referentieniveaus`),
  verwijderReferentieniveau: async (id, niveau) =>
    httpClient.call(`/toetsseries/cito/${id}/referentieniveaus/${niveau}`, { method: 'delete' }, true, `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} referentieniveaus`),
  registreerDiataalToetsserie: async data =>
    httpClient.call('/toetsseries/diataal', { method: 'post', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} toetsserie`),
  verwijderDiataalToetsserie: async (id) =>
    httpClient.call(`/toetsseries/diataal/${id}`, { method: 'delete' }, true, `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} toets`, true),
  registreerBoomToetsserie: async data =>
    httpClient.call('/toetsseries/boom', { method: 'post', data }, true, `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} toetsserie`),
  verwijderBoomToetsserie: async (id) =>
    httpClient.call(`/toetsseries/boom/${id}`, { method: 'delete' }, true, `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} toets`, true)
}
