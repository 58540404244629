import httpClient from '../HttpClient'

export default {
  getMaandtotalen: async schoolid =>
    httpClient.get(`/scholen/${schoolid}/facturatie/maandtotalen`),
  getTarieven: async =>
    httpClient.get(`/abonnementen/tarieven`),
  downloadKostenoverzicht: async schoolid =>
    httpClient.download(`/scholen/${schoolid}/facturatie/bestand`),
  getMaandtotalenBestuur: async bestuurId =>
    httpClient.get(`/besturen/${bestuurId}/facturatie/maandtotalen`),
  getMaandtotalenBestuurJaarMaand: async (bestuurId, jaar, maand) =>
    httpClient.get(`/besturen/${bestuurId}/facturatie/maandtotalen/${jaar}/${maand}`),
  downloadKostenoverzichtBestuur: async bestuurId =>
    httpClient.download(`/besturen/${bestuurId}/facturatie/bestand`),
  corrigeerSchoolTelling: async (schoolId, jaar, maand, data) =>
    httpClient.putJson(`/scholen/${schoolId}/facturatie/maandtotalen/${jaar}/${maand}/correctie`, { data }),
  deleteCorrigeerSchoolTelling: async (schoolId, jaar, maand, data) =>
    httpClient.delete(`/scholen/${schoolId}/facturatie/maandtotalen/${jaar}/${maand}/correctie`)
}
