
import Focus from '../api/focus'
import { useScholenStore } from '@/stores/scholen'
import { useAccountStore } from '@/stores/account'
import { features } from '../constants/features'
import { rollen } from 'constants/rollen'

const api = new Focus({ config: { basePath: import.meta.env.VITE_API_ENDPOINT } })

export const ifAuthenticated = async (to, from, next, feature, redirect = false) => {
  if (feature && !isAuthenticated()) {
    await FetchGebruiker()
  }
  if (isAuthenticated() && hasFeature(feature)) {
    // token refreshen zodat de gebruiker niet onbedoeld wordt uitgelogd wegens inactiviteit
    useAccountStore().refreshToken()
    next()
  } else if (isAuthenticated() && !hasFeature(feature)) {
    next(from.fullPath)
  } else {
    useAccountStore().logout()
    if (redirect) {
      next({ name: 'login', query: { redirectTo: to.name } })
    } else {
      next({ name: 'login' })
    }
  }
}

export const ifAuthenticatedRole = async (to, from, next, role) => {
  if (role && !isAuthenticated()) {
    await FetchGebruiker()
  }
  if (isAuthenticated() && hasRole(role)) {
    // token refreshen zodat de gebruiker niet onbedoeld wordt uitgelogd wegens inactiviteit
    useAccountStore().refreshToken()
    next()
  } else if (isAuthenticated() && !hasRole(role)) {
    next(from.fullPath)
  } else {
    useAccountStore().logout()
    next('/')
  }
}

export const ifNotAuthenticated = async (to, from, next) => {
  if (!isAuthenticated()) {
    next()
  } else {
    await FetchGebruiker()
    await FetchSchool()
    if (hasFeature(features.SysteembeheerScholen)) {
      next('/scholen')
    } else if (hasFeature(features.SysteembeheerToetsdata)) {
      next('/toetsseries')
    } else if (!isRegistratieAfgerond()) {
      next('/schoolbeheer/abonnenement/afronden')
    } else if (hasRole(rollen.BESTUURDER)) {
      next({ name: 'meta' })
    } else if (!hasFeature(features.Opbrengsten) && !hasFeature(features.Leerdoelen12) && !hasFeature(features.Leerdoelen38)) {
      next('/schoolbeheer/gegevens')
    } else {
      next('/welkom')
    }
  }
}

export const ifRegistratieAfgerond = async (to, from, next) => {
  if (isAuthenticated() && await isRegistratieAfgerond()) {
    next()
    return
  }
  next('/schoolbeheer/abonnenement/afronden')
}

function isAuthenticated () {
  const accStore = useAccountStore()
  const epochNow = new Date().getTime()
  return !!accStore.token && accStore.tokenExpiration > epochNow && import.meta.env.VITE_AUTH_VERSION === accStore.authVersion
}

function hasFeature (feature) {
  if (Array.isArray(feature)) {
    return useAccountStore().ingelogdeGebruiker.features.some(s => feature.includes(s))
  } else if (feature) {
    return useAccountStore().ingelogdeGebruiker.features.includes(feature)
  } else {
    return true
  }
}

function hasRole (role) {
  if (Array.isArray(role)) {
    return useAccountStore().ingelogdeGebruiker.rollen.some(s => role.includes(s))
  } else if (role) {
    return useAccountStore().ingelogdeGebruiker.rollen.includes(role)
  } else {
    return true
  }
}

function isRegistratieAfgerond () {
  const scholenStore = useScholenStore()
  return scholenStore.getHuidigeSchool?.registratieIsCompleet
}

async function FetchGebruiker () {
  await useAccountStore().getGebruiker(api.account)
}

async function FetchSchool () {
  const scholenStore = useScholenStore()
  if (!scholenStore.getHuidigeSchool && useAccountStore().ingelogdeGebruiker?.scholen?.length > 0) {
    await scholenStore.setSchool(api.scholen, useAccountStore().ingelogdeGebruiker.scholen[0])
  }
}
