import httpClient from '../HttpClient'

export default {
  getGroep: async (schoolId, groepId) =>
    httpClient.getCached(`/scholen/${schoolId}/groepen/${groepId}`, { minutesToCache: 15, cacheTags: ['groepen38', 'doelgroepen'] }),
  getGroepen: async (schoolId) =>
    httpClient.getCached(`/scholen/${schoolId}/groepen`, { minutesToCache: 15, cacheTags: ['groepen38', 'doelgroepen'] }),
  getLeerlingenInGroep: async (schoolId, groepId) =>
    httpClient.get(`/scholen/${schoolId}/groepen/${groepId}/leerlingen`, ),
  setUitstroomProfiel: async (schoolId, leerling, profiel) =>
    httpClient.putJson(`/scholen/${schoolId}/leerlingen/${leerling}/uitstroomprofiel`, { data:  profiel, invalidateCacheTag: 'leervaardigheid' }),
  getGroepdoelenZonderNiveau: async (schoolId, groepId, vak, parameters) =>
    httpClient.get(`/scholen/${schoolId}/groepen/${groepId}/doelen/${vak}`, { parameters }),
  getLeerjaardoelenZonderNiveau: async (schoolId, leerjaar, vak, parameters) =>
    httpClient.get(`/scholen/${schoolId}/leerjaren/${leerjaar}/doelen/${vak}`, { parameters }),
  getGroepdoelen: async (schoolId, groepId, vak, niveau, parameters) =>
    httpClient.get(`/scholen/${schoolId}/groepen/${groepId}/doelen/${vak}/${niveau}`, { parameters }),
  getLeerjaardoelen: async (schoolId, leerjaar, vak, niveau, parameters) =>
    httpClient.get(`/scholen/${schoolId}/leerjaren/${leerjaar}/doelen/${vak}/${niveau}`, { parameters }),
  wijzigLeerlingNiveaugroep: async (schoolId, groepId, leerlingId, vak, data) =>
    httpClient.putJson(`/scholen/${schoolId}/groepen/${groepId}/leerlingen/${leerlingId}/niveaugroepen/${vak}`, { data, invalidateCacheTag: 'leervaardigheid' }),
  verwijderLeerlingNiveaugroep: async (schoolId, groepId, leerlingId, vak) =>
    httpClient.delete(`/scholen/${schoolId}/groepen/${groepId}/leerlingen/${leerlingId}/niveaugroepen/${vak}`, { invalidateCacheTag: 'leervaardigheid' }),
  wijzigLeerlingLeervaardigheid: async (schoolId, groepId, leerlingId, data) =>
    httpClient.putJson(`/scholen/${schoolId}/groepen/${groepId}/leerlingen/${leerlingId}/leervaardigheid`, { data, invalidateCacheTag: 'leervaardigheid' }),
  wijzigLeerlingVakNiveau: async (schoolId, groepId, leerlingId, vak, data) =>
    httpClient.putJson(`/scholen/${schoolId}/groepen/${groepId}/leerlingen/${leerlingId}/leerlijnen/${vak}`, { data, invalidateCacheTag: 'leervaardigheid' }),
  verwijderLeerlingVakNiveau: async (schoolId, groepId, leerlingId, vak) =>
    httpClient.delete(`/scholen/${schoolId}/groepen/${groepId}/leerlingen/${leerlingId}/leerlijnen/${vak}`, { invalidateCacheTag: 'leervaardigheid' }),
  setDoorstroomToets: async (schoolId, leerlingId, uitStroomToets) => 
    httpClient.putJson(`/scholen/${schoolId}/leerlingen/${leerlingId}/doorstroomtoets`, { data: uitStroomToets })
}
