
import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [{
  path: '/groep/periodeoverzicht/plannen/:id',
  name: 'cruciale-leerdoelen-plannen',
  props: true,
  component: () => import('views/Groep38/PlanningMaken.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen38) }
},
{
  path: '/groep/periodeoverzicht/actualiseren/:id',
  name: 'cruciale-leerdoelen-actualiseren',
  props: true,
  component: () => import('views/Groep38/PlanningActualiseren.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen38) }
},
{
  path: '/groep/periodeoverzicht/concept/:id',
  name: 'cruciale-leerdoelen-concept',
  props: true,
  component: () => import('views/Groep38/PlanningConcept.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen38) }
},
{
  path: '/groep/periodeoverzicht/beheersing/:pniveau?/:pvak?/:pleerjaar?/:pgroepId?/:pperiodeplanId?/:ptype?',
  name: 'cruciale-leerdoelen-beheersing',
  props: true,
  component: () => import('views/Groep38/LeerdoelenDossier.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen38) }
},
{
  path: '/groep/burgerschap/:pleerjaar?/:pgroepId?',
  name: 'sel-burgerschap',
  props: true,
  component: () => import('views/Groep38/BurgerschatDossier.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen38) }
},
{
  path: '/groep/periodeoverzicht/bekijk-exporteer-print/:id',
  name: 'bekijk-exporteer-print',
  props: true,
  component: () => import('views/Groep38/BekijkExporteerPrint.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen38) }
},
/* {
  path: '/groep/leerroute',
  name: 'leerroute-index',
  component: () => import('views/Groep38/ResponseLeerroute.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen38) }
}, */
{
  path: '/groep/periodeoverzicht',
  name: 'cruciale-leerdoelen',
  component: () => import('views/Groep38/PeriodeOverzicht.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.Leerdoelen38) }
},
{
  path: '/groep/cruciale-leerdoelen',
  redirect: '/groep/periodeoverzicht',
  redirectParams: {
    responseCode: 301
  }
}]
