import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  getLeerkrachten: async schoolId =>
    httpClient.get(`/scholen/${schoolId}/leerkrachten`),
  getLeerkracht: async (schoolId, leerkrachtId) =>
    httpClient.get(`/scholen/${schoolId}/leerkrachten/${leerkrachtId}`),
  wijzigLeerkracht: async (schoolId, leerkrachtId, data) =>
    httpClient.patch(`/scholen/${schoolId}/leerkrachten/${leerkrachtId}`, { data, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} leerkracht`, toonServerResponse: true }),
  registreerLeerkracht: async (schoolId, data) =>
    httpClient.post(`/scholen/${schoolId}/leerkrachten`, { data, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} leerkracht`, toonServerResponse: true }),
  verwijderLeerkracht: async (schoolId, leerkrachtId) =>
    httpClient.delete(`/scholen/${schoolId}/leerkrachten/${leerkrachtId}`, { errorText: `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} leerkracht`, toonServerResponse: true })
}
