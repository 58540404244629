import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  getNieuwstegels: async () =>
    httpClient.getCached('/content/nieuwstegels', { minutesToCache: 60, cacheTags: ['nieuwstegels'], toonErrorNotify: false }),
  getNieuwstegel: async id =>
    httpClient.get(`/content/nieuwstegels/${id}`),
  wijzigNieuwstegel: async (id, data) =>
    httpClient.patch(`/content/nieuwstegels/${id}`, { data, invalidateCacheTag: 'nieuwstegels', errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} nieuwstegel`, toonServerResponse: true, toonErrorNotify: true }),
  wijzigVolgorde: async data =>
    httpClient.patch('/content/nieuwstegels/volgorde', { data, invalidateCacheTag: 'nieuwstegels', errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} nieuwstegel`, toonServerResponse: true, toonErrorNotify: true }),
  registreerNieuwstegel: async data =>
    httpClient.post('/content/nieuwstegels', { data, invalidateCacheTag: 'nieuwstegels', errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} nieuwstegel`, toonServerResponse: true, toonErrorNotify: true }),
  verwijderNieuwstegel: async id =>
    httpClient.delete(`/content/nieuwstegels/${id}`, { invalidateCacheTag: 'nieuwstegels', errorText: `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} nieuwstegel`, toonServerResponse: true, toonErrorNotify: true }),
  getBlob: async (blobId) =>
    httpClient.get(`/content/bestanden/${blobId}`),
  getSasUri: async (blobId) =>
    httpClient.get(`/content/bestanden/${blobId}/sas`),
  getLinks: async () =>
    httpClient.getCached('/content/links', { minutesToCache: 30, cacheTags: ['links'], toonErrorNotify: false })
}
