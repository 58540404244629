import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

const headers = { 'Content-Type': 'multipart/form-data' }
export default {
  importeerToetsresultatenEsis: async (schoolId, data) => {
    return httpClient.post(`/scholen/${schoolId}/toetsresultaten/esis/bestand`, { data, headers, invalidateCacheTag: 'toetsresultaten', toonErrorNotify: true, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} toetsresultaten` })
  },
  importeerToetsresultatenParnassys: async (schoolId, data) => {
    return httpClient.post(`/scholen/${schoolId}/toetsresultaten/parnassys/bestand`, { data, headers, invalidateCacheTag: 'toetsresultaten', toonErrorNotify: true, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} toetsresultaten` })
  },
  importeerToetsresultatenDiataal: async (schoolId, data) => {
    return httpClient.post(`/scholen/${schoolId}/toetsresultaten/diataal/bestand`, { data, headers, invalidateCacheTag: 'toetsresultaten', toonErrorNotify: true, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} toetsresultaten` })
  },
  berekenVix: async (schoolId) => {
    return httpClient.options(`/scholen/${schoolId}/vix`)
  }
}
