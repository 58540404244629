
import { ifAuthenticated } from 'helpers/routeHelpers'
import { features } from 'constants/features'

export default [{
  path: '/schoolbeheer/gegevens',
  name: 'mijn-gegevens',
  component: () => import('views/Schoolbeheer/MijnGegevens/MijnGegevens.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.MijnGegevens) }
},
{
  path: '/schoolbeheer/gegevens/wijzig',
  name: 'wijzig-gegevens',
  component: () => import('views/Schoolbeheer/MijnGegevens/WijzigGegevens.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.MijnGegevens) }
},
{
  path: '/schoolbeheer/aanvragen/:abonnement',
  name: 'abonnement-aanvragen',
  props: true,
  component: () => import('views/Schoolbeheer/MijnGegevens/AbonnementAanvragen.vue'),
  beforeEnter: ifAuthenticated
},
{
  path: '/schoolbeheer/abonnenement/opzeggen',
  name: 'abonnement-opzeggen',
  component: () => import('views/Schoolbeheer/MijnGegevens/AbonnementOpzeggen.vue'),
  beforeEnter (to, from, next) { ifAuthenticated(to, from, next, features.MijnGegevens) }
},
{
  path: '/schoolbeheer/abonnenement/afronden',
  name: 'registratie-afronden',
  component: () => import('views/Schoolbeheer/MijnGegevens/RegistratieAfronden.vue'),
  beforeEnter: ifAuthenticated
},
{
  path: '/schoolbeheer/abonnenement/beeindigd',
  name: 'abonnement-beeindigd',
  component: () => import('views/Schoolbeheer/MijnGegevens/AbonnementBeeindigd.vue')
},
{
  path: '/schoolbeheer/abonnenement/proefabonnement-beeindigd',
  name: 'abonnement-beeindigd-proef',
  component: () => import('views/Schoolbeheer/MijnGegevens/AbonnementBeeindigdProef.vue')
},
{
  path: '/schoolbeheer/abonnenement/aangevraagd',
  name: 'abonnement-aangevraagd',
  component: () => import('views/Schoolbeheer/MijnGegevens/AbonnementAangevraagd.vue'),
  beforeEnter: ifAuthenticated
}]
