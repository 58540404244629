import { ifNotAuthenticated, ifAuthenticated } from 'helpers/routeHelpers'

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('views/Account/LoginView.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/account/wachtwoord-vergeten',
    name: 'wachtwoord-vergeten',
    component: () => import('views/Account/WachtwoordVergetenView.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/account/wachtwoord-wijzigen',
    name: 'wachtwoord-wijzigen',
    component: () => import('views/Account/WachtwoordWijzigenView.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/account/nieuw-wachtwoord',
    name: 'nieuw-wachtwoord',
    component: () => import('views/Account/NieuwWachtwoordView.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/account/wachtwoord-instellen/',
    alias: '/#/account/wachtwoord-instellen/',
    name: 'wachtwoord-instellen',
    component: () => import('views/Account/WachtwoordInstellen.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/account/wachtwoord-ingesteld/',
    name: 'wachtwoord-ingesteld',
    component: () => import('views/Account/WachtwoordIngesteld.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/account/registreer',
    name: 'account-registreren',
    component: () => import('views/Account/registratie/RegistreerView.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/account/registratie-gelukt',
    name: 'registratie-gelukt',
    component: () => import('views/Account/registratie/RegistrerenGelukt.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/account/2fa',
    name: 'account-2fa',
    props: true,
    component: () => import('views/Account/Tfa/TfaStart.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/account/2fa/instellen',
    name: 'account-tfa-instellen',
    props: true,
    component: () => import('views/Account/Tfa/TfaInstellen.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/account/2fa/ingesteld',
    name: 'account-tfa-ingesteld',
    props: true,
    component: () => import('views/Account/Tfa/TfaIngesteld.vue'),
    beforeEnter: ifAuthenticated
  }
]
