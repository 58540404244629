import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'
import { useScholenStore } from '@/stores/scholen'

export default {
  getDoelgroepen: async () =>
    httpClient.getCached(`/scholen/${await useScholenStore().getHuidigeSchoolId}/doelgroepen`, { minutesToCache: 15, cacheKey: `doelgroepen-${await useScholenStore().getHuidigeSchoolId}`, cacheTags: ['doelgroepen', 'toetsresultaten'] }),
  activeerDoelgroepen: async (schoolId) =>
    httpClient.put(`/scholen/${schoolId}/doelgroepen/actief`, { invalidateCacheKey: `doelgroepen-${await useScholenStore().getHuidigeSchoolId}` }),
  deactiveerDoelgroepen: async (schoolId) =>
    httpClient.delete(`/scholen/${schoolId}/doelgroepen/actief`, { invalidateCacheKey: `doelgroepen-${await useScholenStore().getHuidigeSchoolId}` }),
  registreerDoelgroep: async (schoolId, data) =>
    httpClient.post(`/scholen/${schoolId}/doelgroepen`, { data, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} doelgroep`, toonServerResponse: true, invalidateCacheKey: `doelgroepen-${await useScholenStore().getHuidigeSchoolId}` }),
  wijzigDoelgroep: async (schoolId, doelgroepId, data) =>
    httpClient.patchJson(`/scholen/${schoolId}/doelgroepen/${doelgroepId}`, { data, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} doelgroep`, invalidateCacheKey: `doelgroepen-${await useScholenStore().getHuidigeSchoolId}` }),
  plaatsGroepInDoelgroep: async (schoolId, doelgroepId, data) =>
    httpClient.patchJson(`/scholen/${schoolId}/doelgroepen/${doelgroepId}/groepen`, { data, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} doelgroep`, invalidateCacheKey: `doelgroepen-${await useScholenStore().getHuidigeSchoolId}` }),
  verwijderDoelgroep: async (schoolId, doelgroepNaam) =>
    httpClient.delete(`/scholen/${schoolId}/doelgroepen/${doelgroepNaam}`, { errorText: `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} doelgroep`, invalidateCacheKey: `doelgroepen-${await useScholenStore().getHuidigeSchoolId}` })
}
