import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  getInstellingen: async () =>
    httpClient.getCached('/instellingen', { minutesToCache: 15, cacheTags: ['instellingen'] }),
  wijzigInstellingen: async (id, data) => {
    return httpClient.patchJson(`/instellingen/${id}`, { data, invalidateCacheTag: 'instellingen', errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} instellingen` })
  }
}
