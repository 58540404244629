import HttpClient from '@/api/HttpClient';

import { useScholenStore } from '@/stores/scholen';

export class RapportService {
    async saveFotoblad(rapportId: string, leerlingId: string, titel: string, kleur: string, positie: string, image: FileToUpload) {
        const data = {
            titel: titel,
            tekstkleur: kleur,
            uitlijning: positie,
        };

        await HttpClient.patchJson(`/scholen/${await this.getSchoolId()}/leerlingen/${leerlingId}/rapporten/${rapportId}/velden/fotoblad`, { data } as any).then(async () => {
            if (image?.blob) {
                const formData = new FormData();
                formData.append('name', titel);
                formData.append('file', image.blob);

                await HttpClient.put(`/scholen/${await this.getSchoolId()}/leerlingen/${leerlingId}/rapporten/${rapportId}/velden/fotoblad/afbeelding`, {
                    data: formData,
                    headers: { 'Content-Type': image.contentType },
                } as any).then(() => {
                    image.blob = null;
                });
            }
        });
    }

    private async getSchoolId(): Promise<string> {
        return await useScholenStore().getHuidigeSchoolId;
    }
}

export interface FileToUpload {
    blob?: Blob | null;
    content: String;
    contentType: String; 
}
