<template>
  <div class="sub-menu">
    <router-link :to="{ name:'welkom' }">home</router-link>
    <span> ></span>

    <!--Mijn Gegevens-->
    <router-link v-if="parent === 'mijn-gegevens'" :to="{ name: 'mijn-gegevens' }">Mijn gegevens</router-link>
    <span v-if="parent === 'mijn-gegevens'"> ></span>

    <span v-if="activeLink === 'mijn-gegevens'">Mijn gegevens</span>
    <span v-if="activeLink === 'abonnement-aangevraagd'">Abonnement aangevraagd</span>
    <span v-if="activeLink === 'abonnement-aanvragen'">Abonnement aanvragen</span>
    <span v-if="activeLink === 'abonnement-beeindigd'">Abonnement beëindigd</span>
    <span v-if="activeLink === 'abonnement-opzeggen'">Abonnement opzeggen</span>
    <span v-if="activeLink === 'registratie-afronden'">Registratie afronden</span>
    <span v-if="activeLink === 'wijzig-gegevens'">Gegevens wijzigen</span>

    <!--Import-->
    <router-link v-if="parent === 'import'" :to="{ name: 'leerlinggegevens-importeren' }">Import</router-link>
    <span v-if="parent === 'toegang'"> ></span>

    <span v-if="activeLink === 'import'">Import</span>
    <span v-if="activeLink === 'importeren-succes'">Gegevens geïmporteerd</span>

    <!-- Instellingen -->
    <router-link v-if="parent === 'instellingen' || parent === 'instellingen-doelgroep' || parent === 'instellingen-doelgroep-wijzigen' || parent === 'instellingen-rapportages' || parent === 'instellingen-rapportages-wijzigen'" :to="{ name: 'start1234' }">Instellingen</router-link>
    <span v-if="parent === 'instellingen' || parent === 'instellingen-doelgroep' || parent === 'instellingen-doelgroep-wijzigen' || parent === 'instellingen-rapportages' || parent === 'instellingen-rapportages-wijzigen'"> ></span>

    <span v-if="activeLink === 'instellingen'">Instellingen</span>

    <router-link v-if="parent === 'instellingen-doelgroep' || parent === 'instellingen-doelgroep-wijzigen'" :to="{ name: 'start1234-doelgroepen' }">Doelgroep</router-link>
    <span v-if="parent === 'instellingen-doelgroep' || parent === 'instellingen-doelgroep-wijzigen'"> ></span>

    <router-link v-if="parent === 'instellingen-doelgroep-wijzigen'" :to="{ name: 'start1234-doelgroepen-wijzigen' }">Doelgroepen wijzigen</router-link>
    <span v-if="parent === 'instellingen-doelgroep-wijzigen'"> ></span>

    <span v-if="activeLink === 'instellingen-doelgroep'">Doelgroep</span>
    <span v-if="activeLink === 'instellingen-doelgroep-wijzigen'">Doelgroep wijzigen</span>
    <span v-if="activeLink === 'instellingen-doelgroep-naam-wijzigen'">Doelgroep naam wijzigen</span>

    <span v-if="activeLink === 'instellingen-schoolambities'">Schoolambities</span>
    <span v-if="activeLink === 'instellingen-schoolaanbod'">Schoolaanbod</span>
    <span v-if="activeLink === 'instellingen-lesmethodes'">Lesmethodes</span>
    <span v-if="activeLink === 'instellingen-schoolleerlijnen'">Schoolleerlijnen</span>
    <span v-if="activeLink === 'instellingen-onderwijsplannen'">Onderwijsplannen</span>
    <span v-if="activeLink === 'instellingen-jaarplanning'">Jaarplanning</span>

    <router-link v-if="parent === 'instellingen-rapportages' || parent === 'instellingen-rapportages-wijzigen'" :to="{ name: 'start1234-rapportages' }">Leerlingrapporten</router-link>
    <span v-if="parent === 'instellingen-rapportages' || parent === 'instellingen-rapportages-wijzigen'"> ></span>

    <router-link v-if="parent === 'instellingen-rapportages-wijzigen'" :to="{ name: 'start1234-rapportages-wijzigen', params: { id: templateId } }">Rapport bewerken</router-link>
    <span v-if="parent === 'instellingen-rapportages-wijzigen'"> ></span>

    <span v-if="activeLink === 'instellingen-rapportages'">Leerlingrapporten</span>
    <span v-if="activeLink === 'instellingen-rapportages-wijzigen'">Rapport bewerken</span>
    <span v-if="activeLink === 'instellingen-rapportages-ontwikkelingsgebieden-wijzigen'">Ontwikkelingsgebied bewerken</span>

    <!--Toegang-->
    <router-link v-if="parent === 'toegang'" :to="{ name: 'leerkrachten-overzicht' }">Toegang</router-link>
    <span v-if="parent === 'toegang'"> ></span>

    <span v-if="activeLink === 'toegang'">Toegang</span>
    <span v-if="activeLink === 'overzicht-persoonsgegevens'">Overzicht persoonsgegevens</span>
    <span v-if="activeLink === 'gebruiker-toevoegen'">Gebruiker toevoegen</span>
    <span v-if="activeLink === 'gebruiker-wijzigen'">Wijzigen persoonsgegevens</span>

    <!--Leerling-->
    <span v-if="activeLink === 'leerling'">Leerling</span>

    <!--Monitor-->
    <span v-if="activeLink === 'monitor'">Monitor</span>
  </div>
</template>

<script>
export default {
  name: 'leerling-submenu',
  props: {
    activeLink: String,
    parent: String,
    templateId: String
  }
}
</script>
