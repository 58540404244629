import axios from 'axios'
import { Notify } from 'quasar'
import cloneDeep from 'clone-deep'
import { useCacheStore } from '@/stores/cache'
import { useAccountStore } from '@/stores/account'

const headers = { 'Content-Type': 'application/json' }
export default {
  config: {},
  defaultErrorText: 'Er is iets misgegaan bij het verwerken van je verzoek. Probeer het later nog eens.',
  async get (path, { data, parameters = {}, rawParameters = {}, headers = {}, errorText, toonErrorNotify = true, toonServerResponse = false, withCredentials = false } = {}) {
    return await this.call(path, { method: 'get', data, parameters, rawParameters, headers }, toonErrorNotify, errorText, toonServerResponse, withCredentials)
  },
  // cacheKey: unieke sleutel om de cache op te slaan en te halen. ALs deze niet wordt meegegeven wordt de path gebruikt. De cache is NIET per school, dus zorg dat de schoolId er ook in staat!
  // cacheTags: soms heb je meerdere 'subcaches', denk aan de losse groepen met elk een unieke cacheKey. Als ze wel dezelfde tag hebben kan je ze alsnog makkelijk in 1x verwijderen.
  // minutesToCache: hoe lang de items in de cache geldig blijven.
  // forceRefresh: Eventuele cache wordt niet gebruikt en de data dus altijd opnieuw opgehaald. De cache wordt wel gevuld.
  async getCached (path, { data, parameters = {}, rawParameters = {}, headers = {}, errorText, toonErrorNotify = true, toonServerResponse = false, withCredentials = false, cacheKey = null, cacheTags = [], minutesToCache = 15, forceRefresh = false } = {}) {
    return new Promise((resolve) => {
      // Gecachte (is dat het juiste woord?) item proberen op te halen
      cacheKey = cacheKey || path.concat(JSON.stringify(parameters)).concat(JSON.stringify(rawParameters))
      const cachedResponse = useCacheStore().tryGetCache(cacheKey)

      // Als er geen cache is (of verlopen) doen we dezelfde call als bij get() en cachen we de response
      if (!cachedResponse || forceRefresh) {
        const apiResponse = this.call(path, { method: 'get', data, parameters, rawParameters, headers }, toonErrorNotify, errorText, toonServerResponse, withCredentials)

        apiResponse
          .then(response => {
            // Deep clone zodat de originele response niet bewerkt kan worden. Dat geeft errors en ellende.
            const clonedResponse = cloneDeep(response)
            // minutesToCache = 0 // TEST!! HAAL WEG
            useCacheStore().addToCache(clonedResponse, (cacheKey), minutesToCache, cacheTags)
          })
          .finally(() => {
            // Originele response terugsturen
            resolve(apiResponse)
          })
      } else {
        // token refreshen zodat de gebruiker niet onbedoeld wordt uitgelogd wegens inactiviteit
        useAccountStore().refreshToken()
        // Response uit cache terugsturen
        resolve(cachedResponse)
      }
    })
  },
  async post (path, { data, parameters = {}, headers = {}, errorText, toonErrorNotify = true, toonServerResponse = false, withCredentials = false, invalidateCacheKey, invalidateCacheTag } = {}) {
    this.invalidateCache(invalidateCacheKey, invalidateCacheTag)
    return await this.call(path, { method: 'post', data, parameters, headers }, toonErrorNotify, errorText, toonServerResponse, withCredentials)
  },
  async postJson (path, { data, parameters = {}, errorText, toonErrorNotify = true, toonServerResponse = false, withCredentials = false, invalidateCacheKey, invalidateCacheTag } = {}) {
    this.invalidateCache(invalidateCacheKey, invalidateCacheTag)
    return await this.call(path, { method: 'post', data, parameters, headers }, toonErrorNotify, errorText, toonServerResponse, withCredentials)
  },
  async patch (path, { data, parameters = {}, headers = {}, errorText, toonErrorNotify = true, toonServerResponse = false, withCredentials = false, invalidateCacheKey, invalidateCacheTag } = {}) {
    this.invalidateCache(invalidateCacheKey, invalidateCacheTag)
    return await this.call(path, { method: 'patch', data, parameters, headers }, toonErrorNotify, errorText, toonServerResponse, withCredentials)
  },
  async patchJson (path, { data, parameters = {}, errorText, toonErrorNotify = true, toonServerResponse = false, withCredentials = false, invalidateCacheKey, invalidateCacheTag } = {}) {
    this.invalidateCache(invalidateCacheKey, invalidateCacheTag)
    return await this.call(path, { method: 'patch', data, parameters, headers }, toonErrorNotify, errorText, toonServerResponse, withCredentials)
  },
  async put (path, { data, parameters = {}, headers = {}, errorText, toonErrorNotify = true, toonServerResponse = false, withCredentials = false, invalidateCacheKey, invalidateCacheTag } = {}) {
    this.invalidateCache(invalidateCacheKey, invalidateCacheTag)
    return await this.call(path, { method: 'put', data, parameters, headers }, toonErrorNotify, errorText, toonServerResponse, withCredentials)
  },
  async putJson (path, { data, parameters = {}, errorText, toonErrorNotify = true, toonServerResponse = false, withCredentials = false, invalidateCacheKey, invalidateCacheTag } = {}) {
    this.invalidateCache(invalidateCacheKey, invalidateCacheTag)
    return await this.call(path, { method: 'put', data, parameters, headers }, toonErrorNotify, errorText, toonServerResponse, withCredentials)
  },
  async delete (path, { data, parameters = {}, headers = {}, errorText, toonErrorNotify = true, toonServerResponse = false, withCredentials = false, invalidateCacheKey, invalidateCacheTag } = {}) {
    this.invalidateCache(invalidateCacheKey, invalidateCacheTag)
    return await this.call(path, { method: 'delete', data, parameters, headers }, toonErrorNotify, errorText, toonServerResponse, withCredentials)
  },
  async deleteJson (path, { data, parameters = {}, errorText, toonErrorNotify = true, toonServerResponse = false, withCredentials = false, invalidateCacheKey, invalidateCacheTag } = {}) {
    this.invalidateCache(invalidateCacheKey, invalidateCacheTag)
    return await this.call(path, { method: 'delete', data, parameters, headers }, toonErrorNotify, errorText, toonServerResponse, withCredentials)
  },
  async options (path, { data, parameters = {}, headers = {}, errorText, toonErrorNotify = true, toonServerResponse = false, withCredentials = false } = {}) {
    return await this.call(path, { method: 'options', data, parameters, headers }, toonErrorNotify, errorText, toonServerResponse, withCredentials)
  },
  async call (path, { method = 'get', data, parameters = {}, rawParameters = {}, headers = {} } = {}, toonErrorNotify = false, errorText = '', toonServerResponse = false, withCredentials = false) {
    const queryParameters =
      Object.keys(parameters)
        .map(key => `${key}=${encodeURIComponent(parameters[key])}`)
        .concat(
          Object.keys(rawParameters)
            .map(key => `${key}=${rawParameters[key]}`))
        .join('&')
        .trim()

    const url = queryParameters.length >= 1 ? `${path}/?${queryParameters}` : path
    const response = await axios.request(url, { method, data, headers, withCredentials })
      .catch(async error => {
        errorText = (toonServerResponse && error && error.response && error.response.data && error.response.data.errorMessage && !error.response.data.errorMessage.includes('RequestID')) ? error.response.data.errorMessage : (errorText || this.defaultErrorText)
        if (toonErrorNotify && error.response.status !== 401) {
          Notify.create({
            group: errorText,
            type: 'negative',
            progress: true,
            multiLine: true,
            timeout: 5000,
            message: `Sorry, er ging iets mis \n${errorText}`,
            actions: [{ label: 'Sluiten', color: 'white', handler: () => { } }]
          })
        }
        return Promise.reject(error)
      })
    return response.data
  },
  async download (path, { parameters = {} } = {}) {
    const queryParameters = Object
      .keys(parameters)
      .map(key => `${key}=${encodeURIComponent(parameters[key])}`)
      .join('&')
    const url = queryParameters.length >= 1 ? `${path}/?${queryParameters}` : path
    return await axios({
      url,
      method: 'GET',
      responseType: 'blob'
    })
  },
  invalidateCache (invalidateCacheKey, invalidateCacheTag) {
    if (invalidateCacheKey) {
      useCacheStore().removeByKey(invalidateCacheKey)
    }
    if (invalidateCacheTag) {
      useCacheStore().removeByTag(invalidateCacheTag)
    }
  }
}
