import * as instellingenApi from 'src/api/Scholen/instellingenApi';
import { useScholenStore } from '@/stores/scholen';

export class TemplateService {
    private instellingen: any = undefined;
    private scholenStore = useScholenStore();

    public async getFonts() {
        let instellingen = await this.getInstellingen();
        let fonts = instellingen.filter((x: any) => x.type === 'FONT');

        return fonts;
    }

    public async getKleuren() {
        let instellingen = await this.getInstellingen();
        let fonts = instellingen.filter((x: any) => x.type === 'KLEUR');

        return fonts;
    }

    public async getFont(id: string) {
        return (await this.getFonts()).find((x: any) => x.id === id);
    }
    
    private async getInstellingen() {
        if (this.instellingen === undefined) {
            await instellingenApi.default.getBasisinstellingen(this.getSchoolId()).then((response) => {
                this.instellingen = response.result.instellingen;
            });
        }

        return this.instellingen;
    }

    private getSchoolId() {
        return (this.scholenStore?.huidigeSchool as any).id;
    }
}
