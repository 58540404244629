import httpClient from '../HttpClient'
import { useScholenStore } from '@/stores/scholen'

export default {
  getLogboek: async (leerlingId, doelId) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/doelen/${doelId}/logboek`, { toonErrorNotify: false }),
  getLogboekenVoorGroep: async (groepId) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/groep/${groepId}/logboek`, { toonErrorNotify: false }),
  getLogboekenVoorLeerjaar: async (leerjaar) =>
    httpClient.get(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerjaar/${leerjaar}/logboek`, { toonErrorNotify: false }),
  registreerNotitie: async (leerlingId, doelId, data) =>
    httpClient.postJson(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/doelen/${doelId}/logboek/notities`, { data }),
  verwijderNotitie: async (leerlingId, doelId, notitieId) =>
    httpClient.delete(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/doelen/${doelId}/logboek/notities/${notitieId}`),
  wijzigNotitie: async (leerlingId, doelId, notitieId, data) =>
    httpClient.patchJson(`/scholen/${await useScholenStore().getHuidigeSchoolId}/leerlingen/${leerlingId}/doelen/${doelId}/logboek/notities/${notitieId}`, { data })
}
