
import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  getVerwerkersovereenkomst: async id =>
    httpClient.call(`/besturen/${id}/verwerkersovereenkomst`, { method: 'get', id }),
  getSubVerwerkersovereenkomst: async id =>
    httpClient.call(`/besturen/${id}/subverwerkersovereenkomst`, { method: 'get', id }),
  getBesturen: async (metAbonnement, search, orderBy, start, limit) =>
    httpClient.call('/besturen', { method: 'get', parameters: { metAbonnement, search, orderBy, start, limit } }, true),
  getBestuur: async id =>
    httpClient.getCached(`/besturen/${id}`, { minutesToCache: 15, cacheTags: ['bestuur', 'gebruikers'] }),
  wijzigBestuur: async (id, data) =>
    httpClient.patch(`/besturen/${id}`, { data, toonErrorNotify: true, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_HET} bestuur`, invalidateCacheTag: 'bestuur' }),
  registreerBestuur: async data =>
    httpClient.post('/besturen', { data, toonErrorNotify: true, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_HET} bestuur`, invalidateCacheTag: 'bestuur' }),
  verwijderBestuur: async id =>
    httpClient.delete(`/besturen/${id}`, { errorText: `${errors.FOUT_OPGETREDEN_VERWIJDEREN_HET} bestuur`, toonErrorNotify: true, invalidateCacheTag: 'bestuur' }),

  sluitVerwerkerovereenkomst: async (id, data) =>
    httpClient.putJson(`/besturen/${id}/verwerkersovereenkomst`, { data, toonErrorNotify: true, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} verwerkersovereenkomst`, invalidateCacheTag: 'bestuur' }),
  verwijderVerwerkerovereenkomst: async (id, data) =>
    httpClient.deleteJson(`/besturen/${id}/verwerkersovereenkomst`, { data, toonErrorNotify: true, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} verwerkersovereenkomst`, invalidateCacheTag: 'bestuur' }),
  sluitSubVerwerkerovereenkomst: async (id, data) =>
    httpClient.putJson(`/besturen/${id}/subverwerkersovereenkomst`, { data, toonErrorNotify: true, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} subverwerkersovereenkomst`, invalidateCacheTag: 'bestuur' }),
  verwijderSubVerwerkerovereenkomst: async (id) =>
    httpClient.delete(`/besturen/${id}/subverwerkersovereenkomst`, { toonErrorNotify: true, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} subverwerkersovereenkomst`, invalidateCacheTag: 'bestuur' }),

  importeerBesturen: async data => {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return httpClient.post('/besturen/bestand', { data, headers, toonErrorNotify: true, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_DE} besturen` })
  },
  exporteerBesturen: async () =>
    httpClient.download('/besturen/bestand'),
  registreerAbonnement: async (bestuurId, data) =>
    httpClient.post(`/besturen/${bestuurId}/abonnement`, { data, invalidateCacheTag: 'bestuur' }),
  abonnementOverstappen: async (bestuurId, data) =>
    httpClient.put(`/besturen/${bestuurId}/abonnement/type`, { data, invalidateCacheTag: 'bestuur' }),
  stopAbonnement: async (bestuurId, data) =>
    httpClient.putJson(`/besturen/${bestuurId}/abonnement/status`, { data, errorText: `${errors.FOUT_OPGETREDEN_VERWERKEN_HET} abonnement`, toonServerResponse: true, invalidateCacheTag: 'bestuur' }),
  getScholen: async (bestuurId) =>
    httpClient.getCached(`/besturen/${bestuurId}/scholen`, { minutesToCache: 15, cacheTags: ['school', 'gebruikers'] }),
  getGebruikers: async (bestuurId) =>
    httpClient.getCached(`/besturen/${bestuurId}/gebruikers`, { minutesToCache: 10, cacheTags: ['gebruikers'] }),
  registreerGebruiker: async (bestuurId, data) =>
    httpClient.post(`/besturen/${bestuurId}/bestuurders`, { data, invalidateCacheTag: 'gebruikers' }),
  wijzigGebruiker: async (bestuurId, gebruikerId, data) =>
    httpClient.patch(`/besturen/${bestuurId}/bestuurders/${gebruikerId}`, { data, invalidateCacheTag: 'gebruikers' }),
  verwijderGebruiker: async (bestuurId, gebruikerId) =>
    httpClient.delete(`/besturen/${bestuurId}/bestuurders/${gebruikerId}`, { invalidateCacheTag: 'gebruikers' }),
  verwijderLeerkracht: async (bestuurId, gebruikerId) =>
    httpClient.delete(`/besturen/${bestuurId}/leerkracht/${gebruikerId}`, { invalidateCacheTag: 'gebruikers' }),
  getCookieSettings: async (bestuurId) =>
    httpClient.getCached(`besturen/${bestuurId}/2fa/cookie`, { minutesToCache: 10, cacheTags: ['cookieSettings'] }),
  wijzig2faCookieLifetime: async (bestuurId, dagen) =>
    httpClient.patch(`/besturen/${bestuurId}/2fa/cookie/${dagen}`, { invalidateCacheTag: 'cookieSettings' }),
  getBestuurdersBijBestuur: async (bestuurId) =>
    httpClient.getCached(`/besturen/${bestuurId}/bestuurders`, { minutesToCache: 10, cacheTags: ['bestuur', 'gebruikers'] }),
  getReferentieniveaus: async (bestuurId, peildatum, meetmoment, leerjaren) =>
    httpClient.getCached(`besturen/${bestuurId}/referentieniveaus`, { minutesToCache: 15, cacheTags: ['referentieniveaus'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { leerjaren } }),
  getReferentieniveausVak: async (bestuurId, vak, peildatum, meetmoment, leerjaren) =>
    httpClient.getCached(`besturen/${bestuurId}/referentieniveaus/${vak}`, { minutesToCache: 15, cacheTags: ['referentieniveaus'], parameters: { peildatum: peildatum || '', meetmoment: meetmoment || '' }, rawParameters: { leerjaren } })
}
