<template>
  <span>{{ getNiveauLabel(niveau).niveau }} </span>
  <small> {{ getNiveauLabel(niveau).periode }} </small>
</template>

<script>
import { getNiveauLabel } from '@/helpers/niveaus'
export default {
  name: 'NiveauLabel',
  props: {
    niveau: String
  },
  methods: {
    getNiveauLabel
  }
}
</script>
