import httpClient from '../HttpClient'
import { errors } from 'constants/apiErrors'

export default {
  getGebruikers: async (search, orderBy, start, limit) =>
    httpClient.call('/gebruikers', { method: 'get', parameters: { search, orderBy, start, limit } }),
  getGebruiker: async gebruikerId =>
    httpClient.call(`/gebruikers/${gebruikerId}`, { method: 'get' }),
  verwijderGebruiker: async (gebruikerId) =>
    httpClient.call(`/gebruikers/${gebruikerId}`, { method: 'delete' }, true, `${errors.FOUT_OPGETREDEN_VERWIJDEREN_DE} gebruiker`, true)
}
