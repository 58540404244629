<template>
  <div class="spinner-container">
    <q-spinner
      class="center-spinner"
      color="primary"
    />
  </div>
</template>

<script>
export default {
}
</script>
