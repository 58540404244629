import httpClient from '../HttpClient'

const headers = { 'Content-Type': 'application/json' }
export default {
  getThemas: async (schoolId, parameters) => {
    return httpClient.call(`/scholen/${schoolId}/themas`, { method: 'get', parameters }, true)
  },
  getThema: async (schoolId, themaId, parameters) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}`, { method: 'get', parameters }, true)
  },
  getThemaVelden: async (schoolId, themaId) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}/velden`, { method: 'get' }, true)
  },
  getThemaVeld: async (schoolId, themaId, veldId) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}/velden/${veldId}`, { method: 'get' }, true)
  },
  updateThemaVelden: async (schoolId, themaId, veldId, data) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}/velden/${veldId}`, { method: 'patch', data }, true)
  },
  getSubdomein: async (schoolId, themaId, subdomein) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}/subdomeinen/${subdomein}`, { method: 'get' }, true)
  },
  uploadBestand: async (schoolId, themaId, data, parameters, type) =>
    httpClient.put(`/scholen/${schoolId}/themas/${themaId}/bijlagen`, { data, parameters, headers: { 'Content-Type': type } }),
  wijzigBestand: async (schoolId, themaId, data) =>
    httpClient.patch(`/scholen/${schoolId}/themas/${themaId}/bijlagen`, { data }),
  verwijderBestand: async (schoolId, themaId, bestandId) =>
    httpClient.delete(`/scholen/${schoolId}/themas/${themaId}/bijlagen/${bestandId}`),
  wijzigSubdomein: async (schoolId, themaId, subdomein, data) =>
    httpClient.putJson(`/scholen/${schoolId}/themas/${themaId}/subdomeinen/${subdomein}/toelichting`, { data }),
  wijzigSubdomeinActiviteit: async (schoolId, themaId, subdomein, data) =>
    httpClient.putJson(`/scholen/${schoolId}/themas/${themaId}/subdomeinen/${subdomein}/activiteit`, { data }),
  verwijderSubdomeinActiviteit: async (schoolId, themaId, subdomein, data) =>
    httpClient.delete(`/scholen/${schoolId}/themas/${themaId}/subdomeinen/${subdomein}/activiteit`),
  registreerSubdomeindoelen: async (schoolId, themaId, subdomein) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}/doelen`, { method: 'put', parameters: { subdomein } }, true)
  },
  verwijderSubdomeindoelen: async (schoolId, themaId, subdomein) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}/doelen`, { method: 'delete', parameters: { subdomein } }, true)
  },
  registreerThemadoelen: async (schoolId, themaId, data) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}/doelen`, { method: 'put', headers, data }, true)
  },
  verwijderThemadoelen: async (schoolId, themaId, data) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}/doelen`, { method: 'delete', headers, data }, true)
  },
  getThemadoelen: async (schoolId, themaId) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}/doelen`, { method: 'get' }, true)
  },
  wijzigThemadoel: async (schoolId, themaId, doelId, data) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}/doelen/${doelId}`, { method: 'patch', headers, data }, true)
  },
  getThemadoel: async (schoolId, themaId, doelId) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}/doelen/${doelId}`, { method: 'get' }, true)
  },
  registreerThema: async (schoolId, data) => {
    return httpClient.call(`/scholen/${schoolId}/themas`, { method: 'post', headers, data }, true)
  },
  verwijderThema: async (schoolId, themaId) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}`, { method: 'delete', headers }, true)
  },
  dupliceerThema: async (schoolId, themaId) => {
    return httpClient.postJson(`/scholen/${schoolId}/themas/${themaId}/kopie`, { data: {} })
  },
  wijzigThema: async (schoolId, themaId, data) => {
    return httpClient.call(`/scholen/${schoolId}/themas/${themaId}`, { method: 'patch', headers, data }, true)
  },
  getThemaplannen: async (schoolId, parameters) => {
    return httpClient.get(`/scholen/${schoolId}/themaplannen`, { parameters })
  },
  verwijderThemaplannen: async (schoolId, parameters) => {
    return httpClient.delete(`/scholen/${schoolId}/themaplannen`, { parameters })
  },
  verwijderThemaplan: async (schoolId, themaplanId) => {
    return httpClient.delete(`/scholen/${schoolId}/themaplannen/${themaplanId}`)
  }
}
